import React, { Fragment, useEffect, useRef, useState } from "react";
import { Layer, Rect, Stage, Text } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import neck_blank from "../../assets/images/neck_blank.png";
import { useFormula } from "../../lib/hooks/useFormula";
import {
  deleteLayerFromCurrentView,
  setClientCanvas,
  setMaxPrintDesign,
  setMockupWrapperBack,
  setMockupWrapperFront,
  setMockupWrapperLeft,
  setMockupWrapperNeck,
  setMockupWrapperRight,
  setOutsideDesign,
  setSelectedLayer,
} from "../../lib/slice/designerSlice";
import LayerImage from "./LayerImage";
import LayerText from "./LayerText";

const Mockup = ({
  updateDesign,
  dRef,
  setIsDefaultOption,
  v,
  isDownload,
  item,
  displayColor,
}) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const frontCanvasRef = useRef(null);
  const backCanvasRef = useRef(null);
  const leftCanvasRef = useRef(null);
  const rightCanvasRef = useRef(null);
  const neckCanvasRef = useRef(null);
  const mockupWrapperRef = useRef(null);

  const canvasRefs = {
    front: frontCanvasRef,
    back: backCanvasRef,
    left: leftCanvasRef,
    right: rightCanvasRef,
    neck: neckCanvasRef,
  };

  const {
    selectedLayer,
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    mockupWrapperFront,
    mockupWrapperBack,
    mockupWrapperLeft,
    mockupWrapperRight,
    mockupWrapperNeck,
    zoom,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
    view,
    outsideDesign,
    maxPrintDesign,
  } = useSelector((state) => state.designer);
  const frontParentData = useSelector(
    (state) => state.art.data?.front_parent_data
  );
  const backParentData = useSelector(
    (state) => state.art.data?.back_parent_data
  );
  const leftParentData = useSelector(
    (state) => state.art.data?.left_parent_data
  );
  const rightParentData = useSelector(
    (state) => state.art.data?.right_parent_data
  );
  const neckParentData = useSelector(
    (state) => state.art.data?.neck_parent_data
  );
  const dashboard = useSelector((state) => state.dashboard?.data);

  const [isHovered, setIsHovered] = useState(false);
  const [isIconVisible, setIsIconVisible] = useState(true);
  const [containerFront, setContainerFront] = useState(null);
  const [containerBack, setContainerBack] = useState(null);
  const [containerLeft, setContainerLeft] = useState(null);
  const [containerRight, setContainerRight] = useState(null);
  const [containerNeck, setContainerNeck] = useState(null);

  const designs =
    v === "front"
      ? frontDesigns
      : v === "back"
      ? backDesigns
      : v === "left"
      ? leftDesigns
      : v === "right"
      ? rightDesigns
      : v === "neck"
      ? neckDesigns
      : [];

  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();

  useEffect(() => {
    if (v === "front") {
      frontCanvasRef.current.getCanvas()._canvas.id = "frontCanvas";
    } else if (v === "back") {
      backCanvasRef.current.getCanvas()._canvas.id = "backCanvas";
    } else if (v === "left") {
      leftCanvasRef.current.getCanvas()._canvas.id = "leftCanvas";
    } else if (v === "right") {
      rightCanvasRef.current.getCanvas()._canvas.id = "rightCanvas";
    } else if (v === "neck") {
      neckCanvasRef.current.getCanvas()._canvas.id = "neckCanvas";
    }
    // eslint-disable-next-line
  }, [v]);

  useEffect(() => {
    if (selectedLayer?.type === "TEXT") {
      navigate("/edit-text");
    } else if (selectedLayer?.type === "IMAGE") {
      navigate("/edit-image");
      setIsDefaultOption(false);
    }
    // eslint-disable-next-line
  }, [selectedLayer?.type]);

  useEffect(() => {
    let canvasWidth = 0;
    let canvasHeight = 0;
    let canvasHeightFull = 0;
    let canvasTop = 0;
    let canvasLeft = 0;

    if (view === "front" && frontParentData && frontParentData[0]) {
      if (dashboard?.productType === "Tumblers") {
        canvasWidth =
          (Number(frontParentData[0]?.front_area_width) /
            (+currentFrontPrint?.front_area_height /
              +currentFrontPrint?.front_area_height_float)) *
          NewImageFormulaFront;
        canvasHeight =
          (Number(frontParentData[0]?.front_area_height) /
            (+currentFrontPrint?.front_area_height /
              +currentFrontPrint?.front_area_height_float)) *
          NewImageFormulaFront;
        canvasHeightFull =
          (Number(frontParentData[0]?.front_area_height) /
            (+currentFrontPrint?.front_area_height /
              +currentFrontPrint?.front_area_height_float)) *
          NewImageFormulaFront;
        canvasTop =
          (Number(frontParentData[0]?.front_area_top) /
            (+currentFrontPrint?.front_area_height /
              +currentFrontPrint?.front_area_height_float)) *
          NewImageFormulaFront;
        canvasLeft =
          (Number(frontParentData[0]?.front_area_left) /
            (+currentFrontPrint?.front_area_height /
              +currentFrontPrint?.front_area_height_float)) *
          NewImageFormulaFront;
      } else {
        canvasWidth = Number(
          (
            Number(frontParentData[0]?.front_area_width_float) *
            NewImageFormulaFront
          ).toFixed(2)
        );
        canvasHeight = +frontParentData[0]?.front_area_end_point
          ? Number(
              (
                Number(frontParentData[0]?.front_area_height_float) *
                NewImageFormulaFront
              ).toFixed(2)
            )
          : Number(
              (
                Number(frontParentData[0]?.front_area_height_float) *
                NewImageFormulaFront
              ).toFixed(2)
            );
        canvasHeightFull = +frontParentData[0]?.front_area_end_point
          ? Number(
              (
                Number(frontParentData[0]?.front_area_end_point) *
                NewImageFormulaFront
              ).toFixed(2)
            )
          : Number(
              (
                Number(frontParentData[0]?.front_area_height_full) *
                NewImageFormulaFront
              ).toFixed(2)
            );
        canvasTop = Number(
          (
            Number(frontParentData[0]?.front_area_top) *
            (Number(mockupWrapperFront?.width) / 478)
          ).toFixed(2)
        );
        canvasLeft = Number(
          (
            Number(frontParentData[0]?.front_area_left) *
            (Number(mockupWrapperFront?.width) / 478)
          ).toFixed(2)
        );
      }

      if (
        canvasWidth ||
        canvasHeight ||
        canvasHeightFull ||
        canvasTop ||
        canvasLeft
      ) {
        dispatch(
          setClientCanvas({
            canvasType: "frontClientCanvas",
            payload: {
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              canvasHeightFull: canvasHeightFull,
              canvasTop: canvasTop,
              canvasLeft: canvasLeft,
            },
          })
        );
      }
    } else if (view === "back" && backParentData && backParentData[0]) {
      if (dashboard?.productType === "Tumblers") {
        canvasWidth =
          (Number(backParentData[0]?.back_area_width) /
            (+currentBackPrint?.back_area_height /
              +currentBackPrint?.back_area_height_float)) *
          NewImageFormulaBack;
        canvasHeight =
          (Number(backParentData[0]?.back_area_height) /
            (+currentBackPrint?.back_area_height /
              +currentBackPrint?.back_area_height_float)) *
          NewImageFormulaBack;
        canvasHeightFull =
          (Number(backParentData[0]?.back_area_height) /
            (+currentBackPrint?.back_area_height /
              +currentBackPrint?.back_area_height_float)) *
          NewImageFormulaBack;
        canvasTop =
          (Number(backParentData[0]?.back_area_top) /
            (+currentBackPrint?.back_area_height /
              +currentBackPrint?.back_area_height_float)) *
          NewImageFormulaBack;
        canvasLeft =
          (Number(backParentData[0]?.back_area_left) /
            (+currentBackPrint?.back_area_height /
              +currentBackPrint?.back_area_height_float)) *
          NewImageFormulaBack;
      } else {
        canvasWidth = Number(
          (
            Number(backParentData[0]?.back_area_width_float) *
            NewImageFormulaBack
          ).toFixed(2)
        );
        canvasHeight = +backParentData[0]?.back_area_end_point
          ? Number(
              (
                Number(backParentData[0]?.back_area_height_float) *
                NewImageFormulaBack
              ).toFixed(2)
            )
          : Number(
              (
                Number(backParentData[0]?.back_area_height_float) *
                NewImageFormulaBack
              ).toFixed(2)
            );
        canvasHeightFull = +backParentData[0]?.back_area_end_point
          ? Number(
              (
                Number(backParentData[0]?.back_area_end_point) *
                NewImageFormulaBack
              ).toFixed(2)
            )
          : Number(
              (
                Number(backParentData[0]?.back_area_height_full) *
                NewImageFormulaBack
              ).toFixed(2)
            );
        canvasTop = Number(
          (
            Number(backParentData[0]?.back_area_top) *
            (Number(mockupWrapperBack?.width) / 478)
          ).toFixed(2)
        );
        canvasLeft = Number(
          (
            Number(backParentData[0]?.back_area_left) *
            (Number(mockupWrapperBack?.width) / 478)
          ).toFixed(2)
        );
      }
      if (
        canvasWidth ||
        canvasHeight ||
        canvasHeightFull ||
        canvasTop ||
        canvasLeft
      ) {
        dispatch(
          setClientCanvas({
            canvasType: "backClientCanvas",
            payload: {
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              canvasHeightFull: canvasHeightFull,
              canvasTop: canvasTop,
              canvasLeft: canvasLeft,
            },
          })
        );
      }
    } else if (view === "left" && leftParentData && leftParentData[0]) {
      canvasWidth = Number(
        (
          Number(leftParentData[0]?.left_area_width_float) * NewImageFormulaLeft
        ).toFixed(2)
      );
      canvasHeight = +leftParentData[0]?.left_area_end_point
        ? Number(
            (
              Number(leftParentData[0]?.left_area_height_float) *
              NewImageFormulaLeft
            ).toFixed(2)
          )
        : Number(
            (
              Number(leftParentData[0]?.left_area_height_float) *
              NewImageFormulaLeft
            ).toFixed(2)
          );

      canvasHeightFull = +leftParentData[0]?.left_area_end_point
        ? Number(
            (
              Number(leftParentData[0]?.left_area_end_point) *
              NewImageFormulaLeft
            ).toFixed(2)
          )
        : Number(
            (
              Number(leftParentData[0]?.left_area_height_full) *
              NewImageFormulaLeft
            ).toFixed(2)
          );
      canvasTop = Number(
        (
          Number(leftParentData[0]?.left_area_top) *
          (Number(mockupWrapperLeft?.width) / 478)
        ).toFixed(2)
      );
      canvasLeft = Number(
        (
          Number(leftParentData[0]?.left_area_left) *
          (Number(mockupWrapperLeft?.width) / 478)
        ).toFixed(2)
      );
      if (
        canvasWidth ||
        canvasHeight ||
        canvasHeightFull ||
        canvasTop ||
        canvasLeft
      ) {
        dispatch(
          setClientCanvas({
            canvasType: "leftClientCanvas",
            payload: {
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              canvasHeightFull: canvasHeightFull,
              canvasTop: canvasTop,
              canvasLeft: canvasLeft,
            },
          })
        );
      }
    } else if (view === "right" && rightParentData && rightParentData[0]) {
      canvasWidth = Number(
        (
          Number(rightParentData[0]?.right_area_width_float) *
          NewImageFormulaRight
        ).toFixed(2)
      );
      canvasHeight = +rightParentData[0]?.right_area_end_point
        ? Number(
            (
              Number(rightParentData[0]?.right_area_height_float) *
              NewImageFormulaRight
            ).toFixed(2)
          )
        : Number(
            (
              Number(rightParentData[0]?.right_area_height_float) *
              NewImageFormulaRight
            ).toFixed(2)
          );
      canvasHeightFull = +rightParentData[0]?.right_area_end_point
        ? Number(
            (
              Number(rightParentData[0]?.right_area_end_point) *
              NewImageFormulaRight
            ).toFixed(2)
          )
        : Number(
            (
              Number(rightParentData[0]?.right_area_height_full) *
              NewImageFormulaRight
            ).toFixed(2)
          );
      canvasTop = Number(
        (
          Number(rightParentData[0]?.right_area_top) *
          (Number(mockupWrapperRight?.width) / 478)
        ).toFixed(2)
      );
      canvasLeft = Number(
        (
          Number(rightParentData[0]?.right_area_left) *
          (Number(mockupWrapperRight?.width) / 478)
        ).toFixed(2)
      );
      if (
        canvasWidth ||
        canvasHeight ||
        canvasHeightFull ||
        canvasTop ||
        canvasLeft
      ) {
        dispatch(
          setClientCanvas({
            canvasType: "rightClientCanvas",
            payload: {
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              canvasHeightFull: canvasHeightFull,
              canvasTop: canvasTop,
              canvasLeft: canvasLeft,
            },
          })
        );
      }
    } else if (view === "neck" && neckParentData && neckParentData[0]) {
      canvasWidth = Number(
        (
          Number(neckParentData[0]?.neck_area_width_float) * NewImageFormulaNeck
        ).toFixed(2)
      );
      canvasHeight = +neckParentData[0]?.neck_area_end_point
        ? Number(
            (
              Number(neckParentData[0]?.neck_area_height_float) *
              NewImageFormulaNeck
            ).toFixed(2)
          )
        : Number(
            (
              Number(neckParentData[0]?.neck_area_height_float) *
              NewImageFormulaNeck
            ).toFixed(2)
          );
      canvasHeightFull = +neckParentData[0]?.neck_area_end_point
        ? Number(
            (
              Number(neckParentData[0]?.neck_area_end_point) *
              NewImageFormulaNeck
            ).toFixed(2)
          )
        : Number(
            (
              Number(neckParentData[0]?.neck_area_height_full) *
              NewImageFormulaNeck
            ).toFixed(2)
          );
      canvasTop = Number(
        (
          Number(neckParentData[0]?.neck_area_top) *
          (Number(mockupWrapperNeck?.width) / 478)
        ).toFixed(2)
      );
      canvasLeft = Number(
        (
          Number(neckParentData[0]?.neck_area_left) *
          (Number(mockupWrapperNeck?.width) / 478)
        ).toFixed(2)
      );
      if (
        canvasWidth ||
        canvasHeight ||
        canvasHeightFull ||
        canvasTop ||
        canvasLeft
      ) {
        dispatch(
          setClientCanvas({
            canvasType: "neckClientCanvas",
            payload: {
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              canvasHeightFull: canvasHeightFull,
              canvasTop: canvasTop,
              canvasLeft: canvasLeft,
            },
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    frontParentData,
    backParentData,
    leftParentData,
    rightParentData,
    neckParentData,
    view,
    mockupWrapperFront?.width,
    NewImageFormulaFront,
    mockupWrapperBack?.width,
    NewImageFormulaBack,
    mockupWrapperLeft?.width,
    NewImageFormulaLeft,
    mockupWrapperRight?.width,
    NewImageFormulaRight,
    mockupWrapperNeck?.width,
    NewImageFormulaNeck,
    currentBackPrint?.back_area_height,
    currentBackPrint?.back_area_height_float,
    currentFrontPrint?.front_area_height,
    currentFrontPrint?.front_area_height_float,
  ]);

  useEffect(() => {
    const handleClick = (event) => {
      if (
        mockupWrapperRef.current &&
        mockupWrapperRef.current.contains(event.target)
      ) {
        setIsHovered(true);
      } else {
        setIsHovered(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [mockupWrapperRef]);

  const frontCanvasWidth = Number(
    (
      (Number(currentFrontPrint?.front_area_width) / 14.25) *
      NewImageFormulaFront
    ).toFixed(2)
  );
  console.log("frontCanvasWidth", frontCanvasWidth);

  const backCanvasWidth = Number(
    (
      (Number(currentBackPrint?.back_area_width) / 14.25) *
      NewImageFormulaBack
    ).toFixed(2)
  );

  const leftCanvasWidth = Number(
    (
      (Number(currentLeftPrint?.left_area_width) / 14.25) *
      NewImageFormulaLeft
    ).toFixed(2)
  );

  const rightCanvasWidth = Number(
    (
      (Number(currentRightPrint?.right_area_width) / 14.25) *
      NewImageFormulaRight
    ).toFixed(2)
  );

  const neckCanvasWidth = Number(
    (
      (Number(currentNeckPrint?.neck_area_width) / 14.25) *
      NewImageFormulaNeck
    ).toFixed(2)
  );

  // const frontCanvasHeightFull = +currentFrontPrint?.front_area_end_point
  //   ? Number(
  //       (
  //         Number(currentFrontPrint?.front_area_end_point) * NewImageFormulaFront
  //       ).toFixed(2)
  //     )
  //   : Number(
  //       (
  //         Number(currentFrontPrint?.front_area_height_full) *
  //         NewImageFormulaFront
  //       ).toFixed(2)
  //     );

  // const backCanvasHeightFull = +currentBackPrint?.back_area_end_point
  //   ? Number(
  //       (
  //         Number(currentBackPrint?.back_area_end_point) * NewImageFormulaBack
  //       ).toFixed(2)
  //     )
  //   : Number(
  //       (
  //         Number(currentBackPrint?.back_area_height_full) * NewImageFormulaBack
  //       ).toFixed(2)
  //     );

  // const leftCanvasHeightFull = +currentLeftPrint?.left_area_height_full
  //   ? Number(
  //       (
  //         Number(currentLeftPrint?.left_area_height_full) * NewImageFormulaLeft
  //       ).toFixed(2)
  //     )
  //   : Number(
  //       (
  //         Number(currentLeftPrint?.left_area_height_full) * NewImageFormulaLeft
  //       ).toFixed(2)
  //     );

  // const rightCanvasHeightFull = +currentRightPrint?.right_area_end_point
  //   ? Number(
  //       (
  //         Number(currentRightPrint?.right_area_end_point) * NewImageFormulaRight
  //       ).toFixed(2)
  //     )
  //   : Number(
  //       (
  //         Number(currentRightPrint?.right_area_height_full) *
  //         NewImageFormulaRight
  //       ).toFixed(2)
  //     );

  // const neckCanvasHeightFull = +currentNeckPrint?.neck_area_end_point
  //   ? Number(
  //       (
  //         Number(currentNeckPrint?.neck_area_end_point) * NewImageFormulaNeck
  //       ).toFixed(2)
  //     )
  //   : Number(
  //       (
  //         Number(currentNeckPrint?.neck_area_height_full) * NewImageFormulaNeck
  //       ).toFixed(2)
  //     );

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      dispatch(setSelectedLayer(null));
      if (location.pathname === "/edit-image") {
        navigate("/upload");
        ismobile && setIsDefaultOption(true);
      }
      if (
        [
          "/edit-text",
          "/font-preview",
          "/font-color",
          "/text-size",
          "/text-outline",
          "/text-alignment",
        ].includes(location.pathname)
      ) {
        navigate("/add-text");
        ismobile && setIsDefaultOption(true);
      }
    }
  };

  const renderScale = () => {
    const scaleElements = [];
    const offset = ismobile ? 8 : 16;
    for (let i = 1; i <= 30; i++) {
      const yPosFront = i * NewImageFormulaFront;
      const yPosBack = i * NewImageFormulaBack;
      const yPosLeft = i * NewImageFormulaLeft;
      const yPosRight = i * NewImageFormulaRight;
      const yPosNeck = i * NewImageFormulaNeck;
      const measurement = i;
      scaleElements.push(
        <Rect
          key={`line${i}`}
          x={
            v === "front"
              ? frontClientCanvas?.canvasWidth / 2 - 5
              : v === "back"
              ? backClientCanvas?.canvasWidth / 2 - 5
              : v === "left"
              ? leftClientCanvas?.canvasWidth / 2 - 5
              : v === "right"
              ? rightClientCanvas?.canvasWidth / 2 - 5
              : v === "neck"
              ? neckClientCanvas?.canvasWidth / 2 - 5
              : 0
          }
          y={
            v === "front"
              ? yPosFront
              : v === "back"
              ? yPosBack
              : v === "left"
              ? yPosLeft
              : v === "right"
              ? yPosRight
              : v === "neck"
              ? yPosNeck
              : 0
          }
          width={10}
          height={1}
          fill="#8dcc19"
        />,
        <Text
          key={`text${i}`}
          x={
            v === "front"
              ? frontClientCanvas?.canvasWidth / 2 + offset
              : v === "back"
              ? backClientCanvas?.canvasWidth / 2 + offset
              : v === "left"
              ? leftClientCanvas?.canvasWidth / 2 + offset
              : v === "right"
              ? rightClientCanvas?.canvasWidth / 2 + offset
              : v === "neck"
              ? neckClientCanvas?.canvasWidth / 2 + offset
              : 0
          }
          y={
            v === "front"
              ? yPosFront - 6
              : v === "back"
              ? yPosBack - 6
              : v === "left"
              ? yPosLeft - 6
              : v === "right"
              ? yPosRight - 6
              : v === "neck"
              ? yPosNeck - 6
              : 0
          }
          text={measurement}
          fontSize={ismobile ? 8 : 12}
          fill="#8dcc19"
        />
      );
    }

    return <Fragment>{scaleElements}</Fragment>;
  };

  const isDesignOutsideCanvas = (design, canvasWidth, canvasHeight) => {
    const { x, y, width, height } = design?.config || {};
    return (
      x < 0 ||
      y < 0 ||
      (Number(width) + Number(x)).toFixed(2) > canvasWidth ||
      (Number(height) + Number(y)).toFixed(2) > canvasHeight
    );
  };

  const isOutsideFrontDesign = frontDesigns.some((design) =>
    isDesignOutsideCanvas(
      design,
      frontClientCanvas?.canvasWidth + 0.2,
      frontClientCanvas?.canvasHeightFull + 0.2
    )
  );

  const isOutsideBackDesign = backDesigns.some((design) =>
    isDesignOutsideCanvas(
      design,
      backClientCanvas?.canvasWidth + 0.2,
      backClientCanvas?.canvasHeightFull + 0.2
    )
  );

  const isOutsideLeftDesign = leftDesigns.some((design) =>
    isDesignOutsideCanvas(
      design,
      leftClientCanvas?.canvasWidth + 0.2,
      leftClientCanvas?.canvasHeightFull + 0.2
    )
  );

  const isOutsideRightDesign = rightDesigns.some((design) =>
    isDesignOutsideCanvas(
      design,
      rightClientCanvas?.canvasWidth + 0.2,
      rightClientCanvas?.canvasHeightFull + 0.2
    )
  );

  const isOutsideNeckDesign = neckDesigns.some((design) =>
    isDesignOutsideCanvas(
      design,
      neckClientCanvas?.canvasWidth + 0.2,
      neckClientCanvas?.canvasHeightFull + 0.2
    )
  );

  const calculateMaxPrintArea = (canvasHeight, designs) =>
    designs.some(
      (design) => canvasHeight < Number(design?.config?.height)?.toFixed(2)
    );

  const maxPrintAreaFront = calculateMaxPrintArea(
    0.2 + frontClientCanvas?.canvasHeight,
    frontDesigns
  );
  const maxPrintAreaBack = calculateMaxPrintArea(
    0.2 + backClientCanvas?.canvasHeight,
    backDesigns
  );
  const maxPrintAreaLeft = calculateMaxPrintArea(
    0.2 + leftClientCanvas?.canvasHeight,
    leftDesigns
  );
  const maxPrintAreaRight = calculateMaxPrintArea(
    0.2 + rightClientCanvas?.canvasHeight,
    rightDesigns
  );
  const maxPrintAreaNeck = calculateMaxPrintArea(
    0.2 + neckClientCanvas?.canvasHeight,
    neckDesigns
  );

  useEffect(() => {
    if (
      frontDesigns?.length ||
      backDesigns?.length ||
      leftDesigns?.length ||
      rightDesigns?.length ||
      neckDesigns?.length
    ) {
      dispatch(
        setOutsideDesign({
          front: isOutsideFrontDesign,
          back: isOutsideBackDesign,
          left: isOutsideLeftDesign,
          right: isOutsideRightDesign,
          neck: isOutsideNeckDesign,
        })
      );
    }
  }, [
    dispatch,
    isOutsideFrontDesign,
    isOutsideBackDesign,
    isOutsideLeftDesign,
    isOutsideRightDesign,
    isOutsideNeckDesign,
    frontDesigns?.length,
    backDesigns?.length,
    leftDesigns?.length,
    rightDesigns?.length,
    neckDesigns?.length,
  ]);

  useEffect(() => {
    dispatch(
      setMaxPrintDesign({
        front: maxPrintAreaFront,
        back: maxPrintAreaBack,
        left: maxPrintAreaLeft,
        right: maxPrintAreaRight,
        neck: maxPrintAreaNeck,
      })
    );
  }, [
    dispatch,
    selectedLayer?.config?.height,
    maxPrintAreaFront,
    maxPrintAreaBack,
    maxPrintAreaLeft,
    maxPrintAreaRight,
    maxPrintAreaNeck,
  ]);

  useEffect(() => {
    if (!mockupWrapperFront?.width && view === "front") {
      setContainerFront(document.querySelectorAll(".pro-width-front-color")[0]);
      dispatch(
        setMockupWrapperFront({
          width: containerFront?.clientWidth,
          height: containerFront?.clientHeight,
        })
      );
    }

    if (!mockupWrapperBack?.width && view === "back") {
      setContainerBack(document.querySelectorAll(".pro-width-back-color")[0]);
      dispatch(
        setMockupWrapperBack({
          width: containerBack?.clientWidth,
          height: containerBack?.clientHeight,
        })
      );
    }

    if (!mockupWrapperLeft?.width && view === "left") {
      setContainerLeft(document.querySelectorAll(".pro-width-left-color")[0]);
      dispatch(
        setMockupWrapperLeft({
          width: containerLeft?.clientWidth,
          height: containerLeft?.clientHeight,
        })
      );
    }

    if (!mockupWrapperRight?.width && view === "right") {
      setContainerRight(document.querySelectorAll(".pro-width-right-color")[0]);
      dispatch(
        setMockupWrapperRight({
          width: containerRight?.clientWidth,
          height: containerRight?.clientHeight,
        })
      );
    }

    if (!mockupWrapperNeck?.width && view === "neck") {
      setContainerNeck(document.querySelectorAll(".pro-width-neck-color")[0]);
      dispatch(
        setMockupWrapperNeck({
          width: containerNeck?.clientWidth,
          height: containerNeck?.clientHeight,
        })
      );
    }

    // eslint-disable-next-line
  }, [
    view,
    containerFront?.clientWidth,
    containerBack?.clientWidth,
    containerLeft?.clientWidth,
    containerRight?.clientWidth,
    containerNeck?.clientWidth,
    mockupWrapperFront?.width,
    mockupWrapperBack?.width,
    mockupWrapperLeft?.width,
    mockupWrapperRight?.width,
    mockupWrapperNeck?.width,
  ]);

  return (
    <Wrapper $isDownload={isDownload} $isHovered={isHovered}>
      <div
        style={{ textAlign: "center" }}
        className="img-width"
        ref={mockupWrapperRef}
      >
        <div
          className={`${
            v === "front"
              ? "pro-width-front"
              : v === "back"
              ? "pro-width-back"
              : v === "left"
              ? "pro-width-left"
              : v === "right"
              ? "pro-width-right"
              : v === "neck"
              ? "pro-width-neck"
              : ""
          } ${isDownload ? "transparent" : "green-border"} ${
            displayColor &&
            (v === "front"
              ? "pro-width-front-color"
              : v === "back"
              ? "pro-width-back-color"
              : v === "left"
              ? "pro-width-left-color"
              : v === "right"
              ? "pro-width-right-color"
              : v === "neck"
              ? "pro-width-neck-color"
              : "")
          }`}
          id="pro-width"
          style={{
            position: "relative",
            height: "100%",
            display: "inline-block",
            marginTop: 50,
            transform: `scale(${zoom})`,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div style={{ display: "inline-block" }}>
            <div
              className="uploadarea"
              style={{
                position: "absolute",
                top:
                  v === "front"
                    ? frontClientCanvas?.canvasTop
                    : v === "back"
                    ? backClientCanvas?.canvasTop
                    : v === "left"
                    ? leftClientCanvas?.canvasTop
                    : v === "right"
                    ? rightClientCanvas?.canvasTop
                    : v === "neck"
                    ? neckClientCanvas?.canvasTop
                    : 0,
                left:
                  v === "front"
                    ? frontClientCanvas?.canvasLeft
                    : v === "back"
                    ? backClientCanvas?.canvasLeft
                    : v === "left"
                    ? leftClientCanvas?.canvasLeft
                    : v === "right"
                    ? rightClientCanvas?.canvasLeft
                    : v === "neck"
                    ? neckClientCanvas?.canvasLeft
                    : 0,
              }}
            >
              {isHovered && (
                <div
                  className="color-area"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width:
                      v === "front"
                        ? frontClientCanvas?.canvasWidth ?? 0
                        : v === "back"
                        ? backClientCanvas?.canvasWidth ?? 0
                        : v === "left"
                        ? leftClientCanvas?.canvasWidth ?? 0
                        : v === "right"
                        ? rightClientCanvas?.canvasWidth ?? 0
                        : v === "neck"
                        ? neckClientCanvas?.canvasWidth ?? 0
                        : 0,
                    height:
                      v === "front"
                        ? frontClientCanvas?.canvasHeightFull
                        : v === "back"
                        ? backClientCanvas?.canvasHeightFull
                        : v === "left"
                        ? leftClientCanvas?.canvasHeightFull
                        : v === "right"
                        ? rightClientCanvas?.canvasHeightFull
                        : v === "neck"
                        ? neckClientCanvas?.canvasHeightFull
                        : 0,
                  }}
                ></div>
              )}
              <Stage
                width={
                  v === "front"
                    ? frontClientCanvas?.canvasWidth + 20
                    : v === "back"
                    ? backClientCanvas?.canvasWidth + 20
                    : v === "left"
                    ? leftClientCanvas?.canvasWidth + 20
                    : v === "right"
                    ? rightClientCanvas?.canvasWidth + 20
                    : v === "neck"
                    ? neckClientCanvas?.canvasWidth + 20
                    : 0
                }
                height={
                  v === "front"
                    ? frontClientCanvas?.canvasHeightFull
                    : v === "back"
                    ? backClientCanvas?.canvasHeightFull
                    : v === "left"
                    ? leftClientCanvas?.canvasHeightFull
                    : v === "right"
                    ? rightClientCanvas?.canvasHeightFull
                    : v === "neck"
                    ? neckClientCanvas?.canvasHeightFull
                    : 0
                }
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
              >
                <Layer ref={canvasRefs[v]}>
                  {isHovered && !isDownload && renderScale()}
                  {designs.map((design, i) => {
                    const onSelect = () => {
                      setIsHovered(true);
                      dispatch(setSelectedLayer(design));
                    };
                    const onChange = (newAttrs) => {
                      const rects = designs.slice();
                      rects[i] = newAttrs;
                      updateDesign(rects);
                    };
                    const isSelected =
                      design?.config?.id === selectedLayer?.config?.id;

                    return (
                      <Fragment key={i}>
                        {design.type === "TEXT" ? (
                          <LayerText
                            isSelected={isSelected}
                            onSelect={onSelect}
                            designProp={design}
                            onChange={onChange}
                            isHovered={isHovered}
                            setIsIconVisible={setIsIconVisible}
                            isDownload={isDownload}
                            v={v}
                          />
                        ) : (
                          <Fragment key={i}>
                            <LayerImage
                              onSelect={onSelect}
                              isSelected={isSelected}
                              designProp={design}
                              onChange={onChange}
                              isHovered={isHovered}
                              isIconVisible={isIconVisible}
                              setIsIconVisible={setIsIconVisible}
                              isDownload={isDownload}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    );
                  })}
                </Layer>
              </Stage>
              {isIconVisible && selectedLayer && (
                <>
                  {!(
                    Object.values(outsideDesign).some((value) => value) ||
                    Object.values(maxPrintDesign).some((value) => value)
                  ) && (
                    <>
                      <div
                        className="anchors"
                        style={{
                          width: 6,
                          height: 6,
                          backgroundColor: "#8dcc19",
                          position: "absolute",
                          top: selectedLayer?.config?.y - 3,
                          left:
                            selectedLayer?.config?.x +
                            selectedLayer?.config?.width / 2 -
                            3,
                        }}
                      ></div>
                      <div
                        className="anchors"
                        style={{
                          width: 6,
                          height: 6,
                          backgroundColor: "#8dcc19",
                          position: "absolute",
                          top:
                            selectedLayer?.config?.y +
                            selectedLayer?.config?.height -
                            3,
                          left:
                            selectedLayer?.config?.x +
                            selectedLayer?.config?.width / 2 -
                            3,
                        }}
                      ></div>
                    </>
                  )}
                  <div
                    className="imagedeleteicon"
                    style={{
                      position: "absolute",
                      top:
                        selectedLayer?.config?.y >= 0 &&
                        selectedLayer?.config?.y <
                          (v === "front"
                            ? frontClientCanvas?.canvasHeightFull
                            : v === "back"
                            ? backClientCanvas?.canvasHeightFull
                            : v === "left"
                            ? leftClientCanvas?.canvasHeightFull
                            : v === "right"
                            ? rightClientCanvas?.canvasHeightFull
                            : v === "neck"
                            ? neckClientCanvas?.canvasHeightFull
                            : 0)
                          ? selectedLayer?.config?.y
                          : selectedLayer?.config?.y < 0
                          ? 0
                          : "100%",
                      left:
                        selectedLayer?.config?.x >= 0 &&
                        selectedLayer?.config?.x <
                          (v === "front"
                            ? frontCanvasWidth
                            : v === "back"
                            ? backCanvasWidth
                            : v === "left"
                            ? leftCanvasWidth
                            : v === "right"
                            ? rightCanvasWidth
                            : v === "neck"
                            ? neckCanvasWidth
                            : 0)
                          ? selectedLayer?.config?.x
                          : selectedLayer?.config?.x < 0
                          ? 0
                          : "100%",
                    }}
                    onClick={() => {
                      if (dRef.current) {
                        dRef.current.value = "";
                      }
                      if (selectedLayer?.type === "IMAGE") {
                        navigate("/upload");
                      } else {
                        navigate("/add-text");
                      }
                      dispatch(
                        deleteLayerFromCurrentView(selectedLayer?.config?.id)
                      );
                      ismobile && setIsDefaultOption(true);
                    }}
                  >
                    <svg
                      className="imagedelete"
                      data-id="imgcanvas0"
                      id="deleteiconimgcanvas0"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      x={0}
                      y={0}
                      viewBox="0 0 17 17"
                      xmlSpace="preserve"
                    >
                      <circle fill="#FFF" cx="8.5" cy="8.5" r="8.5" />
                      <g fill="#4a4a4a">
                        <path d="M11.13 4.97l-6.16 6.16c-.25.25-.25.65 0 .9s.65.25.9 0l6.16-6.16c.25-.25.25-.65 0-.9a.634.634 0 00-.9 0" />
                        <path d="M12.03 11.14L5.86 4.97a.634.634 0 00-.9 0c-.25.25-.25.65 0 .9l6.16 6.16c.25.25.65.25.9 0 .26-.24.26-.65.01-.89" />
                      </g>
                    </svg>
                  </div>
                  <div
                    className="imageresizeicon"
                    style={{
                      width: 30,
                      height: 30,
                      position: "absolute",
                      pointerEvents: "none",
                      top:
                        Math.min(
                          selectedLayer?.config?.height +
                            selectedLayer?.config?.y,
                          v === "front"
                            ? frontClientCanvas?.canvasHeightFull
                            : v === "back"
                            ? backClientCanvas?.canvasHeightFull
                            : v === "left"
                            ? leftClientCanvas?.canvasHeightFull
                            : v === "right"
                            ? rightClientCanvas?.canvasHeightFull
                            : v === "neck"
                            ? neckClientCanvas?.canvasHeightFull
                            : 0
                        ) - 15,
                      left:
                        selectedLayer?.config?.width +
                        selectedLayer?.config?.x -
                        15,
                    }}
                  >
                    <svg
                      className="imageresizeiconsvg"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      x={0}
                      y={0}
                      viewBox="0 0 39 39"
                      xmlSpace="preserve"
                    >
                      <path
                        fill="#4a4a4a"
                        d="M25.6 19.7v3.8L15.5 13.4h3.7v-3h-8.8v8.8h3v-3.7l10.1 10.1h-3.8v3h8.9v-8.9z"
                      />
                    </svg>
                  </div>
                </>
              )}
            </div>
            <img
              className="mockup-image"
              alt="mockup"
              src={
                v === "front"
                  ? item?.frontimage || ""
                  : v === "back"
                  ? item?.backimage || ""
                  : v === "left"
                  ? item?.leftimage || ""
                  : v === "right"
                  ? item?.rightimage || ""
                  : v === "neck"
                  ? item?.neckimage?.startsWith("https")
                    ? neck_blank
                    : item?.neckimage || ""
                  : ""
              }
              onLoad={() => {
                if (view === "front") {
                  setContainerFront(
                    document.querySelectorAll(".pro-width-front-color")
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .mockup-image {
    max-width: 760px !important;
  }
  .mockup-image {
    max-height: min(75vh, 760px);
    width: auto;

    @media (max-width: 1500px) {
      max-height: min(75vh, 478px);
    }
  }
  #pro-width:hover .color-area {
    border: 1px solid #8dcc19;
    background-color: #38383880;
  }
  #pro-width {
    height: 15%;
  }
  #pro-width:hover .uploadarea::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(50% - 10px);
    border-left: ${(props) =>
      props.$isDownload || !props.$isHovered
        ? "1px solid transparent"
        : "1px solid #8dcc19"};
    z-index: 0;
  }

  .imagedeleteicon,
  .imageresizeicon,
  .anchors {
    display: none;
  }

  #pro-width:hover .imagedeleteicon,
  #pro-width:hover .imageresizeicon,
  #pro-width:hover .anchors {
    display: block;
  }

  @media (max-width: 991px) {
    .color-area {
      background-color: #38383880;
      border: 1px solid #8dcc19;
    }

    .uploadarea::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 10px);
      border-left: ${(props) =>
        props.$isDownload || !props.$isHovered
          ? "1px solid transparent"
          : "1px solid #8dcc19"};
      z-index: 0;
    }

    .imagedeleteicon,
    .imageresizeicon,
    .anchors {
      display: ${(props) =>
        props.$isDownload || !props.$isHovered ? "none" : "block"};
    }
  }
`;

export default Mockup;
