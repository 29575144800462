import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../components/Sidebar/Default";
import TextAlignmentContent from "./TextAlignment/TextAlignmentContent";
import TextSizeContent from "./TextSize/TextSizeContent";

const EditText = ({
  isDefaultOption,
  setIsDefaultOption,
  setTextLayer,
  textLayer,
  enableInputRef,
}) => {
  const ismobile = window.innerWidth <= 991;

  const navigate = useNavigate();

  const { selectedLayer } = useSelector((state) => state.designer);
  const textColor = useSelector((state) => state.textColor.data);
  const textOutlineColor = useSelector((state) => state.textOutlineColor.data);

  const textColorName = textColor?.find(
    (color) => color.code === selectedLayer?.config?.fill
  )?.name;

  const textOutlineColorName = textOutlineColor?.find(
    (color) => color.code === selectedLayer?.config?.stroke
  )?.name;

  const [editContent, setEditContent] = useState("");

  useEffect(() => {
    setEditContent(selectedLayer?.content);
  }, [selectedLayer?.content]);

  useEffect(() => {
    if (selectedLayer?.type !== "TEXT") {
      navigate("/add-text");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <>
          {
            <div
              className={
                ismobile
                  ? "mbl-edit-option mbl-option bottom-option"
                  : "text-opt-details option-box"
              }
              style={{
                position: ismobile ? "fixed" : "relative",
                display: "inline-block",
              }}
            >
              <div className="option-heading">
                <div
                  className="back-btn"
                  onClick={() => {
                    navigate("/add-text");
                    ismobile && setIsDefaultOption(true);
                  }}
                >
                  <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
                </div>
                <h3 className="h3">Edit Text</h3>
                <div
                  className="option-close-btn"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 30,
                    height: 30,
                    backgroundColor: "#272727",
                    borderRadius: "50%",
                  }}
                  onClick={() => setIsDefaultOption(true)}
                >
                  <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
                </div>
              </div>
              <div className="option-srl-sec">
                <form>
                  <div className="input-group">
                    <textarea
                      className="form-control attextname"
                      style={{ backgroundColor: "transparent", zIndex: 0 }}
                      rows={5}
                      id="add-text"
                      placeholder="Enter text here"
                      value={editContent}
                      onChange={(e) => {
                        setEditContent(e.target.value);
                        enableInputRef.current = true;
                        setTextLayer((prev) => ({
                          ...prev,
                          content: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </form>
                {ismobile ? (
                  <button
                    type="button"
                    className="orange-btn editText"
                    onClick={() => setIsDefaultOption(true)}
                  >
                    Done
                  </button>
                ) : (
                  <>
                    <div className="chunkthirdbox">
                      <TextAlignmentContent />
                    </div>
                    <div className="chunkfistbox">
                      <div className="font-family-row text-opt-row">
                        <span className="opt-label">Font</span>
                        <div
                          className="font-family fontfamilyevent"
                          onClick={() => navigate("/font-preview")}
                        >
                          {selectedLayer?.config?.fontFamily}
                        </div>
                      </div>
                      <div className="font-color-row text-opt-row">
                        <span className="opt-label">Text Color</span>
                        <div
                          className="font-color textcolorevent"
                          onClick={() => navigate("/font-color")}
                        >
                          {textColorName || selectedLayer?.config?.fill}
                        </div>
                      </div>
                      <TextSizeContent
                        setTextLayer={setTextLayer}
                        textLayer={textLayer}
                        enableInputRef={enableInputRef}
                        setIsDefaultOption={setIsDefaultOption}
                      />
                    </div>
                    <div className="chunksecondbox">
                      <div className="font-outline-row text-opt-row">
                        <span className="opt-label">Outline</span>
                        <div
                          className="font-outline textoutline"
                          onClick={() => navigate("/text-outline")}
                        >
                          {textOutlineColorName
                            ? selectedLayer?.config?.strokeWidth
                              ? `Color: ${textOutlineColorName}`
                              : "none"
                            : selectedLayer?.config?.strokeWidth
                            ? `Color: ${selectedLayer?.config?.stroke}`
                            : "none"}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          }
        </>
      )}
    </>
  );
};

export default EditText;
