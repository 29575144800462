import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFormula = () => {
  // const frontDimension = useSelector(
  //   (state) => state.dashboard?.data?.front_dimension
  // );
  // const backDimension = useSelector(
  //   (state) => state.dashboard?.data?.back_dimension
  // );
  // const leftDimension = useSelector(
  //   (state) => state.dashboard?.data?.left_dimension
  // );
  // const rightDimension = useSelector(
  //   (state) => state.dashboard?.data?.right_dimension
  // );
  const dashboard = useSelector((state) => state.dashboard?.data);

  const {
    mockupWrapperFront,
    mockupWrapperBack,
    mockupWrapperLeft,
    mockupWrapperRight,
    mockupWrapperNeck,
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
  } = useSelector((state) => state.designer);

  const [NewImageFormulaFront, setNewImageFormulaFront] = useState(1);
  const [NewImageFormulaBack, setNewImageFormulaBack] = useState(1);
  const [NewImageFormulaLeft, setNewImageFormulaLeft] = useState(1);
  const [NewImageFormulaRight, setNewImageFormulaRight] = useState(1);
  const [NewImageFormulaNeck, setNewImageFormulaNeck] = useState(1);

  useEffect(() => {
    if (dashboard?.productType === "Tumblers") {
      if (currentFrontPrint) {
        setNewImageFormulaFront(
          +(
            (mockupWrapperFront?.width / 478) *
            (+currentFrontPrint?.front_area_height /
              +currentFrontPrint?.front_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaBack(
          +(
            (mockupWrapperBack?.width / 478) *
            (+currentBackPrint?.back_area_height /
              +currentBackPrint?.back_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaLeft(
          +(
            (mockupWrapperLeft?.width / 478) *
            (+currentLeftPrint?.left_area_height /
              +currentLeftPrint?.left_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaRight(
          +(
            (mockupWrapperRight?.width / 478) *
            (+currentRightPrint?.right_area_height /
              +currentRightPrint?.right_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaNeck(
          +(
            (mockupWrapperNeck?.width / 478) *
            (+currentNeckPrint?.neck_area_height /
              +currentNeckPrint?.neck_area_height_float)
          ).toFixed(3)
        );
      }
    } else {
      if (currentFrontPrint) {
        setNewImageFormulaFront(
          +(
            (mockupWrapperFront?.width / 478) *
            (+currentFrontPrint?.front_area_height /
              +currentFrontPrint?.front_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaBack(
          +(
            (mockupWrapperBack?.width / 478) *
            (+currentBackPrint?.back_area_height /
              +currentBackPrint?.back_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaLeft(
          +(
            (mockupWrapperLeft?.width / 478) *
            (+currentLeftPrint?.left_area_height /
              +currentLeftPrint?.left_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaRight(
          +(
            (mockupWrapperRight?.width / 478) *
            (+currentRightPrint?.right_area_height /
              +currentRightPrint?.right_area_height_float)
          ).toFixed(3)
        );
        setNewImageFormulaNeck(
          +(
            (mockupWrapperNeck?.width / 478) *
            (+currentNeckPrint?.neck_area_height /
              +currentNeckPrint?.neck_area_height_float)
          ).toFixed(3)
        );
      }
      // if (mockupWrapperFront?.width <= 760) {
      //   setNewImageFormulaFront((mockupWrapperFront?.width / 33.54).toFixed(3));
      //   setNewImageFormulaBack((mockupWrapperBack?.width / 33.54).toFixed(3));
      //   setNewImageFormulaLeft((mockupWrapperLeft?.width / 33.54).toFixed(3));
      //   setNewImageFormulaRight((mockupWrapperRight?.width / 33.54).toFixed(3));
      //   setNewImageFormulaNeck((mockupWrapperNeck?.width / 33.54).toFixed(3));
      // } else {
      //   if (frontDimension === "") {
      //     setNewImageFormulaFront(
      //       (mockupWrapperFront?.width / 33.54).toFixed(3)
      //     );
      //   } else {
      //     setNewImageFormulaFront(
      //       (mockupWrapperFront?.width / frontDimension).toFixed(3)
      //     );
      //   }
      //   if (backDimension === "") {
      //     setNewImageFormulaBack((mockupWrapperBack?.width / 33.54).toFixed(3));
      //   } else {
      //     setNewImageFormulaBack(
      //       (mockupWrapperBack?.width / backDimension).toFixed(3)
      //     );
      //   }
      //   if (leftDimension === "") {
      //     setNewImageFormulaLeft((mockupWrapperLeft?.width / 33.54).toFixed(3));
      //   } else {
      //     setNewImageFormulaLeft(
      //       (mockupWrapperLeft?.width / leftDimension).toFixed(3)
      //     );
      //   }
      //   if (rightDimension === "") {
      //     setNewImageFormulaRight(
      //       (mockupWrapperRight?.width / 33.54).toFixed(3)
      //     );
      //   } else {
      //     setNewImageFormulaRight(
      //       (mockupWrapperRight?.width / rightDimension).toFixed(3)
      //     );
      //   }
      //   setNewImageFormulaNeck((mockupWrapperNeck?.width / 33.54).toFixed(3));
      // }
    }
    // eslint-disable-next-line
  }, [
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    mockupWrapperFront,
    mockupWrapperBack,
    mockupWrapperLeft,
    mockupWrapperRight,
    mockupWrapperNeck,
  ]);

  return {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  };
};
