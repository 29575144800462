import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import Default from "../components/Sidebar/Default";
import {
  clearSaveNoteMsg,
  fetchGetSaveNoteData,
  fetchSaveNoteData,
} from "../lib/slice/saveNoteSlice";

const AddNotes = ({ isDefaultOption, setIsDefaultOption }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();

  const customerId = localStorage.getItem("customer");
  const storeId = useSelector((state) => state.dashboard.data?.cur_store_id);
  const { data } = useSelector((state) => state.saveNote);
  const productid = useSelector(
    (state) => state.dashboard.data?.productid
  );
  const successMessage = useSelector((state) => state.saveNote.successMessage);

  const [note, setNote] = useState("");

  useEffect(() => {
    if (customerId && storeId && productid) {
      const formData = new FormData();
      formData.append("product_id", productid);
      formData.append("customer", customerId);
      formData.append("cur_store_id", storeId);
      dispatch(fetchGetSaveNoteData(formData));
    }
  }, [dispatch, customerId, storeId, productid]);

  useEffect(() => {
    setNote(data?.title);
  }, [data?.title]);

  useEffect(() => {
    let timeout;
    if (successMessage) {
      timeout = setTimeout(() => {
        dispatch(clearSaveNoteMsg());
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [successMessage, dispatch]);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile
              ? "mbl-notes-option mbl-option bottom-option"
              : "notes-option-details option-box"
          }
          style={{
            position: ismobile ? "fixed" : "relative",
            display: "inline-block",
          }}
        >
          <div className="option-heading">
            <div className="back-btn" onClick={() => setIsDefaultOption(true)}>
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Save Notes</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <div className="add-notes-details">
            <p>Leave a note for our team.</p>
            {successMessage && (
              <div className="notesblock">
                <span className="validation_msg">{successMessage}</span>
              </div>
            )}
            <form>
              <div className="input-group">
                <textarea
                  className="form-control notesdetails"
                  style={{ backgroundColor: "transparent", zIndex: 0 }}
                  rows={5}
                  id="add-text"
                  placeholder="Enter text here"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </form>
            <button
              type="button"
              className="orange-btn savenotes"
              onClick={() => {
                const formData = new FormData();
                formData.append("customerid", customerId);
                formData.append("productid", productid);
                formData.append("cur_store_id", storeId);
                formData.append("notesdetails", note);
                dispatch(fetchSaveNoteData(formData));
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNotes;
