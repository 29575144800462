import React, { useEffect, useMemo, useRef } from "react";
import { Text } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { useFormula } from "../../lib/hooks/useFormula";
import {
  fetchConvertImageArtData,
  fetchCreateTexttoImageData,
} from "../../lib/slice/createTextToImageSlice";
import { updateBase64Data } from "../../lib/slice/designerSlice";
import DesignWrapper from "./DesignWrapper";

const LayerText = ({
  isSelected,
  onSelect,
  designProp,
  onChange,
  isHovered,
  setIsIconVisible,
  isDownload,
  v,
}) => {
  const dispatch = useDispatch();

  const trRef = useRef();
  const shapeRef = useRef();

  const {
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    mockupWrapperFront,
    mockupWrapperBack,
    mockupWrapperLeft,
    mockupWrapperRight,
    mockupWrapperNeck,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    view,
    selectedLayer,
  } = useSelector((state) => state.designer);
  const dashboard = useSelector((state) => state.dashboard?.data);
  const selectedLayerWidth = useSelector(
    (state) => state.designer.selectedLayer?.config?.width
  );
  const shopUrl = useSelector((state) => state.dashboard.data?.shop_url);
  const pid = useSelector((state) => state.dashboard.data?.productid);

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];
  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();

  const layerConfig = useMemo(() => {
    return {
      x: designProp?.config?.x,
      y: designProp?.config?.y,
      fontFamily: designProp?.config?.fontFamily,
      fontSize: designProp?.config?.fontSize,
      fill: designProp?.config?.fill,
      textDecoration: designProp?.config?.textDecoration,
      strokeWidth: designProp?.config?.strokeWidth,
      stroke: designProp?.config?.stroke,
      fillAfterStrokeEnabled: designProp?.config?.fillAfterStrokeEnabled,
    };
    // eslint-disable-next-line
  }, [
    designProp.config.x,
    designProp.config.y,
    designProp.config.fontFamily,
    designProp.config.fontSize,
    designProp.config.fill,
    designProp.config.textDecoration,
    designProp.config.strokeWidth,
    designProp.config.stroke,
    designProp.config.fillAfterStrokeEnabled,
  ]);

  useEffect(() => {
    if (selectedLayer && selectedLayer?.type === "TEXT") {
      const formData = new FormData();
      formData.append("text", selectedLayer?.content);
      v === "front" &&
        formData.append(
          "font_size",
          (selectedLayer?.config?.fontSize / NewImageFormulaFront).toFixed(2)
        );
      v === "back" &&
        formData.append(
          "font_size",
          (selectedLayer?.config?.fontSize / NewImageFormulaBack).toFixed(2)
        );
      v === "left" &&
        formData.append(
          "font_size",
          (selectedLayer?.config?.fontSize / NewImageFormulaLeft).toFixed(2)
        );
      v === "right" &&
        formData.append(
          "font_size",
          (selectedLayer?.config?.fontSize / NewImageFormulaRight).toFixed(2)
        );
      v === "neck" &&
        formData.append(
          "font_size",
          (selectedLayer?.config?.fontSize / NewImageFormulaNeck).toFixed(2)
        );
      formData.append("font", selectedLayer?.config?.fontFamily);
      formData.append("color", selectedLayer?.config?.fill);
      formData.append("outercolor", selectedLayer?.config?.stroke);
      formData.append("shop_url", shopUrl);
      v === "front" &&
        formData.append(
          "outershap",
          (selectedLayer?.config?.strokeWidth / NewImageFormulaFront).toFixed(2)
        );
      v === "back" &&
        formData.append(
          "outershap",
          (selectedLayer?.config?.strokeWidth / NewImageFormulaBack).toFixed(2)
        );
      v === "left" &&
        formData.append(
          "outershap",
          (selectedLayer?.config?.strokeWidth / NewImageFormulaLeft).toFixed(2)
        );
      v === "right" &&
        formData.append(
          "outershap",
          (selectedLayer?.config?.strokeWidth / NewImageFormulaRight).toFixed(2)
        );
      v === "neck" &&
        formData.append(
          "outershap",
          (selectedLayer?.config?.strokeWidth / NewImageFormulaNeck).toFixed(2)
        );
      v === "front" && formData.append("image_src", mockupWrapperFront?.width);
      v === "back" && formData.append("image_src", mockupWrapperBack?.width);
      v === "left" && formData.append("image_src", mockupWrapperLeft?.width);
      v === "right" && formData.append("image_src", mockupWrapperRight?.width);
      v === "neck" && formData.append("image_src", mockupWrapperNeck?.width);

      dispatch(fetchCreateTexttoImageData(formData))
        .then((response) => {
          if (response?.payload) {
            const formCreateTexttoImage = new FormData();
            formCreateTexttoImage.append("image_url", response?.payload?.name);
            formCreateTexttoImage.append("upload_type", "text");
            formCreateTexttoImage.append("cat_name", dashboard?.productType);
            formCreateTexttoImage.append("pid", pid);
            return dispatch(fetchConvertImageArtData(formCreateTexttoImage));
          }
        })
        .then((response) => {
          if (response?.payload) {
            const targetLayerIndex = currentDesignsView.findIndex(
              (d) => d?.config?.id === selectedLayer?.config?.id
            );
            if (targetLayerIndex === -1) return;

            const tempLayers = [...currentDesignsView];
            tempLayers[targetLayerIndex] = {
              ...selectedLayer,
              base64High: response?.payload?.highimagedataurl,
              base64: response?.payload?.imageurl,
            };
            dispatch(
              updateBase64Data({
                designId: selectedLayer?.config?.id,
                base64: response?.payload?.imageurl,
                base64High: response?.payload?.highimagedataurl,
              })
            );
          }
        });
    }
    // eslint-disable-next-line
  }, [
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
    selectedLayer?.config?.fill,
    selectedLayer?.config?.fontFamily,
    selectedLayer?.config?.fontSize,
    selectedLayer?.config?.stroke,
    selectedLayer?.config?.strokeWidth,
    selectedLayer?.content,
    dispatch,
    shopUrl,
    mockupWrapperFront?.width,
    mockupWrapperBack?.width,
    mockupWrapperLeft?.width,
    mockupWrapperRight?.width,
    mockupWrapperNeck?.width,
  ]);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, selectedLayerWidth]);

  useEffect(() => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    const width = Math.max(5, node.width() * scaleX);
    const height = Math.max(5, node.height() * scaleY);

    onChange({
      ...designProp,
      config: {
        ...designProp.config,
        width: width,
        height: height,
        fontSize: height / designProp?.content.split("\n").length,
      },
    });
    // eslint-disable-next-line
  }, [
    layerConfig.x,
    layerConfig.y,
    layerConfig.fontFamily,
    layerConfig.fontSize,
    layerConfig.fill,
    layerConfig.fillAfterStrokeEnabled,
    layerConfig.stroke,
    layerConfig.strokeWidth,
    layerConfig.textDecoration,
    designProp.content,
  ]);

  return (
    <DesignWrapper
      isSelected={isSelected}
      trRef={trRef}
      enabledAnchors={["bottom-right"]}
      isHovered={isHovered}
      isDownload={isDownload}
    >
      <Text
        ref={shapeRef}
        text={designProp.content}
        onClick={onSelect}
        onTap={onSelect}
        verticalAlign="middle"
        draggable
        {...layerConfig}
        onDragStart={() => {
          setIsIconVisible(false);
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              isDragging: true,
            },
          });
        }}
        onDragMove={(e) => {
          const newX = Math.max(
            0,
            Math.min(
              (view === "front"
                ? frontClientCanvas?.canvasWidth
                : view === "back"
                ? backClientCanvas?.canvasWidth
                : view === "left"
                ? leftClientCanvas?.canvasWidth
                : view === "right"
                ? rightClientCanvas?.canvasWidth
                : view === "neck"
                ? neckClientCanvas?.canvasWidth
                : 0) - (designProp.config.width || 0),
              e.target.x()
            )
          );
          const newY = Math.max(
            0,
            Math.min(
              (view === "front"
                ? frontClientCanvas?.canvasHeightFull
                : view === "back"
                ? backClientCanvas?.canvasHeightFull
                : view === "left"
                ? leftClientCanvas?.canvasHeightFull
                : view === "right"
                ? rightClientCanvas?.canvasHeightFull
                : view === "neck"
                ? neckClientCanvas?.canvasHeightFull
                : 0) - (designProp.config.height || 0),
              e.target.y()
            )
          );
          e.target.x(newX);
          e.target.y(newY);
        }}
        onDragEnd={(e) => {
          const currentX = Math.max(0, e.target.x());
          const currentY = Math.max(0, e.target.y());
          const newX = Math.max(
            0,
            Math.min(
              (view === "front"
                ? frontClientCanvas?.canvasWidth
                : view === "back"
                ? backClientCanvas?.canvasWidth
                : view === "left"
                ? leftClientCanvas?.canvasWidth
                : view === "right"
                ? rightClientCanvas?.canvasWidth
                : view === "neck"
                ? neckClientCanvas?.canvasWidth
                : 0) - (designProp.config.width || 0),
              currentX
            )
          );
          const newY = Math.max(
            0,
            Math.min(
              (view === "front"
                ? frontClientCanvas?.canvasHeightFull
                : view === "back"
                ? backClientCanvas?.canvasHeightFull
                : view === "left"
                ? leftClientCanvas?.canvasHeightFull
                : view === "right"
                ? rightClientCanvas?.canvasHeightFull
                : view === "neck"
                ? neckClientCanvas?.canvasHeightFull
                : 0) - (designProp.config.height || 0),
              currentY
            )
          );

          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: newX,
              y: newY,
            },
          });
          setIsIconVisible(true);
        }}
        onTransformStart={() => setIsIconVisible(false)}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          const newWidth = Math.max(5, node.width() * scaleX);
          const newHeight = Math.max(node.height() * scaleY);
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: node.x(),
              y: node.y(),
              width: newWidth,
              height: newHeight,
              fontSize: newHeight / designProp?.content.split("\n").length,
            },
          });
          setIsIconVisible(true);
        }}
      />
    </DesignWrapper>
  );
};

export default LayerText;
