import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { removeAllDesign } from "../lib/slice/designerSlice";

const CartModal = ({ closeModal }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Wrapper className="swal2-overlay">
      <div className="swal2-modal">
        <div className="swal2-icon swal2-success animate">
          <span className="line tip animate-success-tip" />
          <span className="line long animate-success-long" />
          <div className="placeholder" /> <div className="fix" />
        </div>
        <h2>Added To Cart Successfully</h2>
        <div className="swal2-content">
          <span className={ismobile ? "swal_title" : ""}>
            What Would You Like To Do Next?
          </span>
          <br />
          <div
            className={
              ismobile ? "col-md-8 mainheaderswal" : "col-md-12 mainheaderswal"
            }
            style={ismobile ? { margin: "10px 0 0 0" } : {}}
          >
            <button
              type="button"
              className="SwalBtn1 mainheaderswalbutton"
              style={{ fontSize: 16 }}
              onClick={() => {
                closeModal();
                navigate("/cart");
              }}
            >
              Go To Cart
            </button>
          </div>
          {/* <div
              className={ismobile ? "col-md-8" : "col-md-4 customebutton"}
              style={{ marginLeft: ismobile ? 0 : 40 }}
            >
              <button
                type="button"
                className={`SwalBtn1 mainheaderswalcustombutton cloneotherproduct${
                  ismobile ? " mobile" : ""
                }`}
                style={ismobile ? { marginTop: 10 } : { height: 71 }}
                onClick={() => {
                  closeModal();
                  navigate("/products");
                }}
              >
                Add Another Product To Same Design
              </button>
            </div> */}
          {/* <div className={ismobile ? "col-md-8" : "col-md-4 customebutton"}>
              <button
                type="button"
                className={`SwalBtn1 mainheaderswalcustombutton${
                  ismobile ? " mobile" : ""
                }`}
                style={ismobile ? { marginTop: 10 } : { height: 71 }}
                onClick={() => {
                  dispatch(removeAllDesign());
                  navigate("/products");
                }}
              >
                Work On New Design
              </button>
            </div> */}
          <div
            className={
              ismobile ? "col-md-8 mainheaderswal" : "col-md-12 mainheaderswal"
            }
            style={ismobile ? { margin: "10px 0 0 0" } : { marginTop: 0 }}
          >
            <button
              type="button"
              style={{ fontSize: 16 }}
              className="SwalBtn1 mainheaderswalbutton"
              onClick={() => {
                dispatch(removeAllDesign());
                // navigate("/products");
                window.location.href =
                  "https://imprintmaker.com/pages/choose-an-option";
              }}
            >
              Work On A New Item
            </button>
          </div>
          {/* <div className={ismobile ? "col-md-8" : "col-md-4 customebutton"}>
              <button
                type="button"
                className={`SwalBtn1 mainheaderswalcustombutton backsamepoduct${
                  ismobile ? " mobile" : ""
                }`}
                style={ismobile ? { marginTop: 10 } : { height: 71 }}
                onClick={() => {
                  dispatch(removeAllDesign());
                  navigate("/product-color");
                }}
              >
                Back To Same Product
              </button>
            </div> */}
        </div>
        <span className="swal2-close" onClick={closeModal}>
          ×
        </span>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  z-index: 9999;

  .mainheaderswalbutton,
  .mainheaderswalcustombutton {
    height: unset;
    min-height: 43px;
  }

  .swal2-modal {
    display: block;
    max-width: 500px;
    min-width: 290px;
    margin: 0;
    padding: 20px;
    background: #fff;
    transform: translate(-50%, -50%);
  }

  .swal2-icon,
  .swal2-content,
  .swal2-close {
    display: block;
  }
`;

export default CartModal;
