import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFormula } from "../lib/hooks/useFormula";
import { setFullImage, setSelectedLayer } from "../lib/slice/designerSlice";
import { fetchSaveImageArt } from "../lib/slice/storePrintArtSlice";
import SaveModal from "./SaveModal";
import WithLoader from "./WithLoader";

const Footer = ({ setIsDownload, setLoader, setLoaderMsg }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    outsideDesign,
    maxPrintDesign,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
    imgData,
  } = useSelector((state) => state.designer);
  const { colorArtData } = useSelector((state) => state.color);
  const currentProduct = useSelector(
    (state) => state.categoryByProduct.currentProduct
  );
  const dashboardData = useSelector((state) => state.dashboard.data);

  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();

  const [isSave, setIsSave] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const captureCanvasImageSave = (selector, viewName, btnName) => {
    const containers = document.querySelectorAll(selector);

    containers.forEach((container) => {
      if (container) {
        html2canvas(container, {
          useCORS: true,
          allowTaint: false,
          logging: true,
        }).then((canvas) => {
          const dataUrl = canvas.toDataURL("image/png");
          dispatch(setFullImage({ position: viewName, payload: dataUrl }));

          if (btnName === "download") {
            const a = document.createElement("a");
            a.href = dataUrl;
            a.download = `${viewName}.png`;
            a.click();
            setLoader(false);
            setLoaderMsg("");
          }
        });
      }
    });
  };

  const handleSave = async (btnName) => {
    const designInfo = [
      {
        designs: frontDesigns,
        viewName: "front",
        querySelector: ".pro-width-front",
      },
      {
        designs: backDesigns,
        viewName: "back",
        querySelector: ".pro-width-back",
      },
      {
        designs: leftDesigns,
        viewName: "left",
        querySelector: ".pro-width-left",
      },
      {
        designs: rightDesigns,
        viewName: "right",
        querySelector: ".pro-width-right",
      },
      {
        designs: neckDesigns,
        viewName: "neck",
        querySelector: ".pro-width-neck",
      },
    ];
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    for (const { designs, viewName, querySelector } of designInfo) {
      if (designs?.length) {
        await delay(1000);
        captureCanvasImageSave(querySelector, viewName, btnName);
      }
    }

    setTimeout(() => {
      setIsDownload(false);
      btnName === "save" && setIsSave(true);
    }, 2000);
  };

  useEffect(() => {
    if (isSave) {
      const formData = new FormData();
      formData.append("customerid", dashboardData?.customerid);
      formData.append("cur_store_id", dashboardData?.cur_store_id);
      formData.append("productName", currentProduct?.name);
      formData.append("mainproductid", dashboardData?.productid);
      formData.append("storeproductid", dashboardData?.storeproductid);
      formData.append("product_sku", currentProduct?.sku);
      formData.append("push_color_code", colorArtData[0]?.id);
      frontDesigns &&
        frontDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaFront
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaFront
          ).toFixed(2);

          const artImageDetails = {
            canvasname: `canvasimgcanvas${i}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
          };

          if (design?.type === "IMAGE") {
            for (const key in artImageDetails) {
              formData.append(
                `artImageDetails[${i}][${key}]`,
                design?.type === "IMAGE" ? artImageDetails[key] : ""
              );
            }
          }
        });

      backDesigns &&
        backDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaBack
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaBack
          ).toFixed(2);

          const artImageDetails = {
            canvasname: `canvasimgcanvasback${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
          };

          if (design?.type === "IMAGE") {
            for (const key in artImageDetails) {
              formData.append(
                `artImageDetails[${i + frontDesigns?.length}][${key}]`,
                design?.type === "IMAGE" ? artImageDetails[key] : ""
              );
            }
          }
        });

      leftDesigns &&
        leftDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaLeft
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaLeft
          ).toFixed(2);

          const artImageDetails = {
            canvasname: `canvasimgcanvasleft${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
          };

          if (design?.type === "IMAGE") {
            for (const key in artImageDetails) {
              formData.append(
                `artImageDetails[${
                  i + frontDesigns?.length + backDesigns?.length
                }][${key}]`,
                design?.type === "IMAGE" ? artImageDetails[key] : ""
              );
            }
          }
        });

      rightDesigns &&
        rightDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaRight
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaRight
          ).toFixed(2);

          const artImageDetails = {
            canvasname: `canvasimgcanvasright${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
          };

          if (design?.type === "IMAGE") {
            for (const key in artImageDetails) {
              formData.append(
                `artImageDetails[${
                  i +
                  frontDesigns?.length +
                  backDesigns?.length +
                  leftDesigns?.length
                }][${key}]`,
                design?.type === "IMAGE" ? artImageDetails[key] : ""
              );
            }
          }
        });

      neckDesigns &&
        neckDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaNeck
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaNeck
          ).toFixed(2);

          const artImageDetails = {
            canvasname: `canvasimgcanvasneck${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
          };

          if (design?.type === "IMAGE") {
            for (const key in artImageDetails) {
              formData.append(
                `artImageDetails[${
                  i +
                  frontDesigns?.length +
                  backDesigns?.length +
                  leftDesigns?.length +
                  rightDesigns?.length
                }][${key}]`,
                design?.type === "IMAGE" ? artImageDetails[key] : ""
              );
            }
          }
        });

      frontDesigns?.length &&
        formData.append(`image_url[0][${"name"}]`, "canvasimag");
      frontDesigns?.length &&
        formData.append(`image_url[0][${"value"}]`, imgData?.frontFullImage);
      backDesigns?.length &&
        formData.append(
          `canvasbackimage[0][${"value"}]`,
          imgData?.backFullImage
        );
      backDesigns?.length &&
        formData.append(`canvasbackimage[0][${"name"}]`, "canvasimag");
      leftDesigns?.length &&
        formData.append(
          `canvasleftimage[0][${"value"}]`,
          imgData?.leftFullImage
        );
      leftDesigns?.length &&
        formData.append(`canvasleftimage[0][${"name"}]`, "canvasimag");
      rightDesigns?.length &&
        formData.append(
          `canvasrightimage[0][${"value"}]`,
          imgData?.rightFullImage
        );
      rightDesigns?.length &&
        formData.append(`canvasrightimage[0][${"name"}]`, "canvasimag");
      neckDesigns?.length &&
        formData.append(
          `canvasneckimage[0][${"value"}]`,
          imgData?.neckFullImage
        );
      neckDesigns?.length &&
        formData.append(`canvasneckimage[0][${"name"}]`, "canvasimag");
      const a = frontDesigns?.filter((v) => v?.type === "IMAGE");
      if (a?.length) {
        formData.append(
          `image_url[0][dataartid]`,
          a.map((i) => i.config.id).join(", ")
        );
      }

      const b = backDesigns?.filter((v) => v?.type === "IMAGE");
      if (b?.length) {
        formData.append(
          `canvasbackimage[0][dataartid]`,
          b.map((i) => i.config.id).join(", ")
        );
      }

      const c = leftDesigns?.filter((v) => v?.type === "IMAGE");
      if (c?.length) {
        formData.append(
          `canvasleftimage[0][dataartid]`,
          c.map((i) => i.config.id).join(", ")
        );
      }

      const d = rightDesigns?.filter((v) => v?.type === "IMAGE");
      if (d?.length) {
        formData.append(
          `canvasrightimage[0][dataartid]`,
          d.map((i) => i.config.id).join(", ")
        );
      }

      const e = neckDesigns?.filter((v) => v?.type === "IMAGE");
      if (e?.length) {
        formData.append(
          `canvasneckimage[0][dataartid]`,
          e.map((i) => i.config.id).join(", ")
        );
      }

      dispatch(fetchSaveImageArt(formData)).then((response) => {
        setIsSave(false);
        if (response?.payload) {
          setLoader(false);
          setLoaderMsg("");
          openModal();
        }
      });
    }
    // eslint-disable-next-line
  }, [isSave]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Wrapper $ismobile={ismobile}>
      <button
        type="button"
        className="footer-btn"
        style={
          ismobile
            ? {
                display: "inline-block",
                margin: "0 3px",
                fontSize: 12,
                padding: "8px 5px",
                minWidth: 220,
                border: "1px solid #E33F3A",
                borderRadius: 5,
                fontWeight: 600,
              }
            : {
                display: "block",
                margin: "0",
                fontSize: 12,
                padding: "8px 5px",
                minWidth: 220,
                border: "1px solid #E33F3A",
                borderRadius: 5,
                fontWeight: 600,
              }
        }
        onClick={() => {
          setLoader(true);
          setLoaderMsg("This step may take up to 30 seconds.");
          setIsDownload(true);
          handleSave("save");
        }}
      >
        Save Design
        {!ismobile && <> For Later</>}
      </button>
      <button
        type="button"
        className="footer-btn"
        style={{
          display: ismobile ? "inline-block" : "block",
          margin: ismobile ? "10px 3px 0 3px" : "10px 0",
          fontSize: 12,
          padding: "8px 5px",
          minWidth: 220,
          border: "1px solid #E33F3A",
          borderRadius: 5,
          fontWeight: 600,
          opacity:
            (!frontDesigns?.length &&
              !backDesigns?.length &&
              !leftDesigns?.length &&
              !rightDesigns?.length &&
              !neckDesigns?.length) ||
            Object.values(outsideDesign).some((value) => value)
              ? 0.6
              : 1,
        }}
        onClick={() => {
          setLoader(true);
          setIsDownload(true);
          handleSave("download");
        }}
        disabled={
          (!frontDesigns?.length &&
            !backDesigns?.length &&
            !leftDesigns?.length &&
            !rightDesigns?.length &&
            !neckDesigns?.length) ||
          Object.values(outsideDesign).some((value) => value)
        }
      >
        Download Mockup
      </button>
      <button
        type="button"
        className={`orange-btn ${ismobile ? "" : "cartbtn"}`}
        style={
          ismobile
            ? {
                display: "inline-block",
                margin: "10px 3px 0 3px",
                fontSize: 12,
                marginBottom: 10,
                opacity:
                  Object.values(outsideDesign).some((value) => value) ||
                  Object.values(maxPrintDesign).some((value) => value)
                    ? 0.6
                    : 1,
                minWidth: 220,
                fontWeight: 700,
              }
            : {
                display: "block",
                marginTop: 10,
                marginBottom: 10,
                minWidth: 220,
                opacity:
                  Object.values(outsideDesign).some((value) => value) ||
                  Object.values(maxPrintDesign).some((value) => value)
                    ? 0.6
                    : 1,
              }
        }
        onClick={() => {
          dispatch(setSelectedLayer(null));
          navigate("/approve-mockup");
        }}
        disabled={
          Object.values(outsideDesign).some((value) => value) ||
          Object.values(maxPrintDesign).some((value) => value)
        }
      >
        Approve Mockup
        {!ismobile && (
          <>
            <br />
            &amp; Select Quantity
          </>
        )}
      </button>
      {Object.values(outsideDesign).some((value) => value) && (
        <div
          style={
            ismobile
              ? { fontSize: 11, color: "red" }
              : { fontSize: 11, width: 220, color: "red", textAlign: "center" }
          }
        >
          {Object.keys(outsideDesign)
            .filter((key) => outsideDesign[key])
            .map((key, index, array) => (
              <span key={index}>
                {index > 0 && index === array.length - 1
                  ? " and "
                  : index > 0
                  ? ", "
                  : ""}
                {key}
              </span>
            ))}
          : Please position your artwork inside print area.
        </div>
      )}
      {Object.keys(maxPrintDesign)
        .filter((value) => maxPrintDesign[value])
        .map((value) => {
          let printArea;
          let printName;

          switch (value) {
            case "front":
              printArea = `${currentFrontPrint?.front_area_width_float} X ${currentFrontPrint?.front_area_height_float}`;
              printName = currentFrontPrint?.front_name;
              break;
            case "back":
              printArea = `${currentBackPrint?.back_area_width_float} X ${currentBackPrint?.back_area_height_float}`;
              printName = currentBackPrint?.back_name;
              break;
            case "left":
              printArea = `${currentLeftPrint?.left_area_width_float} X ${currentLeftPrint?.left_area_height_float}`;
              printName = currentLeftPrint?.left_name;
              break;
            case "right":
              printArea = `${currentRightPrint?.right_area_width_float} X ${currentRightPrint?.right_area_height_float}`;
              printName = currentRightPrint?.right_name;
              break;
            case "neck":
              printArea = `${currentNeckPrint?.neck_area_width_float} X ${currentNeckPrint?.neck_area_height_float}`;
              printName = currentNeckPrint?.neck_name;
              break;
            default:
              printArea = null;
              printName = null;
          }

          if (printArea && printName) {
            return (
              <div
                key={value}
                style={
                  ismobile
                    ? { fontSize: 11, color: "red" }
                    : {
                        fontSize: 11,
                        width: 220,
                        color: "red",
                        textAlign: "center",
                      }
                }
              >
                {`${value} : Max size allowed for ${printName} : ${printArea}`}
              </div>
            );
          }

          return null;
        })}
      {isModalOpen && <SaveModal closeModal={closeModal} />}
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  text-align: ${(props) => (props.$ismobile ? "center" : "left")};
  position: ${(props) => (props.$ismobile ? "relative" : "fixed")};
  padding: ${(props) => (props.$ismobile ? "0" : "1px")};
  bottom: ${(props) => (props.$ismobile ? "auto" : "65px")};
  right: ${(props) => (props.$ismobile ? "auto" : "20px")};

  .footer-btn {
    color: #e33f3a;
    background-color: #fff;
  }

  .footer-btn:hover {
    color: #fff;
    background-color: #e33f3a;
  }
`;

export default WithLoader(Footer);
