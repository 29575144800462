import React, { useEffect, useMemo, useRef } from "react";
import { Image } from "react-konva";
import { useSelector } from "react-redux";
import useImage from "use-image";
import { useFormula } from "../../lib/hooks/useFormula";
import DesignWrapper from "./DesignWrapper";

const LayerImage = ({
  onSelect,
  isSelected,
  designProp,
  onChange,
  isHovered,
  setIsIconVisible,
  isDownload,
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  const {
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    view,
  } = useSelector((state) => state.designer);
  const embroidery_stitch = useSelector(
    (state) => state.dashboard?.data?.embroidery_stitch
  );
  const productType = useSelector(
    (state) => state.dashboard?.data?.productType
  );
  const selectedLayerWidth = useSelector(
    (state) => state.designer.selectedLayer?.config?.width
  );
  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();

  const [image] = useImage(designProp.asset, "Anonymous");

  const layerConfig = useMemo(() => {
    return {
      x: designProp?.config?.x,
      y: designProp?.config?.y,
    };
    // eslint-disable-next-line
  }, [designProp?.config?.x, designProp?.config?.y]);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, selectedLayerWidth]);

  const format_embroidery_stitch = (embroidery_stitch || [])?.map((i) => {
    return { ...i, area: +i?.size?.split("X")[0] * +i?.size?.split("X")[1] };
  });

  function findClosestSize(area) {
    const sortedData = [...format_embroidery_stitch]?.sort(
      (a, b) => a?.area - b?.area
    );
    for (const item of sortedData) {
      if (item?.area >= area) {
        return +item?.stitch;
      }
    }
    return +sortedData[sortedData.length - 1]?.stitch;
  }

  useEffect(() => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    const width = Math.max(5, node.width() * scaleX);
    const height = Math.max(node.height() * scaleY);

    let NewImageFormula;
    if (view === "front") {
      NewImageFormula = NewImageFormulaFront;
    } else if (view === "back") {
      NewImageFormula = NewImageFormulaBack;
    } else if (view === "left") {
      NewImageFormula = NewImageFormulaLeft;
    } else if (view === "right") {
      NewImageFormula = NewImageFormulaRight;
    } else if (view === "neck") {
      NewImageFormula = NewImageFormulaNeck;
    }

    console.log("+++", +width / NewImageFormula, +height / NewImageFormula);

    onChange({
      ...designProp,
      config: {
        ...designProp?.config,
        width: width,
        height: height,
        // totalStitches: (() => {
        //   const totalStitches =
        //     (+width / NewImageFormula) *
        //     (+height / NewImageFormula) *
        //     (designProp?.config?.occupiedPixelsPercentage / 100) *
        //     2000;
        //   return parseInt(totalStitches);
        // })(),
        totalStitches:
          productType === "Embroidery"
            ? parseInt(
                findClosestSize(
                  (+width / NewImageFormula) * +(height / NewImageFormula)
                ) *
                  (+designProp?.config?.occupiedPixelsPercentage / 100)
              )
            : 0,
      },
    });
    // eslint-disable-next-line
  }, [
    layerConfig.x,
    layerConfig.y,
    designProp.asset,
    designProp.config.width,
    designProp.config.height,
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  ]);

  return (
    <DesignWrapper
      isSelected={isSelected}
      trRef={trRef}
      enabledAnchors={["bottom-right"]}
      isHovered={isHovered}
      isDownload={isDownload}
    >
      <Image
        ref={shapeRef}
        image={image}
        onClick={onSelect}
        onTap={onSelect}
        draggable
        {...designProp?.config}
        onDragStart={() => {
          setIsIconVisible(false);
          onChange({
            ...designProp,
            config: {
              ...designProp?.config,
              isDragging: true,
            },
          });
        }}
        onDragMove={(e) => {
          const newX = Math.max(
            0,
            Math.min(
              Number(
                (view === "front"
                  ? frontClientCanvas?.canvasWidth
                  : view === "back"
                  ? backClientCanvas?.canvasWidth
                  : view === "left"
                  ? leftClientCanvas?.canvasWidth
                  : view === "right"
                  ? rightClientCanvas?.canvasWidth
                  : view === "neck"
                  ? neckClientCanvas?.canvasWidth
                  : 0) - (designProp.config.width || 0)
              ),
              e.target.x()
            )
          );
          const newY = Math.max(
            0,
            Math.min(
              Number(
                (view === "front"
                  ? frontClientCanvas?.canvasHeightFull
                  : view === "back"
                  ? backClientCanvas?.canvasHeightFull
                  : view === "left"
                  ? leftClientCanvas?.canvasHeightFull
                  : view === "right"
                  ? rightClientCanvas?.canvasHeightFull
                  : view === "neck"
                  ? neckClientCanvas?.canvasHeightFull
                  : 0) - (designProp.config.height || 0)
              ),
              e.target.y()
            )
          );
          e.target.x(newX);
          e.target.y(newY);
        }}
        onDragEnd={(e) => {
          const newX = Math.max(
            0,
            Math.min(
              Number(
                (view === "front"
                  ? frontClientCanvas?.canvasWidth
                  : view === "back"
                  ? backClientCanvas?.canvasWidth
                  : view === "left"
                  ? leftClientCanvas?.canvasWidth
                  : view === "right"
                  ? rightClientCanvas?.canvasWidth
                  : view === "neck"
                  ? neckClientCanvas?.canvasWidth
                  : 0) - (designProp.config.width || 0)
              ),
              e.target.x()
            )
          );
          const newY = Math.max(
            0,
            Math.min(
              Number(
                (view === "front"
                  ? frontClientCanvas?.canvasHeightFull
                  : view === "back"
                  ? backClientCanvas?.canvasHeightFull
                  : view === "left"
                  ? leftClientCanvas?.canvasHeightFull
                  : view === "right"
                  ? rightClientCanvas?.canvasHeightFull
                  : view === "neck"
                  ? neckClientCanvas?.canvasHeightFull
                  : 0) - (designProp.config.height || 0)
              ),
              e.target.y()
            )
          );
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: newX,
              y: newY,
            },
          });
          setIsIconVisible(true);
        }}
        onTransformStart={() => setIsIconVisible(false)}
        onTransformEnd={() => {
          setIsIconVisible(true);

          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          const width = Math.max(5, node.width() * scaleX);
          const height = Math.max(node.height() * scaleY);
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: node.x(),
              y: node.y(),
              width: width,
              height: height,
            },
          });
        }}
      />
    </DesignWrapper>
  );
};

export default LayerImage;
