import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormula } from "../../lib/hooks/useFormula";
import { setCurrentDesignsView } from "../../lib/slice/designerSlice";

const TextSizeContent = ({
  setTextLayer,
  textLayer,
  enableInputRef,
  setIsDefaultOption,
}) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();

  const {
    selectedLayer,
    view,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
  } = useSelector((state) => state.designer);

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];
  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();
  const getViewFormula = (view) => {
    switch (view) {
      case "front":
        return NewImageFormulaFront;
      case "back":
        return NewImageFormulaBack;
      case "left":
        return NewImageFormulaLeft;
      case "right":
        return NewImageFormulaRight;
      case "neck":
        return NewImageFormulaNeck;
      default:
        return 1;
    }
  };

  const selectedFormula = getViewFormula(view);
  const [inputValue, setInputValue] = useState(0);
  const [autoCenter, setAutoCenter] = useState(false);

  const roundToNearestQuarter = (number) => {
    return (Math.round(number * 10) / 10).toFixed(2);
  };

  useEffect(() => {
    setInputValue(
      roundToNearestQuarter(selectedLayer?.config?.fontSize / selectedFormula)
    );
  }, [selectedLayer?.config?.fontSize, selectedFormula]);

  useEffect(() => {
    if (autoCenter) {
      designAlign("HorizontalCenter");
      setAutoCenter(false);
    }
    // eslint-disable-next-line
  }, [selectedLayer?.config?.width]);

  const onChangeAlignment = (config) => {
    const targetLayerIndex = currentDesignsView.findIndex(
      (d) => d?.config?.id === selectedLayer?.config.id
    );
    if (targetLayerIndex === -1) return;
    const tempLayers = [...currentDesignsView];
    tempLayers[targetLayerIndex] = {
      ...selectedLayer,
      config: { ...selectedLayer?.config, ...config },
    };

    dispatch(setCurrentDesignsView(tempLayers));
  };

  const designAlign = (ALIGN) => {
    if (!selectedLayer) return;

    switch (ALIGN) {
      case "HorizontalCenter":
        return onChangeAlignment({
          x:
            ((view === "front"
              ? frontClientCanvas?.canvasWidth
              : view === "back"
              ? backClientCanvas?.canvasWidth
              : view === "left"
              ? leftClientCanvas?.canvasWidth
              : view === "right"
              ? rightClientCanvas?.canvasWidth
              : view === "neck"
              ? neckClientCanvas?.canvasWidth
              : 0) -
              selectedLayer?.config?.width) /
            2,
        });
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    setAutoCenter(true);
    if (
      Number(e.target.value) <=
      (view === "front"
        ? Number(currentFrontPrint?.front_area_height_float)
        : view === "back"
        ? Number(currentBackPrint?.back_area_height_float)
        : view === "left"
        ? Number(currentLeftPrint?.left_area_height_float)
        : view === "right"
        ? Number(currentRightPrint?.right_area_height_float)
        : view === "neck"
        ? Number(currentNeckPrint?.neck_area_height_float)
        : 0)
    ) {
      const newValue = Number(e.target.value);
      setInputValue(newValue);
      enableInputRef.current = true;
      setTextLayer({
        ...textLayer,
        config: {
          ...textLayer?.config,
          fontSize: newValue * selectedFormula,
          height:
            newValue * selectedFormula * textLayer?.content.split("\n").length,
        },
      });
    }
  };

  return (
    <>
      <div className={ismobile ? "rotate-value" : "font-size-row text-opt-row"}>
        {!ismobile && <span className="opt-label">Text Size</span>}
        {/* {ismobile && (
          <div
            className="roted-minus"
            onClick={() => {
              enableInputRef.current = true;
              setTextLayer({
                ...textLayer,
              });
            }}
          >
            <img
              className="textsizebtn"
              alt="minus"
              src="https://app.imprintmaker.com/assets/custom_design/mobile-icon/rotate-minus.svg"
            />
          </div>
        )} */}
        <div className="font-size">
          <input
            type="number"
            className="form-control arttextsize"
            min={0}
            style={
              ismobile
                ? { backgroundColor: "transparent", zIndex: 0 }
                : {
                    width: 90,
                    whiteSpace: "nowrap",
                    backgroundColor: "transparent",
                    zIndex: 0,
                  }
            }
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        {/* {ismobile && (
          <div
            className="roted-right"
            onClick={() => {
              enableInputRef.current = true;
              setTextLayer({
                ...textLayer,
              });
            }}
          >
            <img
              className="textsizebtn"
              alt="plus"
              src="https://app.imprintmaker.com/assets/custom_design/mobile-icon/rotate-plus.svg"
            />
          </div>
        )} */}
      </div>
      {ismobile && (
        <button
          type="button"
          className="orange-btn close_artextsize"
          onClick={() => {
            setIsDefaultOption(true);
          }}
        >
          Done
        </button>
      )}
    </>
  );
};

export default TextSizeContent;
