import React, { createContext, useEffect, useRef } from "react";

export const InputContext = createContext();

const FormInput = ({
  input_errors = {},
  errors = {},
  name,
  values,
  label,
  children,
  handleChange,
  type,
  hint,
  placeholder,
  isTextArea = false,
  isReadOnly = false,
  max = null,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (input_errors[name] || (errors && errors[name])) {
      inputRef.current.focus();
    }
  }, [input_errors, errors, name]);

  return (
    <div
      className={
        input_errors[name] || (errors && errors[name]) ? "invalid" : ""
      }
    >
      {label && <label htmlFor={name}>{label}</label>}
      {children ? (
        <InputContext.Provider
          value={{
            inputRef: inputRef,
            inputName: name,
          }}
        >
          {children}
        </InputContext.Provider>
      ) : (
        <>
          {isTextArea ? (
            <>
              <textarea
                ref={inputRef}
                onChange={(e) =>
                  max
                    ? e.target.value.length <= max
                      ? handleChange(e)
                      : null
                    : handleChange(e)
                }
                value={values[name]}
                name={name}
                type={type}
                placeholder={placeholder}
              />
              {max && (
                <p style={{ fontSize: "sm", textAlign: "right" }}>
                  {values[name] ? values[name].length : 0}/{max}
                </p>
              )}
            </>
          ) : (
            <input
              ref={inputRef}
              onChange={handleChange}
              value={values[name]}
              name={name}
              type={type}
              placeholder={placeholder}
              readOnly={isReadOnly}
            />
          )}
        </>
      )}
      {input_errors[name] || errors[name] ? (
        <div className="error">{input_errors[name] || errors[name]}</div>
      ) : null}
      {hint && <p>{hint}</p>}
    </div>
  );
};

export default FormInput;
