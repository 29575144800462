import * as pdfjs from "pdfjs-dist";
import React, { useEffect, useState } from "react";
import { MdCloudUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import WithLoader from "../../components/WithLoader";
import { applySolidBackgroundAbovePNG } from "../../lib/applySolidBackgroundAbovePNG";
import { fetchConvertImageArtData } from "../../lib/slice/createTextToImageSlice";
import {
  setCurrentDesignsView,
  setSelectedLayer,
} from "../../lib/slice/designerSlice";
import { fetchUpload } from "../../lib/slice/uploadSlice";
import FormInput, { InputContext } from "./FormInput";

const InputDesign = ({ dRef, setLoader }) => {
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();

  const {
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    view,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
  } = useSelector((state) => state.designer);
  const shop_url = useSelector((state) => state.dashboard.data?.shop_url);
  const customerid = localStorage.getItem("customer");
  const data = useSelector((state) => state.art.data);
  const { loading } = useSelector((state) => state.upload);
  const dashboard = useSelector(
    (state) => state.dashboard?.data
  );
  const pid = useSelector((state) => state.dashboard.data?.productid);

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];
  let print_type = null;

  if (view === "front" && data?.front_parent_data?.length > 0) {
    print_type = data.front_parent_data[0].id;
  } else if (view === "back" && data?.back_parent_data?.length > 0) {
    print_type = data.back_parent_data[0].id;
  } else if (view === "left" && data?.left_parent_data?.length > 0) {
    print_type = data.left_parent_data[0].id;
  } else if (view === "right" && data?.right_parent_data?.length > 0) {
    print_type = data.right_parent_data[0].id;
  } else if (view === "neck" && data?.neck_parent_data?.length > 0) {
    print_type = data.neck_parent_data[0].id;
  }

  const resetInputValue = () => {
    if (dRef.current) {
      dRef.current.value = "";
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  useEffect(() => {
    setLoader(loading);
    // eslint-disable-next-line
  }, [loading]);

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    let text = tmp.textContent || tmp.innerText || "";

    text = text.replace(/\s+/g, " ").trim();

    return text;
  };

  const handleCommonFileLoad = (file, asset, width, height) => {
    const i = new window.Image();
    i.src = asset;

    i.onload = () => {
      const formData = new FormData();
      formData.append("shop_url", shop_url);
      formData.append("image_width", i.width);
      formData.append("image_height", i.height);
      formData.append("print_type", print_type);
      formData.append("customerid", customerid);
      formData.append("print_method", view);
      formData.append("file", asset);
      dispatch(fetchUpload(formData)).then((response) => {
        if (response?.payload?.type === "success") {
          const image = new window.Image();
          image.src = asset;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);

            const imageData = context.getImageData(
              0,
              0,
              image.width,
              image.height
            );
            const data = imageData.data;
            let occupiedPixelCount = 0;

            for (let i = 0; i < data.length; i += 4) {
              if (data[i + 3] !== 0) {
                occupiedPixelCount++;
              }
            }
            const totalPixels = image.width * image.height;
            const occupiedPixelsPercentage =
              (occupiedPixelCount / totalPixels) * 100;
            const dataLayer = {
              type: "IMAGE",
              name: file.name,
              asset,
              base64: asset,
              file,
              config: {
                x:
                  ((view === "front"
                    ? frontClientCanvas?.canvasWidth
                    : view === "back"
                    ? backClientCanvas?.canvasWidth
                    : view === "left"
                    ? leftClientCanvas?.canvasWidth
                    : view === "right"
                    ? rightClientCanvas?.canvasWidth
                    : view === "neck"
                    ? neckClientCanvas?.canvasWidth
                    : 0) -
                    width) /
                  2,
                y: 0,
                id: response?.payload?.art_id,
                width,
                height,
                occupiedPixelsPercentage,
              },
            };

            dispatch(setCurrentDesignsView([...currentDesignsView, dataLayer]));
            dispatch(setSelectedLayer(dataLayer));
          };
        } else {
          const textContent = stripHtmlTags(response?.payload?.message);
          alert(textContent);
        }
      });
    };
  };

  const calculateTrimmedBoundingBox = (imageData) => {
    const { data, width, height } = imageData;
    let minX = width;
    let minY = height;
    let maxX = 0;
    let maxY = 0;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const alpha = data[(y * width + x) * 4 + 3];
        if (alpha > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }

    return {
      minX,
      minY,
      maxX,
      maxY,
      width: maxX - minX + 1,
      height: maxY - minY + 1,
    };
  };

  const handleSVGConversion = async (pdfData, file) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    pdfjs.getDocument({ data: pdfData }).promise.then((pdf) => {
      pdf.getPage(1).then((page) => {
        const scale = 1;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        page
          .render({
            canvasContext: context,
            background: "rgba(0,0,0,0)",
            viewport,
          })
          .promise.then(() => {
            const imageData = context.getImageData(
              0,
              0,
              canvas.width,
              canvas.height
            );

            const trimmedBoundingBox = calculateTrimmedBoundingBox(imageData);

            const trimmedCanvas = document.createElement("canvas");
            const trimmedContext = trimmedCanvas.getContext("2d");
            trimmedCanvas.width = trimmedBoundingBox.width;
            trimmedCanvas.height = trimmedBoundingBox.height;

            trimmedContext.putImageData(
              imageData,
              -trimmedBoundingBox.minX,
              -trimmedBoundingBox.minY
            );

            const svgString = trimmedCanvas.toDataURL("image/svg+xml");

            const width = calculateImageDimension(
              "width",
              trimmedCanvas.width,
              trimmedCanvas.height
            );
            const height = calculateImageDimension(
              "height",
              trimmedCanvas.width,
              trimmedCanvas.height
            );

            if (dashboard?.productType === "Tumblers") {
              const formData = new FormData();
              formData.append("image_url", svgString);
              formData.append("upload_type", "image");
              formData.append("cat_name", "Tumblers");
              formData.append("pid", pid);

              dispatch(fetchConvertImageArtData(formData)).then(async (res) => {
                applySolidBackgroundAbovePNG(
                  res?.payload?.tumlerImgdata,
                  svgString
                )
                  .then((resultBase64) => {
                    handleCommonFileLoad(file, resultBase64, width, height);
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
              });
            } else {
              handleCommonFileLoad(file, svgString, width, height);
            }
          });
      });
    });
  };

  const handleImageLoad = async (file) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const maxWidth =
          view === "front"
            ? frontClientCanvas?.canvasWidth
            : view === "back"
            ? backClientCanvas?.canvasWidth
            : view === "left"
            ? leftClientCanvas?.canvasWidth
            : view === "right"
            ? rightClientCanvas?.canvasWidth
            : view === "neck"
            ? neckClientCanvas?.canvasWidth
            : 0;
        const maxHeight =
          view === "front"
            ? frontClientCanvas?.canvasHeight
            : view === "back"
            ? backClientCanvas?.canvasHeight
            : view === "left"
            ? leftClientCanvas?.canvasHeight
            : view === "right"
            ? rightClientCanvas?.canvasHeight
            : view === "neck"
            ? neckClientCanvas?.canvasHeight
            : 0;

        let width = image.width;
        let height = image.height;

        const widthScale = maxWidth / width;
        const heightScale = maxHeight / height;

        const scale = Math.min(widthScale, heightScale);

        width *= scale;
        height *= scale;

        if (dashboard?.productType === "Tumblers") {
          const formData = new FormData();
          formData.append("image_url", reader.result);
          formData.append("upload_type", "image");
          formData.append("cat_name", "Tumblers");
          formData.append("pid", pid);

          dispatch(fetchConvertImageArtData(formData)).then(async (res) => {
            applySolidBackgroundAbovePNG(
              res?.payload?.tumlerImgdata,
              reader.result
            )
              .then((resultBase64) => {
                handleCommonFileLoad(file, resultBase64, width, height);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
        } else {
          handleCommonFileLoad(file, reader.result, width, height);
        }
      };
    });

    await new Promise(() => reader.readAsDataURL(file));
  };

  const calculateImageDimension = (type, originalWidth, originalHeight) => {
    const maxWidth =
      view === "front"
        ? frontClientCanvas?.canvasWidth
        : view === "back"
        ? backClientCanvas?.canvasWidth
        : view === "left"
        ? leftClientCanvas?.canvasWidth
        : view === "right"
        ? rightClientCanvas?.canvasWidth
        : view === "neck"
        ? neckClientCanvas?.canvasWidth
        : 0;
    const maxHeight =
      view === "front"
        ? frontClientCanvas?.canvasHeight
        : view === "back"
        ? backClientCanvas?.canvasHeight
        : view === "left"
        ? leftClientCanvas?.canvasHeight
        : view === "right"
        ? rightClientCanvas?.canvasHeight
        : view === "neck"
        ? neckClientCanvas?.canvasHeight
        : 0;

    if (originalWidth > maxWidth || originalHeight > maxHeight) {
      const widthRatio = maxWidth / originalWidth;
      const heightRatio = maxHeight / originalHeight;

      if (widthRatio < heightRatio) {
        if (type === "width") {
          return maxWidth;
        } else {
          return originalHeight * widthRatio;
        }
      } else {
        if (type === "width") {
          return originalWidth * heightRatio;
        } else {
          return maxHeight;
        }
      }
    }
    return originalWidth;
  };

  const changeDesign = async (e) => {
    if (!e.target.files.length > 0) {
      resetInputValue();
      return;
    }

    const maxFileSizeInMB = 20;
    const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;

    if (e.target.files[0].size >= maxFileSizeInBytes) {
      resetInputValue();
      alert(`Image size must be less than ${maxFileSizeInMB} MB`);
      return;
    }

    const file = e.target.files[0];
    if (
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "application/postscript"
    ) {
      const pdfData = await readFileAsync(e.target.files[0]);
      await handleSVGConversion(pdfData, file);
    } else {
      handleImageLoad(file);
    }
  };
  const handleFile = async (file) => {
    const acceptedExtensions = [".jpg", ".jpeg", ".png", ".svg", ".pdf", ".ai"];
    const fileExtension = file?.name?.split(".")?.pop()?.toLowerCase();

    if (!acceptedExtensions.includes("." + fileExtension)) {
      resetInputValue();
      alert(
        "Invalid file format. Please upload a JPG, PNG, JPEG, SVG, PDF, or AI file."
      );
      return;
    }

    if (fileExtension === "pdf" || fileExtension === "ai") {
      const pdfData = await readFileAsync(file);
      await handleSVGConversion(pdfData, file);
    } else {
      await handleImageLoad(file);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    await handleFile(file);
  };

  return (
    <Wrapper
      onDragOver={handleDragOver}
      onDragLeave={() => setIsDragging(false)}
      onDrop={handleDrop}
      $isDragging={isDragging}
    >
      <FormInput
        name={
          view === "front"
            ? "front_design_file"
            : view === "back"
            ? "back_design_file"
            : view === "left"
            ? "left_design_file"
            : view === "right"
            ? "right_design_file"
            : view === "neck"
            ? "neck_design_file"
            : ""
        }
      >
        <InputContext.Consumer>
          {({ inputRef }) => (
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                className="image-upload upload-area"
                id="uploadfile"
                style={{
                  position: "relative",
                  padding: "15px 20px",
                  margin: 0,
                  textAlign: "center",
                }}
              >
                <input
                  type="file"
                  accept=".jpg,.png,.jpeg,.svg,.pdf,.ai"
                  hidden
                  id={
                    view === "front"
                      ? "front_design_file"
                      : view === "back"
                      ? "back_design_file"
                      : view === "left"
                      ? "left_design_file"
                      : view === "right"
                      ? "right_design_file"
                      : view === "neck"
                      ? "neck_design_file"
                      : ""
                  }
                  ref={dRef}
                  onChange={(e) => changeDesign(e)}
                />
                <MdCloudUpload style={{ fontSize: 103, color: "#E33F3A" }} />
                <p>
                  Drag &amp; Drop or <span>Browse</span> Your Computer
                </p>
              </div>
              <button
                type="button"
                ref={inputRef}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: 0,
                  top: 0,
                  opacity: 0,
                  padding: "0",
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                <label
                  htmlFor={
                    view === "front"
                      ? "front_design_file"
                      : view === "back"
                      ? "back_design_file"
                      : view === "left"
                      ? "left_design_file"
                      : view === "right"
                      ? "right_design_file"
                      : view === "neck"
                      ? "neck_design_file"
                      : ""
                  }
                  className="custom-label"
                  style={{
                    display: "flex",
                    padding: "0 1rem",
                    cursor: "pointer",
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    fontWeight: "normal",
                  }}
                ></label>
              </button>
            </div>
          )}
        </InputContext.Consumer>
      </FormInput>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 25px 20px;
  border: 3px dashed
    ${(props) => (props.$isDragging ? "#449d44" : "transparent")};
  border-radius: 10px;
`;

export default WithLoader(InputDesign);
