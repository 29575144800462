import React, { useState } from "react";
import {
  MdFindReplace,
  MdOutlineZoomIn,
  MdOutlineZoomOut,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { setView, setZoom } from "../../lib/slice/designerSlice";
import SizeOption from "./SizeOption";

const MappingOption = () => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { view } = useSelector((state) => state.designer);
  const { colorArtData } = useSelector((state) => state.color);
  const frontRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintdetails
  );
  const backRadioOptions = useSelector(
    (state) => state.dashboard.data?.get_back_parentdata
  );
  const leftRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintLeftSleev
  );
  const rightRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintRightSleev
  );
  const neckRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintNeck
  );

  const handleOptionClick = (selectedView) => {
    dispatch(setView(selectedView));
    if (location.pathname === "/edit-image") {
      navigate("/upload");
    } else if (location.pathname === "/edit-text") {
      navigate("/add-text");
    }
  };

  const options = [];
  if (frontRadioOptions?.length && colorArtData[0]?.frontimage) {
    options.push({
      view: "front",
      label: "Front",
    });
  }
  if (backRadioOptions?.length && colorArtData[0]?.backimage) {
    options.push({
      view: "back",
      label: "Back",
    });
  }
  if (leftRadioOptions?.length && colorArtData[0]?.leftimage) {
    options.push({
      view: "left",
      label: "Left",
    });
  }
  if (rightRadioOptions?.length && colorArtData[0]?.rightimage) {
    options.push({
      view: "right",
      label: "Right",
    });
  }
  if (neckRadioOptions?.length && colorArtData[0]?.neckimage) {
    options.push({
      view: "neck",
      label: "Neck",
    });
  }

  return (
    <Wrapper>
      {ismobile ? (
        <MobileThumbnails
          options={options}
          handleOptionClick={handleOptionClick}
          view={view}
        />
      ) : (
        <DesktopOptions />
      )}
    </Wrapper>
  );
};

const MobileThumbnails = ({ options, handleOptionClick, view }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  return (
    <div className="dropup productInfo-sides-wrap open">
      {options.map(
        ({ view: optionView, label }, index) =>
          optionView === view && (
            <Thumbnail
              key={index}
              view={optionView}
              label={label}
              active={false}
              onClick={() => setIsOpenDropdown(!isOpenDropdown)}
            />
          )
      )}

      {isOpenDropdown && (
        <div className="dropdown-menu">
          <div className="productInfo-sides">
            <div className="thumbView">
              <div className="thumbView-row">
                {options.map(({ view: optionView, label }, index) => (
                  <Thumbnail
                    key={index}
                    view={optionView}
                    label={label}
                    active={optionView === view}
                    onClick={() => {
                      handleOptionClick(optionView);
                      setIsOpenDropdown(false);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DesktopOptions = () => {
  const dispatch = useDispatch();

  const { zoom } = useSelector((state) => state.designer);

  return (
    <div className="options-container">
      <SizeOption />
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <div
          className="zoom-option right-box"
          onClick={() => dispatch(setZoom(1.0))}
        >
          <div className={`zoom right-opt-box ${zoom === 1 ? "disabled" : ""}`}>
            <MdFindReplace />
          </div>

          <span className={zoom === 1 ? "disabled" : ""}>Reset</span>
        </div>

        <div
          className="zoom-option right-box"
          onClick={() => {
            zoom <= 1.5 && dispatch(setZoom(zoom + 0.1));
          }}
        >
          <div
            className={`zoom right-opt-box ${zoom >= 1.5 ? "disabled" : ""}`}
          >
            <MdOutlineZoomIn />
          </div>
          <span className={zoom >= 1.5 ? "disabled" : ""}>
            Zoom
            <br /> In
          </span>
        </div>

        <div
          className="zoom-option right-box"
          onClick={() => zoom > 1.0 && dispatch(setZoom(zoom - 0.1))}
        >
          <div
            className={`zoom right-opt-box ${zoom <= 1.0 ? "disabled" : ""}`}
          >
            {/* <img
              className="plus-icon"
              src="https://app.imprintmaker.com/assets/custom_design/images/Zoom_out.svg"
              alt="Zoom_out"
            /> */}
            <MdOutlineZoomOut />
          </div>
          <span className={zoom <= 1.0 ? "disabled" : ""}>
            Zoom
            <br /> Out
          </span>
        </div>
      </div>
    </div>
  );
};

const Thumbnail = ({ view, label, active, onClick }) => {
  const colorArtData = useSelector((state) => state.color.colorArtData);

  return (
    <div
      className={`thumbView-col ${
        active ? "activeblock activefront" : "activeblock activeback"
      }`}
      onClick={onClick}
    >
      <div className={`imageWrap changeimageurl front_site_border`}>
        {active && <span className={`dot front_dot`} />}
        <figure className="imageWrap-box">
          <img
            src={
              view === "front"
                ? colorArtData[0]?.frontimage
                : view === "back"
                ? colorArtData[0]?.backimage
                : view === "left"
                ? colorArtData[0]?.leftimage
                : view === "right"
                ? colorArtData[0]?.rightimage
                : view === "neck"
                ? colorArtData[0]?.neckimage
                : null
            }
            className={`${view}_image`}
            loading="lazy"
            alt=""
          />
        </figure>
        <div className="imageWrap-title">{label}</div>
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  .zoom {
    font-size: 24px;
  }
  .dropup {
    position: absolute;
    right: 20px;
    top: 100px;
    z-index: 1;
    width: 100%;
    cursor: pointer;
  }

  .dropdown-menu {
    width: 100%;
  }

  .zoom-option {
    margin-right: 15px;
  }

  .right-box {
    .disabled {
      opacity: 0.4;
      cursor: default;
    }
    &:hover .disabled {
      color: #000;
    }
    .right-opt-box {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      font-size: 12px;
      line-height: normal;
    }
  }

  .plus-icon {
    max-width: 20px;
    height: auto;
  }

  .imageWrap {
    position: relative;
  }

  .dot {
    position: absolute;
    right: 4px;
    top: 0;
    background-color: green;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  .imageWrap-title {
    text-align: center;
  }
`;

export default MappingOption;
