import React, { useEffect, useState } from "react";
import storing_animation from "../assets/images/storing_animation.gif";

const WithLoader = (WrappedComponent) => {
  return function WithLoader(props) {
    const [loader, setLoader] = useState(false);
    const [loaderMsg, setLoaderMsg] = useState("");

    useEffect(() => {
      if (loader) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }

      return () => {
        document.body.classList.remove("no-scroll");
      };
    }, [loader]);

    return (
      <>
        {loader && (
          <>
            <div id="saving_container">
              <div
                id="saving"
                style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 100000,
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  {loaderMsg && (
                    <div style={{ marginBottom: 24, fontSize: 14 }}>
                      <p style={{ marginBottom: 4 }}>Please wait!</p>
                      <p>{loaderMsg}</p>
                    </div>
                  )}
                    {/* <div class="progress progress-striped active">
                      <div class="progress-bar progress-bar-danger" id="saving_animation" style={{ width: 50 }}></div>
                    </div> */}
                  <img
                    id="saving_animation"
                    src={storing_animation}
                    alt="saving"
                    style={{
                      backgroundColor: "#fff",
                      padding: 10,
                      borderRadius: 10,
                      width: 100,
                      height: 100,
                      opacity: 1,
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <WrappedComponent
          {...props}
          setLoader={setLoader}
          setLoaderMsg={setLoaderMsg}
        />
      </>
    );
  };
};

export default WithLoader;
