import React, { useEffect, useState } from "react";
import {
  FaAlignCenter,
  FaAlignLeft,
  FaAlignRight,
  FaChevronLeft,
  FaXmark,
} from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Default from "../components/Sidebar/Default";
import { useFormula } from "../lib/hooks/useFormula";
import {
  setCurrentDesignsView,
  setSelectedLayer,
} from "../lib/slice/designerSlice";

const EditImage = ({ isDefaultOption, setIsDefaultOption }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedLayer,
    view,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
  } = useSelector((state) => state.designer);

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];

  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();

  const getViewFormula = (view) => {
    switch (view) {
      case "front":
        return NewImageFormulaFront;
      case "back":
        return NewImageFormulaBack;
      case "left":
        return NewImageFormulaLeft;
      case "right":
        return NewImageFormulaRight;
      case "neck":
        return NewImageFormulaNeck;
      default:
        return 1;
    }
  };

  const selectedFormula = getViewFormula(view);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [inputWidth, setInputWidth] = useState(
    (width / selectedFormula).toFixed(2)
  );
  const [inputHeight, setInputHeight] = useState(
    (height / selectedFormula).toFixed(2)
  );
  const [autoCenter, setAutoCenter] = useState(false);

  useEffect(() => {
    const handlePageRefresh = () => {
      dispatch(setSelectedLayer(null));
    };

    window.addEventListener("beforeunload", handlePageRefresh);

    return () => {
      window.removeEventListener("beforeunload", handlePageRefresh);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateDimensions(
      selectedLayer?.config?.width,
      selectedLayer?.config?.height
    );
    // eslint-disable-next-line
  }, [selectedLayer?.config?.width, selectedLayer?.config?.height]);

  const roundToNearestQuarter = (number) => {
    return (Math.round(number * 10) / 10).toFixed(2);
  };

  useEffect(() => {
    if (width && height) {
      onChangeAlignment({ width, height });
    }
    // eslint-disable-next-line
  }, [width, height]);

  useEffect(() => {
    setInputWidth(roundToNearestQuarter(width / selectedFormula));
  }, [width, selectedFormula]);

  useEffect(() => {
    setInputHeight(roundToNearestQuarter(height / selectedFormula));
  }, [height, selectedFormula]);

  useEffect(() => {
    if (selectedLayer?.type !== "IMAGE") {
      navigate("/upload");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (autoCenter) {
      setAutoCenter(false);
    }

    // eslint-disable-next-line
  }, [width, height]);

  const updateDimensions = (newWidth, newHeight) => {
    const aspectRatio =
      selectedLayer?.config?.width / selectedLayer?.config?.height;
    if (newWidth) {
      if (
        newWidth >
        (view === "front"
          ? frontClientCanvas?.canvasWidth
          : view === "back"
          ? backClientCanvas?.canvasWidth
          : view === "left"
          ? leftClientCanvas?.canvasWidth
          : view === "right"
          ? rightClientCanvas?.canvasWidth
          : view === "neck"
          ? neckClientCanvas?.canvasWidth
          : 0)
      ) {
        newWidth =
          view === "front"
            ? frontClientCanvas?.canvasWidth
            : view === "back"
            ? backClientCanvas?.canvasWidth
            : view === "left"
            ? leftClientCanvas?.canvasWidth
            : view === "right"
            ? rightClientCanvas?.canvasWidth
            : view === "neck"
            ? neckClientCanvas?.canvasWidth
            : 0;
        newHeight =
          (view === "front"
            ? frontClientCanvas?.canvasWidth
            : view === "back"
            ? backClientCanvas?.canvasWidth
            : view === "left"
            ? leftClientCanvas?.canvasWidth
            : view === "right"
            ? rightClientCanvas?.canvasWidth
            : view === "neck"
            ? neckClientCanvas?.canvasWidth
            : 0) / aspectRatio;
      }

      setWidth(newWidth);
      setHeight(newWidth / aspectRatio);
      autoCenter && designAlign("HorizontalCenter");
    } else if (newHeight) {
      if (
        newHeight >
        (view === "front"
          ? frontClientCanvas?.canvasHeight
          : view === "back"
          ? backClientCanvas?.canvasHeight
          : view === "left"
          ? leftClientCanvas?.canvasHeight
          : view === "right"
          ? rightClientCanvas?.canvasHeight
          : view === "neck"
          ? neckClientCanvas?.canvasHeight
          : 0)
      ) {
        newHeight =
          view === "front"
            ? frontClientCanvas?.canvasHeight
            : view === "back"
            ? backClientCanvas?.canvasHeight
            : view === "left"
            ? leftClientCanvas?.canvasHeight
            : view === "right"
            ? rightClientCanvas?.canvasHeight
            : view === "neck"
            ? neckClientCanvas?.canvasHeight
            : 0;
        newWidth = newHeight * aspectRatio;
      }

      setHeight(newHeight);
      setWidth(newHeight * aspectRatio);
      autoCenter && designAlign("HorizontalCenter");
    }
  };

  const onChangeAlignment = (config) => {
    const targetLayerIndex = currentDesignsView.findIndex(
      (d) => d?.config?.id === selectedLayer?.config.id
    );
    if (targetLayerIndex === -1) return;
    const tempLayers = [...currentDesignsView];
    tempLayers[targetLayerIndex] = {
      ...selectedLayer,
      config: { ...selectedLayer?.config, ...config },
    };

    dispatch(setCurrentDesignsView(tempLayers));
  };

  const designAlign = (ALIGN) => {
    if (!selectedLayer) return;

    switch (ALIGN) {
      case "HorizontalCenter":
        return onChangeAlignment({
          x:
            ((view === "front"
              ? frontClientCanvas?.canvasWidth
              : view === "back"
              ? backClientCanvas?.canvasWidth
              : view === "left"
              ? leftClientCanvas?.canvasWidth
              : view === "right"
              ? rightClientCanvas?.canvasWidth
              : view === "neck"
              ? neckClientCanvas?.canvasWidth
              : 0) -
              selectedLayer?.config?.width) /
            2,
        });
      case "HorizontalLeft":
        return onChangeAlignment({ x: 0 });
      case "HorizontalRight":
        return onChangeAlignment({
          x:
            (view === "front"
              ? frontClientCanvas?.canvasWidth
              : view === "back"
              ? backClientCanvas?.canvasWidth
              : view === "left"
              ? leftClientCanvas?.canvasWidth
              : view === "right"
              ? rightClientCanvas?.canvasWidth
              : view === "neck"
              ? neckClientCanvas?.canvasWidth
              : 0) - selectedLayer?.config?.width,
        });
      default:
        break;
    }
  };

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <Wrapper
          className={
            ismobile
              ? "mbl-edit-image-option mbl-option bottom-option"
              : "edit-upload-image option-box"
          }
          style={{
            position: ismobile ? "fixed" : "relative",
            display: "inline-block",
            padding: 0,
          }}
        >
          <div className="option-heading">
            <div
              className="back-btn"
              onClick={() => {
                navigate("/upload");
                ismobile && setIsDefaultOption(true);
              }}
            >
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Edit Image</h3>
            <div
              className="close-btn option-close-btn"
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark />
            </div>
          </div>

          <div className="image-formate uploadimagebox">
            <p>
              Uploaded Image Name : &nbsp;
              <span id="uploadimagename">{selectedLayer?.name}</span>
            </p>
          </div>
          <div className="upload-size textalignmentblock">
            <label className="uploadsizeblocklabel">
              <span className="imagealignmenttitle">Image Alignment</span>
            </label>
            <div className="input-group">
              <div className="text-align-opt">
                <ul>
                  <li
                    onClick={() => designAlign("HorizontalLeft")}
                    className="center"
                  >
                    <FaAlignLeft />
                  </li>
                  <li
                    onClick={() => designAlign("HorizontalCenter")}
                    className="center"
                  >
                    <FaAlignCenter />
                  </li>
                  <li
                    onClick={() => designAlign("HorizontalRight")}
                    className="center"
                  >
                    <FaAlignRight />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="upload-size">
            <label className="uploadsizeblocklabel">
              <div className="uploadimagesizeblock">Upload Size</div>
              <div className="uploadimagesizeblock">Width x Height</div>
            </label>
            <div className="input-group">
              <p>
                <input
                  type="number"
                  className="form-control getinchvalue"
                  id="custom_inch_width"
                  value={inputWidth}
                  style={{ backgroundColor: "transparent", zIndex: 0 }}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) <=
                      (view === "front"
                        ? Number(currentFrontPrint?.front_area_width_float)
                        : view === "back"
                        ? Number(currentBackPrint?.back_area_width_float)
                        : view === "left"
                        ? Number(currentLeftPrint?.left_area_width_float)
                        : view === "right"
                        ? Number(currentRightPrint?.right_area_width_float)
                        : view === "neck"
                        ? Number(currentNeckPrint?.neck_area_width_float)
                        : 0)
                    ) {
                      setInputWidth(Number(e.target.value));
                      setTimeout(() => {
                        setAutoCenter(true);
                        const newWidth = (
                          Number(e.target.value) * selectedFormula
                        ).toFixed(2);
                        updateDimensions(newWidth, null);
                      }, 2000);
                    }
                  }}
                />
                in
              </p>
              <span style={{ marginTop: 0 }}>x</span>
              <p>
                <input
                  type="number"
                  className="form-control getinchvalueHeight"
                  style={{ backgroundColor: "transparent", zIndex: 0 }}
                  id="custom_inch_height"
                  value={inputHeight}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) <=
                      (view === "front"
                        ? Number(currentFrontPrint?.front_area_height_float)
                        : view === "back"
                        ? Number(currentBackPrint?.back_area_height_float)
                        : view === "left"
                        ? Number(currentLeftPrint?.left_area_height_float)
                        : view === "right"
                        ? Number(currentRightPrint?.right_area_height_float)
                        : view === "neck"
                        ? Number(currentNeckPrint?.neck_area_height_float)
                        : 0)
                    ) {
                      setInputHeight(Number(e.target.value));
                      setTimeout(() => {
                        setAutoCenter(true);
                        const newHeight = (
                          Number(e.target.value) * selectedFormula
                        ).toFixed(2);
                        updateDimensions(null, newHeight);
                      }, 2000);
                    }
                  }}
                />
                in
              </p>
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #272727;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    svg {
      color: #ffffff;
      font-size: 24px;
    }
  }

  .center {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 39px;
    width: 44px;
  }

  .image-formate {
    margin-top: 20px;
  }

  #uploadimagename {
    font-weight: 300;
  }

  .form-control:focus {
    border-color: rgba(227, 63, 58, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(227, 63, 58, 0.6);
  }
`;

export default EditImage;
