import React, { Fragment } from "react";

import { Transformer } from "react-konva";
import { useSelector } from "react-redux";

const DesignWrapper = ({
  isSelected,
  trRef,
  children,
  enabledAnchors = ["top-left", "top-right", "bottom-left", "bottom-right"],
  isHovered,
  isDownload,
}) => {
  const {
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    selectedLayer,
    view,
  } = useSelector((state) => state.designer);

  return (
    <Fragment>
      {children}

      {isSelected && (
        <Transformer
          keepRatio={true}
          rotateEnabled={false}
          enabledAnchors={enabledAnchors}
          anchorCornerRadius={100}
          anchorSize={40}
          anchorStroke="transparent"
          anchorFill="transparent"
          borderStroke={isHovered && !isDownload ? "#8dcc19" : "transparent"}
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }

            if (
              newBox.width + selectedLayer?.config?.x >
                (view === "front"
                  ? frontClientCanvas?.canvasWidth
                  : view === "back"
                  ? backClientCanvas?.canvasWidth
                  : view === "left"
                  ? leftClientCanvas?.canvasWidth
                  : view === "right"
                  ? rightClientCanvas?.canvasWidth
                  : view === "neck"
                  ? neckClientCanvas?.canvasWidth
                  : 0) ||
              newBox.height + selectedLayer?.config?.y >
                (view === "front"
                  ? frontClientCanvas?.canvasHeightFull
                  : view === "back"
                  ? backClientCanvas?.canvasHeightFull
                  : view === "left"
                  ? leftClientCanvas?.canvasHeightFull
                  : view === "right"
                  ? rightClientCanvas?.canvasHeightFull
                  : view === "neck"
                  ? neckClientCanvas?.canvasHeightFull
                  : 0)
            ) {
              return oldBox;
            }

            return newBox;
          }}
        />
      )}
    </Fragment>
  );
};

export default DesignWrapper;
