import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchGetSaveNoteData = createAsyncThunk(
  "saveNoteData/fetchGetSaveNoteData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getsavenotes`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSaveNoteData = createAsyncThunk(
  "saveNoteData/fetchSaveNoteData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/savenotes`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
  successMessage: null,
};

const saveNoteSlice = createSlice({
  name: "saveNoteData",
  initialState,
  reducers: {
    clearSaveNoteMsg: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetSaveNoteData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGetSaveNoteData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchGetSaveNoteData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSaveNoteData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSaveNoteData.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = "Note Saved";
      })
      .addCase(fetchSaveNoteData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearSaveNoteMsg } = saveNoteSlice.actions;

export default saveNoteSlice.reducer;
