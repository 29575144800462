import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchArtData } from "../../lib/slice/artSlice";
import {
  setBackCurrentPrint,
  setCurrentDesignsView,
  setFrontCurrentPrint,
  setLeftCurrentPrint,
  setNeckCurrentPrint,
  setRightCurrentPrint,
} from "../../lib/slice/designerSlice";

const SizeOption = () => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();

  const {
    view,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    selectedLayer,
  } = useSelector((state) => state.designer);
  const frontRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintdetails
  );
  const backRadioOptions = useSelector(
    (state) => state.dashboard.data?.get_back_parentdata
  );
  const leftRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintLeftSleev
  );
  const rightRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintRightSleev
  );
  const neckRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintNeck
  );

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];

  useEffect(() => {
    const fetchData = (currentPrintId) => {
      if (currentPrintId) {
        const formData = new FormData();
        formData.append("type", currentPrintId);
        formData.append("tshirttype", view);
        dispatch(fetchArtData(formData));
      }
    };

    if (view === "front") {
      fetchData(currentFrontPrint?.id);
    } else if (view === "back") {
      fetchData(currentBackPrint?.id);
    } else if (view === "left") {
      fetchData(currentLeftPrint?.id);
    } else if (view === "right") {
      fetchData(currentRightPrint?.id);
    } else if (view === "neck") {
      fetchData(currentNeckPrint?.id);
    }

    // eslint-disable-next-line
  }, [
    dispatch,
    view,
    currentFrontPrint?.id,
    currentBackPrint?.id,
    currentLeftPrint?.id,
    currentRightPrint?.id,
    currentNeckPrint?.id,
  ]);

  useEffect(() => {
    const shouldSetCurrentPrint =
      (view === "front" && frontRadioOptions) ||
      (view === "back" && backRadioOptions) ||
      (view === "left" && leftRadioOptions) ||
      (view === "right" && rightRadioOptions) ||
      (view === "neck" && neckRadioOptions);

    if (shouldSetCurrentPrint) {
      const currentPrintState =
        view === "front"
          ? currentFrontPrint
          : view === "back"
          ? currentBackPrint
          : view === "left"
          ? currentLeftPrint
          : view === "right"
          ? currentRightPrint
          : view === "neck"
          ? currentNeckPrint
          : {};

      if (!Object.keys(currentPrintState || {}).length) {
        const setPrintAction =
          view === "front"
            ? setFrontCurrentPrint
            : view === "back"
            ? setBackCurrentPrint
            : view === "left"
            ? setLeftCurrentPrint
            : view === "right"
            ? setRightCurrentPrint
            : view === "neck"
            ? setNeckCurrentPrint
            : null;

        dispatch(setPrintAction(shouldSetCurrentPrint[0]));
      }
    }
  }, [
    dispatch,
    frontRadioOptions,
    backRadioOptions,
    leftRadioOptions,
    rightRadioOptions,
    neckRadioOptions,
    view,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
  ]);

  useEffect(() => {
    const targetLayerIndex = currentDesignsView.findIndex(
      (d) => d?.config?.id === selectedLayer?.config.id
    );
    if (targetLayerIndex === -1) return;

    const tempLayers = [...currentDesignsView];

    const imageActualWidth = selectedLayer?.config?.width;
    const imageActualHeight = selectedLayer?.config?.height;

    const aspectRatio =
      selectedLayer?.config?.width / selectedLayer?.config?.height;

    const maxWidth =
      view === "front"
        ? frontClientCanvas?.canvasWidth
        : view === "back"
        ? backClientCanvas?.canvasWidth
        : view === "left"
        ? leftClientCanvas?.canvasWidth
        : view === "right"
        ? rightClientCanvas?.canvasWidth
        : view === "neck"
        ? neckClientCanvas?.canvasWidth
        : 0;
    const maxHeight =
      view === "front"
        ? frontClientCanvas?.canvasHeight
        : view === "back"
        ? backClientCanvas?.canvasHeight
        : view === "left"
        ? leftClientCanvas?.canvasHeight
        : view === "right"
        ? rightClientCanvas?.canvasHeight
        : view === "neck"
        ? neckClientCanvas?.canvasHeight
        : 0;

    let newWidth;
    let newHeight;

    if (imageActualWidth / maxWidth > imageActualHeight / maxHeight) {
      newWidth = maxWidth;
      newHeight = newWidth / aspectRatio;
    } else {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }

    tempLayers[targetLayerIndex] = {
      ...selectedLayer,
      config: {
        ...selectedLayer?.config,
        x:
          ((view === "front"
            ? frontClientCanvas?.canvasWidth
            : view === "back"
            ? backClientCanvas?.canvasWidth
            : view === "left"
            ? leftClientCanvas?.canvasWidth
            : view === "right"
            ? rightClientCanvas?.canvasWidth
            : view === "neck"
            ? neckClientCanvas?.canvasWidth
            : 0) -
            newWidth) /
          2,
        y: 0,
        width: newWidth,
        height: newHeight,
      },
    };

    if (selectedLayer?.type === "IMAGE") {
      dispatch(setCurrentDesignsView(tempLayers));
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    frontClientCanvas?.canvasHeight,
    backClientCanvas?.canvasHeight,
    leftClientCanvas?.canvasHeight,
    rightClientCanvas?.canvasHeight,
    neckClientCanvas?.canvasHeight,
    frontClientCanvas?.canvasWidth,
    backClientCanvas?.canvasWidth,
    leftClientCanvas?.canvasWidth,
    rightClientCanvas?.canvasWidth,
    neckClientCanvas?.canvasWidth,
  ]);

  return (
    <Wrapper className="frontsizeblock">
      {(view === "front"
        ? frontRadioOptions
        : view === "back"
        ? backRadioOptions
        : view === "left"
        ? leftRadioOptions
        : view === "right"
        ? rightRadioOptions
        : view === "neck"
        ? neckRadioOptions
        : []
      )?.map((option) => (
        <div key={option?.id} style={{ display: "flex", alignItems: "center" }}>
          <div key={option?.id} className="standardsizeradio">
            <input
              type="checkbox"
              id={option?.id}
              className="printtypes"
              style={ismobile ? {} : { width: 16, height: 16 }}
              name="printtype"
              value={option?.name}
              checked={
                (view === "front"
                  ? currentFrontPrint?.name
                  : view === "back"
                  ? currentBackPrint?.name
                  : view === "left"
                  ? currentLeftPrint?.name
                  : view === "right"
                  ? currentRightPrint?.name
                  : view === "neck"
                  ? currentNeckPrint?.name
                  : "") === option?.name
              }
              onChange={() => {
                if (view === "front") {
                  dispatch(setFrontCurrentPrint(option));
                } else if (view === "back") {
                  dispatch(setBackCurrentPrint(option));
                } else if (view === "left") {
                  dispatch(setLeftCurrentPrint(option));
                } else if (view === "right") {
                  dispatch(setRightCurrentPrint(option));
                } else if (view === "neck") {
                  dispatch(setNeckCurrentPrint(option));
                }
              }}
            />
          </div>
          <label
            className="standardsizelabelradio"
            style={ismobile ? { fontSize: 11 } : { fontSize: 12 }}
          >
             {option?.name}
          </label>
          <br />
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 10px;

  .standardsizeradio {
    margin-right: 12px;
  }

  .printtypes {
    accent-color: #e33f3a;
  }
`;

export default SizeOption;
