import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchAllCustomerArt = createAsyncThunk(
  "allCustomerArtData/fetchAllCustomerArt",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getAllCusotmerArt`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLatestUploadImage = createAsyncThunk(
  "latestUploadImageData/latestuploadimage",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/latestuploadimage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSpceficArtInformation = createAsyncThunk(
  "spceficArtInformation/fetchSpceficArtInformation",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getSpceficArtInformation`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  allCustomerArtData: null,
  latestUploadImageData: null,
  spceficArtInformationData: null,

  loading: false,
  error: null,
};

const allCustomerArtSlice = createSlice({
  name: "allCustomerArt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCustomerArt.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCustomerArt.fulfilled, (state, action) => {
        state.loading = false;
        state.allCustomerArtData = action.payload;
      })
      .addCase(fetchAllCustomerArt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSpceficArtInformation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpceficArtInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.spceficArtInformationData = action.payload;
      })
      .addCase(fetchSpceficArtInformation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchLatestUploadImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLatestUploadImage.fulfilled, (state, action) => {
        state.loading = false;
        state.latestUploadImageData = action.payload;
      })
      .addCase(fetchLatestUploadImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default allCustomerArtSlice.reducer;
