import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../assets/images/colors.svg";
import editText from "../../assets/images/edit-text.svg";
import fontFamily from "../../assets/images/font-family.svg";
import note from "../../assets/images/note.svg";
import outline from "../../assets/images/outline.svg";
import resize from "../../assets/images/resize.svg";
import rotate from "../../assets/images/rotate.svg";
import t from "../../assets/images/t.svg";
import upload from "../../assets/images/upload.svg";
import { useDebounce } from "../../lib/hooks/useDebounce";
import {
  fetchAllCustomerArt,
  fetchLatestUploadImage,
} from "../../lib/slice/allCustomerArtSlice";
import {
  fetchCategoryByProductData,
  setCurrentProduct,
} from "../../lib/slice/categoryByProductSlice";
import {
  fetchColorArtData,
  fetchColorData,
  resetColorArtData,
} from "../../lib/slice/colorSlice";
import { setCurrentDesignsView } from "../../lib/slice/designerSlice";
import { fetchGoogleFontData } from "../../lib/slice/googleFontSlice";

const SideBar = ({
  setIsDefaultOption,
  textLayer,
  enableInputRef,
  setTextLayer,
}) => {
  const ismobile = window.innerWidth <= 991;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const googleFont = useSelector((state) => state.googleFont.data);
  const {
    view,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    selectedLayer,
  } = useSelector((state) => state.designer);
  const customerId = localStorage.getItem("customer");
  const productId = useSelector((state) => state.dashboard.data?.productid);
  const shopUrl = useSelector((state) => state.dashboard.data?.shop_url);
  const storeId = useSelector((state) => state.dashboard.data?.cur_store_id);
  const products = useSelector(
    (state) => state.categoryByProduct.data?.products
  );
  const { currentProduct } = useSelector((state) => state.categoryByProduct);
  const { colorArtData } = useSelector((state) => state.color);

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];

  const options = [
    {
      label: "Upload",
      image: upload,
      path: "/upload",
    },
    {
      label: "Add Text",
      image: t,
      path: "/add-text",
    },
    {
      label: "Previous Art Work",
      image: upload,
      path: "/previous-art-work",
    },
    {
      label: "Product Color",
      image: colors,
      path: "/product-color",
    },
    {
      label: "Add Notes",
      image: note,
      path: "/add-notes",
    },
  ];

  const optionsEditText = [
    {
      label: "Add Text",
      image: t,
      path: "/add-text",
    },
    {
      label: "Edit Text",
      image: editText,
      path: "/edit-text",
    },
    {
      label: "Font",
      image: fontFamily,
      path: "/font-preview",
    },
    {
      label: "Text Color",
      image: colors,
      path: "/font-color",
    },
    {
      label: "Text Size",
      image: resize,
      path: "/text-size",
    },
    {
      label: "Outline",
      image: outline,
      path: "/text-outline",
    },
    {
      label: "Text Alignment",
      image: rotate,
      path: "/text-alignment",
    },
  ];

  const textLayerDebounce = useDebounce(textLayer, 2000);

  const selectedText =
    selectedLayer !== null && selectedLayer?.type === "TEXT"
      ? selectedLayer
      : null;

  useEffect(() => {
    const formData = new FormData();
    formData.append("font_status", "1");
    dispatch(fetchGoogleFontData(formData));
  }, [dispatch]);

  useEffect(() => {
    googleFont?.forEach((fontData) => {
      const linkElement = document.createElement("link");
      linkElement.href = `https://fonts.googleapis.com/css?family=${fontData.strFontFamily}`;
      linkElement.rel = "stylesheet";
      linkElement.title = "googleartfont";

      document.head.appendChild(linkElement);
    });
  }, [googleFont]);

  useEffect(() => {
    if (!currentProduct)
      dispatch(
        setCurrentProduct(
          products?.find((product) => product?.id === productId)
        )
      );
  }, [dispatch, currentProduct, productId, products]);

  useEffect(() => {
    if (shopUrl && customerId) {
      const formData = new FormData();
      formData.append("option_type", "custom");
      formData.append("shop", shopUrl);
      formData.append("customer", customerId);
      dispatch(fetchCategoryByProductData(formData));
    }
  }, [dispatch, shopUrl, customerId]);

  useEffect(() => {
    if (shopUrl && customerId && productId && storeId && !colorArtData?.length) {
      const formData = new FormData();
      formData.append("shop", shopUrl);
      formData.append("customer", customerId);
      formData.append("poduct_id", productId);
      formData.append("cur_store_id", storeId);
      dispatch(fetchColorData(formData)).then((res) => {
        dispatch(resetColorArtData());
        const formColorData = new FormData();
        formColorData.append("color_id", res?.payload?.sku_data?.[0]?.id);
        dispatch(fetchColorArtData(formColorData));
      });
    }
    // eslint-disable-next-line
  }, [dispatch, shopUrl, customerId, productId, storeId]);

  useEffect(() => {
    if (!selectedText) return setTextLayer(null);
    setTextLayer(selectedLayer);
    // eslint-disable-next-line
  }, [selectedLayer, selectedText]);

  useEffect(() => {
    if (!enableInputRef.current) return;
    if (!textLayer) return;

    enableInputRef.current = false;

    const fontSize = parseFloat(textLayer?.config?.fontSize);
    const strokeWidth = parseFloat(textLayer?.config?.strokeWidth);
    if (
      textLayer?.config?.fontSize === "" ||
      textLayer?.config?.strokeWidth === ""
    )
      return;
    if (isNaN(fontSize) || isNaN(strokeWidth)) return;
    if (!Boolean(fontSize)) return;

    const targetLayerIndex = currentDesignsView.findIndex(
      (d) => d?.config?.id === textLayer?.config?.id
    );

    const updatedTextLayer = {
      ...textLayer,
      config: {
        ...textLayer?.config,
        fontSize: fontSize,
        strokeWidth: strokeWidth,
      },
    };

    let tempLayers = [...currentDesignsView];
    tempLayers[targetLayerIndex] = updatedTextLayer;
    dispatch(setCurrentDesignsView(tempLayers));
    // eslint-disable-next-line
  }, [dispatch, textLayerDebounce]);

  useEffect(() => {
    if (!customerId || !shopUrl) return;
    const formData = new FormData();
    formData.append("customer", customerId);
    formData.append("shop", shopUrl);
    dispatch(fetchLatestUploadImage(formData));
  }, [dispatch, customerId, shopUrl]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("customer_id", customerId);
    dispatch(fetchAllCustomerArt(formData));
  }, [dispatch, customerId]);

  return (
    <>
      {(ismobile &&
      [
        "/edit-text",
        "/font-preview",
        "/font-color",
        "/text-size",
        "/text-outline",
        "/text-alignment",
      ].includes(location.pathname)
        ? optionsEditText
        : options
      ).map((item, index) => {
        const isActive =
          item.path === location.pathname ||
          (location.pathname === "/edit-image" && item.path === "/upload") ||
          ([
            "/edit-text",
            "/font-preview",
            "/font-color",
            "/text-outline",
          ].includes(location.pathname) &&
            item.path === "/add-text");

        return (
          <button
            type="button"
            className={`option-btn ${isActive ? "active-tab" : ""}`}
            key={index}
            onClick={() => {
              setIsDefaultOption(false);
              navigate(item.path);
            }}
            style={
              ismobile
                ? { margin: 0, height: 50, border: "none" }
                : isActive
                ? {}
                : { border: "none" }
            }
          >
            <img src={item.image} alt={item.label} loading="lazy" />
            <span>{item.label}</span>
          </button>
        );
      })}
    </>
  );
};

export default SideBar;
