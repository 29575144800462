import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import DesignArea from "./components/DesignArea/DesignArea";
import Footer from "./components/Footer";
import MappingOption from "./components/Rightbar/MappingOption";
import SizeOption from "./components/Rightbar/SizeOption";
import SideBar from "./components/Sidebar/SideBar";
import AddNotes from "./container/AddNotes";
import AddText from "./container/AddText";
import EditImage from "./container/EditImage";
import EditText from "./container/EditText";
import FontColor from "./container/FontColor";
import FontPreview from "./container/FontPreview";
import PreviousArtWork from "./container/PreviousArtWork";
import ProductColor from "./container/ProductColor/ProductColor";
import Products from "./container/Products";
import TextAlignment from "./container/TextAlignment/TextAlignment";
import TextOutline from "./container/TextOutline";
import TextSize from "./container/TextSize/TextSize";
import Upload from "./container/Upload/Upload";
import { fetchDashboardData } from "./lib/slice/dashboardSlice";
import { setZoom } from "./lib/slice/designerSlice";

const Design = ({
  frontDReft,
  backDReft,
  leftDReft,
  rightDReft,
  neckDReft,
  isDefaultOption,
  setIsDefaultOption,
  isDownload,
  setIsDownload,
}) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const enableInputRef = useRef(false);

  const { zoom } = useSelector((state) => state.designer);

  const [textLayer, setTextLayer] = useState(null);

  const id =
    new URLSearchParams(location.search).get("id") ||
    localStorage.getItem("id");
  const customer =
    new URLSearchParams(location.search).get("customer") ||
    localStorage.getItem("customer");

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.ctrlKey && e.deltaY > 0) {
        e.preventDefault();
        zoom > 1.0 && dispatch(setZoom(zoom - 0.1));
      }
      if (e.ctrlKey && e.deltaY < 0) {
        e.preventDefault();
        zoom <= 1.5 && dispatch(setZoom(zoom + 0.1));
      }
    };

    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "+") {
        e.preventDefault();
        zoom <= 1.5 && dispatch(setZoom(zoom + 0.1));
      }
      if (e.ctrlKey && e.key === "-") {
        e.preventDefault();
        zoom > 1.0 && dispatch(setZoom(zoom - 0.1));
      }
      if (e.ctrlKey && e.key === "0") {
        e.preventDefault();
        dispatch(setZoom(1));
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, zoom]);

  useEffect(() => {
    if (id) {
      localStorage.setItem("id", id);
    }
    if (customer) {
      localStorage.setItem("customer", customer);
    }
    if (id && customer) {
      if (location.pathname === "/cart") return;
      navigate(`/product-color?id=${id}&customer=${customer}`);
    } else {
      window.location.href = "https://imprintmaker.com/";
    }

    // eslint-disable-next-line
  }, [id, customer]);

  useEffect(() => {
    if (id && customer) {
      const formData = new FormData();
      formData.append("product_id", id);
      formData.append("shop", "imprintmaker.myshopify.com");
      formData.append("customer", customer);
      dispatch(fetchDashboardData(formData));
    }
  }, [dispatch, id, customer]);

  return (
    <Wrapper>
      {!ismobile &&
        location.pathname !== "/approve-mockup" &&
        location.pathname !== "/cart" && (
          <>
            <div className="custom_height_set">
              <div className="container-fluid custome-tshirt-option">
                <div className="row">
                  <div className="col-md-5 col-lg-4 firststep">
                    <div className="left-option">
                      <div className={"option-variation"}>
                        <SideBar
                          setIsDefaultOption={setIsDefaultOption}
                          textLayer={textLayer}
                          enableInputRef={enableInputRef}
                          setTextLayer={setTextLayer}
                        />
                      </div>
                      <div className="option-details">
                        <Routes>
                          <Route
                            path="/upload"
                            element={
                              <Upload
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                                frontDReft={frontDReft}
                                backDReft={backDReft}
                                leftDReft={leftDReft}
                                rightDReft={rightDReft}
                                neckDReft={neckDReft}
                              />
                            }
                          />
                          <Route
                            path="/edit-image"
                            element={
                              <EditImage
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                              />
                            }
                          />
                          <Route
                            path="/add-text"
                            element={
                              <AddText
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                              />
                            }
                          />
                          <Route
                            path="/edit-text"
                            element={
                              <EditText
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                                setTextLayer={setTextLayer}
                                textLayer={textLayer}
                                enableInputRef={enableInputRef}
                              />
                            }
                          />
                          <Route
                            path="/font-preview"
                            element={
                              <FontPreview
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                                enableInputRef={enableInputRef}
                                setTextLayer={setTextLayer}
                              />
                            }
                          />
                          <Route
                            path="/font-color"
                            element={
                              <FontColor
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                                enableInputRef={enableInputRef}
                                setTextLayer={setTextLayer}
                              />
                            }
                          />
                          <Route
                            path="/text-outline"
                            element={
                              <TextOutline
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                                textLayer={textLayer}
                                setTextLayer={setTextLayer}
                                enableInputRef={enableInputRef}
                              />
                            }
                          />
                          <Route
                            path="/previous-art-work"
                            element={
                              <PreviousArtWork
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                              />
                            }
                          />
                          <Route path="/" element={<Outlet />}>
                            <Route
                              index
                              element={<Navigate to="product-color" replace />}
                            />
                            <Route
                              path="product-color"
                              element={
                                <ProductColor
                                  isDefaultOption={isDefaultOption}
                                  setIsDefaultOption={setIsDefaultOption}
                                />
                              }
                            />
                          </Route>
                          <Route
                            path="/add-notes"
                            element={
                              <AddNotes
                                isDefaultOption={isDefaultOption}
                                setIsDefaultOption={setIsDefaultOption}
                              />
                            }
                          />
                          <Route path="/products" element={<Products />} />
                        </Routes>
                      </div>
                    </div>
                  </div>
                  <DesignArea
                    frontDReft={frontDReft}
                    backDReft={backDReft}
                    leftDReft={leftDReft}
                    rightDReft={rightDReft}
                    neckDReft={neckDReft}
                    setIsDefaultOption={setIsDefaultOption}
                    isDownload={isDownload}
                  />
                  <div className="right-col-option append_another_product_template">
                    <div className="right-option mapping_section">
                      <MappingOption />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer setIsDownload={setIsDownload} />
          </>
        )}
      {ismobile &&
        location.pathname !== "/approve-mockup" &&
        location.pathname !== "/cart" && (
          <>
            <MappingOption />
            <div className="mobile-canvas canvas-img">
              <DesignArea
                frontDReft={frontDReft}
                backDReft={backDReft}
                leftDReft={leftDReft}
                rightDReft={rightDReft}
                neckDReft={neckDReft}
                setIsDefaultOption={setIsDefaultOption}
                isDownload={isDownload}
              />
            </div>

            <div className="all_section_btn">
              <div className="parents_footer_">
                <SizeOption />
                <Footer setIsDownload={setIsDownload} />
              </div>
            </div>
            <div className="moble-option-veriastion footer_section">
              <SideBar
                setIsDefaultOption={setIsDefaultOption}
                textLayer={textLayer}
                enableInputRef={enableInputRef}
                setTextLayer={setTextLayer}
              />
            </div>
            <Routes>
              <Route
                path="/upload"
                element={
                  <Upload
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                    frontDReft={frontDReft}
                    backDReft={backDReft}
                    leftDReft={leftDReft}
                    rightDReft={rightDReft}
                    neckDReft={neckDReft}
                  />
                }
              />
              <Route
                path="/edit-image"
                element={
                  <EditImage
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                  />
                }
              />
              <Route
                path="/add-text"
                element={
                  <AddText
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                  />
                }
              />
              <Route
                path="/edit-text"
                element={
                  <EditText
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                    setTextLayer={setTextLayer}
                    textLayer={textLayer}
                    enableInputRef={enableInputRef}
                  />
                }
              />
              <Route
                path="/font-preview"
                element={
                  <FontPreview
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                    enableInputRef={enableInputRef}
                    setTextLayer={setTextLayer}
                  />
                }
              />
              <Route
                path="/font-color"
                element={
                  <FontColor
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                    enableInputRef={enableInputRef}
                    setTextLayer={setTextLayer}
                  />
                }
              />
              <Route
                path="/text-size"
                element={
                  <TextSize
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                    setTextLayer={setTextLayer}
                    textLayer={textLayer}
                    enableInputRef={enableInputRef}
                  />
                }
              />
              <Route
                path="/text-outline"
                element={
                  <TextOutline
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                    textLayer={textLayer}
                    setTextLayer={setTextLayer}
                    enableInputRef={enableInputRef}
                  />
                }
              />
              <Route
                path="/text-alignment"
                element={
                  <TextAlignment
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                  />
                }
              />
              <Route
                path="/previous-art-work"
                element={
                  <PreviousArtWork
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                  />
                }
              />
              <Route path="/" element={<Outlet />}>
                <Route
                  index
                  element={<Navigate to="product-color" replace />}
                />
                <Route
                  path="product-color"
                  element={
                    <ProductColor
                      isDefaultOption={isDefaultOption}
                      setIsDefaultOption={setIsDefaultOption}
                    />
                  }
                />
              </Route>

              <Route
                path="/add-notes"
                element={
                  <AddNotes
                    isDefaultOption={isDefaultOption}
                    setIsDefaultOption={setIsDefaultOption}
                  />
                }
              />
              <Route
                path="/products"
                element={<Products setIsDefaultOption={setIsDefaultOption} />}
              />
            </Routes>
          </>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .option-variation {
    margin-right: 11px;
  }

  .right-option {
    margin-top: 22px;
    max-width: unset;
  }

  .mobile-canvas {
    height: unset;
    position: static;
    margin-top: 50px;
  }

  .all_section_btn {
    position: static;
    margin-bottom: 80px;
  }

  .moble-option-veriastion {
    display: flex;
    padding: 15px;
    margin: 0;
    background-color: #fff;
    justify-content: space-around;
    box-shadow: 0 -3px 6px #00000029;
    position: fixed;
  }
`;

export default Design;
