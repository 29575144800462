import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../components/Sidebar/Default";

const FontPreview = ({
  isDefaultOption,
  setIsDefaultOption,
  enableInputRef,
  setTextLayer,
}) => {
  const ismobile = window.innerWidth <= 991;

  const navigate = useNavigate();

  const { selectedLayer } = useSelector((state) => state.designer);
  const { data } = useSelector((state) => state.googleFont);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (selectedLayer?.type !== "TEXT") {
      navigate("/add-text");
    }
    // eslint-disable-next-line
  }, []);

  const filteredFonts = data?.filter((fontData) =>
    fontData.strFontFamily.toLowerCase().includes(search.trim().toLowerCase())
  );

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile
              ? "mbl-fontfamily-option mbl-option"
              : "font-family-option option-box"
          }
          style={
            ismobile
              ? { position: "fixed", display: "inline-block", top: 65 }
              : { display: "inline-block" }
          }
        >
          <div className="option-heading">
            <div
              className="back-btn"
              onClick={() => {
                navigate("/edit-text");
                ismobile && setIsDefaultOption(true);
              }}
            >
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Font Preview</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12 search-box-div">
            <input
              type="text"
              className="form-control input-lg search-box fontfamily-search"
              placeholder="Search Fonts"
              onChange={(e) => setSearch(e.target.value)}
              style={{ backgroundColor: "transparent", zIndex: 0 }}
            />
          </div>
          <div className="google-font" style={{ marginTop: 73 }}>
            <ul>
              {filteredFonts?.map((fontData) => {
                return (
                  <li
                    className="fontstylecss"
                    style={{ fontSize: 15 }}
                    onClick={() => {
                      enableInputRef.current = true;
                      setTextLayer((prev) => ({
                        ...prev,
                        config: {
                          ...prev?.config,
                          fontFamily: fontData.strFontFamily,
                        },
                      }));
                      ismobile && setIsDefaultOption(true);
                    }}
                    key={fontData?.id}
                  >
                    <span
                      className="appendtext"
                      style={{
                        fontSize: 33,
                        fontFamily: fontData.strFontFamily,
                      }}
                    >
                      {selectedLayer?.content}
                    </span>
                    <br />
                    <span className="arttitle">{fontData.strFontFamily}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default FontPreview;
