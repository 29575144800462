import React from "react";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import WithLoader from "../../components/WithLoader";
import {
  fetchColorArtData,
  removeColorArtData,
  resetColorArtData,
  setFocusColor,
} from "../../lib/slice/colorSlice";

const ProductColorOption = ({
  setIsColorOptionVisible,
  setLoader,
  setLoaderMsg,
  handleSaveImage,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const productColor = useSelector((state) => state.color.data);
  const { colorArtData } = useSelector((state) => state.color);

  const handleColorClick = async (t) => {
    if (location.pathname !== "/approve-mockup" && colorArtData?.find((v) => v?.id === t?.id) ) return

    setLoader(true);
    setLoaderMsg("This step may take up to 3 seconds.");

    if (location.pathname !== "/approve-mockup") {
      dispatch(resetColorArtData());
    }
    
    const convertedData = colorArtData?.find((v) => v?.id === t?.id);

    if (!convertedData) {
      const formData = new FormData();
      formData.append("color_id", t?.id);
      await dispatch(fetchColorArtData(formData)).then(async (res) => {
        if (res?.payload) {
          if (location.pathname === "/approve-mockup") {
            await handleSaveImage(res.payload?.colorcode);
            setIsColorOptionVisible(false);
          }
          dispatch(setFocusColor(res?.payload));
        }
      });
      setLoader(false);
      setLoaderMsg("");
    }

    const isSelected = colorArtData?.some((selected) => selected.id === t.id);

    if (isSelected === true) {
      if (colorArtData?.length >= 2) {
        dispatch(removeColorArtData(t?.id));
      }
      setLoader(false);
      setLoaderMsg("");
    }
  };

  return (
    <Wrapper className="color-option1 color-option-box">
      {productColor?.sku_data?.map((t) => {
        return (
          <div className="customdesigntextcolor" key={t.id}>
            <li
              className="colorcode dynamicchangeimg"
              style={{ backgroundColor: t.colorcode }}
              onClick={() => handleColorClick(t)}
            >
              {colorArtData.some((selected) => selected.id === t.id) && (
                <div className="checkmark">
                  <FaCheck style={{ color: "#FFFFFF", fontSize: 12 }} />
                </div>
              )}
            </li>
            <span className="swatch-name">{t.colorname}</span>
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  max-height: 270px;
  overflow-y: auto;
  margin-bottom: 13px;

  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

export default WithLoader(ProductColorOption);
