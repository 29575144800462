import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchGoogleFontData = createAsyncThunk(
  "googleFont/fetchGoogleFontData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/loadgooglefont`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const sliderSlice = createSlice({
  name: "googleFont",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoogleFontData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGoogleFontData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchGoogleFontData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sliderSlice.reducer;
