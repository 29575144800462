import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getCartDetails = createAsyncThunk(
  "cartDetails/getCartDetails",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getCartDetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateCartQyt = createAsyncThunk(
  "cartDetails/updateCartQyt",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updatecartqyt`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const removeCartItem = createAsyncThunk(
  "cartDetails/removeCartItem",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/remove_cart_item`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const removecartdetails = createAsyncThunk(
  "cartDetails/removecartdetails",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/removecartdetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const applyCustomerDiscount = createAsyncThunk(
  "cartDetails/applyCustomerDiscount",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Applycustomerciscount`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updatedraftorder = createAsyncThunk(
  "cartDetails/updatedraftorder",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updatedraftorder`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
  successMessage: null,
  applyCustomerDiscountData: null,
};

const cartSlice = createSlice({
  name: "cartDetails",
  initialState,
  reducers: {
    clearUpdateCartMsg: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCartDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCartDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateCartQyt.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCartQyt.fulfilled, (state) => {
        state.loading = false;
        state.successMessage  = "Your Cart Updated Successfully..!";
      })
      .addCase(updateCartQyt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(removeCartItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCartItem.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeCartItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(removecartdetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(removecartdetails.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removecartdetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(applyCustomerDiscount.pending, (state) => {
        state.loading = true;
      })
      .addCase(applyCustomerDiscount.fulfilled, (state, action) => {
        state.applyCustomerDiscountData = action.payload
        state.loading = false;
      })
      .addCase(applyCustomerDiscount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearUpdateCartMsg } = cartSlice.actions;

export default cartSlice.reducer;
