import React, { useEffect } from "react";
import { FaCheck, FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../components/Sidebar/Default";

const TextOutline = ({
  isDefaultOption,
  setIsDefaultOption,
  textLayer,
  setTextLayer,
  enableInputRef,
}) => {
  const ismobile = window.innerWidth <= 991;

  const navigate = useNavigate();

  const { selectedLayer } = useSelector((state) => state.designer);
  const { data } = useSelector((state) => state.textOutlineColor);

  useEffect(() => {
    if (selectedLayer?.type !== "TEXT") {
      navigate("/add-text");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile
              ? "mbl-text-outline-option mbl-option bottom-option"
              : "text-outline-option option-box"
          }
          style={{
            position: ismobile ? "fixed" : "relative",
            display: "inline-block",
          }}
        >
          <div className="option-heading">
            <div
              className="back-btn"
              onClick={() => {
                navigate("/edit-text");
                ismobile && setIsDefaultOption(true);
              }}
            >
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Text Outline</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <div className="select-color-option">
            <div className="shape-settings">
              <span>Choose Outline Thinkness</span>
              <div className="shape-size-bg">
                <input
                  className="slider"
                  type="range"
                  value={textLayer?.config?.strokeWidth}
                  min="0"
                  max="100"
                  step="1"
                  onChange={(data) => {
                    enableInputRef.current = true;
                    setTextLayer((prev) => ({
                      ...prev,
                      config: {
                        ...prev?.config,
                        strokeWidth: data.target.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="combine_content">
              {selectedLayer?.config?.stroke && (
                <div className="color-head">
                  <label>Selected color :</label>
                  <span
                    className="color-btn dynamicappendtextoutline"
                    style={{ backgroundColor: selectedLayer?.config?.stroke }}
                  />
                </div>
              )}
              <ul className="color-option color-option-box textoutlinecolor">
                {data?.map((item) => {
                  return (
                    <div className="customdesigntextcolor" key={item?.id}>
                      <li
                        className="textoutlinecolorhover"
                        style={{ backgroundColor: item?.code }}
                        onClick={() => {
                          enableInputRef.current = true;
                          setTextLayer((prev) => ({
                            ...prev,
                            config: { ...prev?.config, stroke: item?.code },
                          }));
                        }}
                      >
                        {selectedLayer?.config?.stroke === item?.code && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <FaCheck
                              style={{ color: "#FFFFFF", fontSize: 12 }}
                            />
                          </div>
                        )}
                      </li>
                      <span className="swatch-name">{item?.name}</span>
                    </div>
                  );
                })}
              </ul>
              <div className="color-boxs" style={{ cursor: "default" }}>
                <span style={{ position: "relative" }}>
                  Choose Other Color
                  <input
                    onChange={(data) => {
                      enableInputRef.current = true;
                      setTextLayer((prev) => ({
                        ...prev,
                        config: {
                          ...prev?.config,
                          stroke: data?.target?.value,
                        },
                      }));
                    }}
                    type="color"
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      width: "100%",
                      opacity: 0,
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
              <div className="button-box">
                <button
                  type="button"
                  style={{ margin: "0 8px" }}
                  className="orange-btn border-btn removeoutline"
                  onClick={() => {
                    enableInputRef.current = true;
                    setTextLayer((prev) => ({
                      ...prev,
                      config: {
                        ...prev?.config,
                        stroke: "#000000",
                        strokeWidth: 0,
                      },
                    }));
                  }}
                >
                  Remove Outline
                </button>
                <button
                  type="button"
                  style={{ margin: "0 8px" }}
                  className="orange-btn add-shape-design changeshapart outlinedone"
                  onClick={() => {
                    navigate("/edit-text");
                    ismobile && setIsDefaultOption(true);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TextOutline;
