import React, { useEffect, useMemo, useState } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../components/Sidebar/Default";
import { useFormula } from "../lib/hooks/useFormula";
import {
  setCurrentDesignsView,
  setSelectedLayer,
} from "../lib/slice/designerSlice";
import { fetchTextColorData } from "../lib/slice/textColorSlice";
import { fetchTextOutlineColorData } from "../lib/slice/textOutlineColorSlice";

const AddText = ({ isDefaultOption, setIsDefaultOption }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    view,
  } = useSelector((state) => state.designer);
  const googleFont = useSelector((state) => state.googleFont.data);

  const currentDesignsView = useMemo(() => {
    return view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];
  }, [view, frontDesigns, backDesigns, leftDesigns, rightDesigns, neckDesigns]);

  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();

  const [textValue, setTextValue] = useState("");

  const defaultText = useMemo(() => {
    return {
      type: "TEXT",
      content: textValue,
      base64High: "",
      base64: "",
      config: {
        x: 0,
        y: 0,
        id: new Date().getTime(),
        fontFamily: googleFont?.length
          ? googleFont[0]?.strFontFamily
          : "Roboto",
        fill: "#ffffff",
        fontSize:
          view === "front"
            ? NewImageFormulaFront
            : view === "back"
            ? NewImageFormulaBack
            : view === "left"
            ? NewImageFormulaLeft
            : view === "right"
            ? NewImageFormulaRight
            : view === "neck"
            ? NewImageFormulaNeck
            : 0,
        textDecoration: "",
        strokeWidth: 0,
        stroke: "#000000",
        fillAfterStrokeEnabled: true,
        width: 0,
        height: 0,
      },
    };
  }, [
    view,
    textValue,
    googleFont,
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  ]);

  useEffect(() => {
    dispatch(setSelectedLayer(null));
  }, [dispatch]);

  useEffect(() => {
    const formData = new FormData();
    dispatch(fetchTextColorData(formData));
  }, [dispatch]);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile ? "mbl-text mbl-option" : "text-opt-details option-box"
          }
          style={
            ismobile
              ? { position: "fixed", display: "inline-block", top: 65 }
              : { display: "inline-block" }
          }
        >
          <div className="option-heading">
            <div className="back-btn" onClick={() => setIsDefaultOption(true)}>
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Add Text</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <form>
            <div className="input-group">
              <textarea
                className="form-control remveexisname"
                style={{ backgroundColor: "transparent", zIndex: 0 }}
                rows={5}
                id="add-text"
                placeholder="Enter text here"
                onChange={(e) => {
                  setTextValue(e.target.value);
                }}
              />
            </div>
            <div className="button-box">
              <button
                type="button"
                className="orange-btn border-btn cancel-btn addtextcancel"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  ismobile
                    ? setIsDefaultOption(true)
                    : navigate("/product-color");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="orange-btn add-design-btn addedtext"
                style={{ margin: "0 8px", opacity: textValue ? 1 : 0.6 }}
                onClick={() => {
                  const formData = new FormData();
                  dispatch(
                    setCurrentDesignsView([...currentDesignsView, defaultText])
                  );
                  dispatch(setSelectedLayer(defaultText));
                  dispatch(fetchTextOutlineColorData(formData));
                  ismobile && setIsDefaultOption(true);
                }}
                disabled={!textValue}
              >
                Add
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddText;
