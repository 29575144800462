import React from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../assets/images/colors.svg";
import shirtIcon from "../../assets/images/shirt-icon.svg";
import t from "../../assets/images/t.svg";
import upload from "../../assets/images/upload.svg";

const Default = ({ setIsDefaultOption }) => {
  const ismobile = window.innerWidth <= 991;

  const navigate = useNavigate();

  const options = [
    {
      label: "Add Text",
      image: t,
      path: "/add-text",
    },
    {
      label: "Upload",
      image: upload,
      path: "/upload",
    },
    {
      label: "Product Color",
      image: colors,
      path: "/product-color",
    },
    {
      label: "Change Product",
      image: shirtIcon,
      path: "/products",
    },
  ];

  return (
    <>
      {ismobile ? (
        <></>
      ) : (
        <div
          className="next-option option-box"
          style={{ display: "inline-block" }}
        >
          <p>Select An Option</p>
          <ul>
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  setIsDefaultOption(false);
                  if (option.label !== "Change Product") {
                    navigate(option.path);
                  } else {
                    window.location.href =
                      "https://imprintmaker.com/pages/choose-an-option";
                  }
                }}
              >
                <div className="next-opt-box text_format">
                  <img
                    src={option.image}
                    style={{ height: 50 }}
                    alt="option"
                    loading="lazy"
                  />
                  <span>{option.label}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Default;
