import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa6";
import { MdClose, MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/logo.avif";

const ShopifyHeader = () => {
  const ismobile = window.innerWidth <= 991;

  const [search, setSearch] = useState("");

  const menuRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef?.current && !menuRef?.current.contains(event.target)) {
        menuRef?.current?.classList?.remove("open");
        overlayRef?.current?.classList?.remove("menu-drawer-overlay");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuItems = [
    { label: "Home", link: "https://imprintmaker.com/" },
    {
      label: "Start An Order",
      link: "https://imprintmaker.com/pages/choose-an-option",
    },
    {
      label: "Catalog",
      link: "https://imprintmaker.com/collections/product-catalog-new2024",
    },
    { label: "FAQ", link: "https://imprintmaker.com/pages/faq" },
    { label: "Contact Us", link: "https://imprintmaker.com/pages/contact-us" },
  ];

  return (
    <Wrapper $ismobile={ismobile}>
      <div className="header__">
        <header className="imp_block">
          {ismobile && (
            <div className="header__inline-menu">
              <button
                className="mobile-menu"
                onClick={() => {
                  menuRef?.current?.classList?.add("open");
                  overlayRef?.current?.classList?.add("menu-drawer-overlay");
                }}
              >
                <MdMenu />
              </button>
            </div>
          )}
          <h1 className="header__heading">
            <Link
              to="https://imprintmaker.com/"
              className="header__heading-link"
            >
              <img
                src={logo}
                alt="Imprintmaker"
                className="header__heading-logo"
              />
            </Link>
          </h1>
          {!ismobile && (
            <nav className="header__inline-menu">
              <ul className="list-menu">
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link}>
                      <span>{item.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
          <div className="header__icons">
            <div className="banner__buttons">
              <div className="col-sm-8 cart">
                <form
                  className="topsearch"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (search) {
                      window.location.href = `https://imprintmaker.com/search?q=${search}`;
                    }
                  }}
                >
                  <input
                    name="q"
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button type="submit">
                    <FaSearch />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </header>
        <>
          <div id="menu-drawer" ref={menuRef}>
            <div className="menu-drawer__navigation-container">
              <div>
                <button
                  onClick={() => {
                    menuRef?.current?.classList?.remove("open");
                    overlayRef?.current?.classList?.remove(
                      "menu-drawer-overlay"
                    );
                  }}
                  className="close-menu"
                >
                  <MdClose />
                </button>
                <nav className="menu-drawer__navigation">
                  <ul className="menu-drawer__menu">
                    {menuItems.map((item, index) => (
                      <li key={index}>
                        <Link to={item.link}>{item.label}</Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="menu-drawer__utility-links">
                <ul className="list">
                  <li>
                    <Link to="https://www.instagram.com/imprintmaker/">
                      <FaInstagram />
                      <span className="visually-hidden">Instagram</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.tiktok.com/@imprintmaker">
                      <FaTiktok />
                      <span className="visually-hidden">TikTok</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@imprintmakerDTG">
                      <FaYoutube />
                      <span className="visually-hidden">YouTube</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div ref={overlayRef}></div>
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: #fff;

  .header__ {
    position: relative;
    padding: 0 0.75rem;
    margin: 0 auto;
    max-width: 100%;

    @media (min-width: 768px) {
      width: 750px;
    }

    @media (min-width: 992px) {
      width: 970px;
    }

    @media (min-width: 1200px) {
      width: 1300px;
    }
  }

  .menu-drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    z-index: 999;
  }

  #menu-drawer {
    position: fixed;
    top: 0;
    left: -100%;
    width: 90%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
    transition: all 0.5s ease;

    &.open {
      left: 0;
    }

    .menu-drawer__navigation-container {
      display: grid;
      grid-template-rows: 1fr auto;
      align-content: space-between;
      overflow-y: auto;
      height: 100%;

      .close-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 15px;
        top: 15px;
        border: 0;
        background-color: transparent;

        svg {
          color: #333;
          font-size: 28px;
        }
      }

      .menu-drawer__navigation {
        margin-top: 100px;

        .menu-drawer__menu {
          li {
            a {
              display: flex !important;
              align-items: center;
              color: #333;
              font-size: 16px;
              font-family: Lato-Regular, Helvetica Neue, Helvetica, Arial,
                sans-serif;
              padding: 8px 15px;
            }
          }
        }
      }

      .menu-drawer__utility-links {
        background: buttonface;

        .list {
          display: flex;
          justify-content: center;
          padding: 10px 0;

          li {
            font-family: Lato-Regular, Helvetica Neue, Helvetica, Arial,
              sans-serif;

            a {
              display: flex;
              align-items: center;
              font-size: 12px;
              gap: 13px;
              padding: 5px 10px;
              color: #333;

              svg {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  .imp_block {
    max-width: 143rem;
    display: grid;
    align-items: center;
    margin: 0 auto;
    padding: 9px 0;

    /* Define grid template areas */
    grid-template-areas: ${(props) =>
      props.$ismobile
        ? "'navigation heading icons'"
        : "'heading navigation icons'"};

    .header__heading {
      grid-area: heading;
      margin: 0;

      &-link {
        display: inline-block;
        max-width: 230px;

        .header__heading-logo {
          max-width: 100%;
          height: 68px;
        }
      }
    }

    .header__inline-menu {
      grid-area: navigation;

      .mobile-menu {
        border: 0;
        background-color: transparent;

        svg {
          font-size: 36px;
          color: #333;
        }
      }

      .list-menu {
        display: inline-flex;
        flex-wrap: wrap;

        li {
          padding: 0px 14px;

          a {
            display: inline-block;
            padding: 15px 10px;
            font-size: 16px;
            font-family: Helvetica, Arial, sans-serif, serif !important;
            font-weight: 500;

            &:hover span {
              text-decoration: underline;
              text-underline-offset: 0.3rem;
              text-decoration-color: red;
              text-decoration-thickness: 0.1rem;
            }
          }
        }
      }
    }

    .header__icons {
      width: 100%;
      justify-content: flex-end;
      gap: 10px;
      justify-self: normal;
      display: flex;
      grid-area: icons;

      .banner__buttons {
        display: flex;
        gap: 15px;
        padding: 2px;

        .cart {
          width: ${(props) => (props.$ismobile ? "100%" : "150px")};

          .topsearch {
            input {
              height: 47px;
            }

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;
              right: 16px;
              height: 47px;
              width: 43px;
              border: unset;
              background: unset;

              svg {
                font-size: 19px;
                color: lightgray;
              }
            }
          }
        }
      }
    }
  }
`;

export default ShopifyHeader;
