import React, { useEffect } from "react";
import { FaCheck, FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../components/Sidebar/Default";

const FontColor = ({
  isDefaultOption,
  setIsDefaultOption,
  enableInputRef,
  setTextLayer,
}) => {
  const ismobile = window.innerWidth <= 991;

  const navigate = useNavigate();

  const { selectedLayer } = useSelector((state) => state.designer);
  const { data } = useSelector((state) => state.textColor);

  useEffect(() => {
    if (selectedLayer?.type !== "TEXT") {
      navigate("/add-text");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile
              ? "mbl-color-option mbl-option bottom-option"
              : "font-color-option option-box"
          }
          style={{
            position: ismobile ? "fixed" : "relative",
            display: "inline-block",
          }}
        >
          <div className="option-heading">
            <div
              className="back-btn"
              onClick={() => {
                navigate("/edit-text");
                ismobile && setIsDefaultOption(true);
              }}
            >
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Font Color</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <div className="select-color-option dynamicTextColor">
            {selectedLayer?.config?.fill && (
              <div className="color-head">
                <label>Selected color :</label>
                <span
                  className="color-btn dynamicappendcolor"
                  style={{ backgroundColor: selectedLayer?.config?.fill }}
                ></span>
              </div>
            )}
            <ul className="color-option color-option-box textcolorcode">
              {data?.map((item) => {
                return (
                  <div className="customdesigntextcolor" key={item?.id}>
                    <li
                      className="textcolorhover"
                      style={{
                        backgroundColor: item?.code,
                      }}
                      onClick={() => {
                        enableInputRef.current = true;
                        setTextLayer((prev) => ({
                          ...prev,
                          config: { ...prev?.config, fill: item?.code },
                        }));
                      }}
                    >
                      {selectedLayer?.config?.fill === item?.code && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <FaCheck style={{ color: "#FFFFFF", fontSize: 12 }} />
                        </div>
                      )}
                    </li>
                    <span className="swatch-name">{item?.name}</span>
                  </div>
                );
              })}
            </ul>
            <div className="color-boxs" style={{ cursor: "default" }}>
              <span style={{ position: "relative" }}>
                Choose Other Color
                <input
                  onChange={(data) => {
                    enableInputRef.current = true;
                    setTextLayer((prev) => ({
                      ...prev,
                      config: {
                        ...prev?.config,
                        fill: data?.target?.value,
                      },
                    }));
                  }}
                  type="color"
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
            <button
              type="button"
              className="orange-btn textfontcolorbtn"
              onClick={() => {
                navigate("/edit-text");
                ismobile && setIsDefaultOption(true);
              }}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FontColor;
