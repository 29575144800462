import React, { useRef, useState } from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Design from "./Design";
import Header from "./components/Header";
import ShopifyHeader from "./components/ShopifyHeader";
import ApproveMockup from "./container/ApproveMockup";
import Cart from "./container/Cart";
import store from "./store";

const App = () => {
  const ismobile = window.innerWidth <= 991;

  const frontDReft = useRef();
  const backDReft = useRef();
  const leftDReft = useRef();
  const rightDReft = useRef();
  const neckDReft = useRef();

  const [isDefaultOption, setIsDefaultOption] = useState(
    ismobile ? true : false
  );
  const [isDownload, setIsDownload] = useState(false);

  return (
    <Provider store={store}>
      <ShopifyHeader />
      <Header />
      <Routes>
        <Route
          path="/approve-mockup"
          element={
            <ApproveMockup
              frontDReft={frontDReft}
              backDReft={backDReft}
              leftDReft={leftDReft}
              rightDReft={rightDReft}
              neckDReft={neckDReft}
              setIsDefaultOption={setIsDefaultOption}
              isDownload={isDownload}
              setIsDownload={setIsDownload}
            />
          }
        />
        <Route path="/cart" element={<Cart />} />
      </Routes>
      <Design
        frontDReft={frontDReft}
        backDReft={backDReft}
        leftDReft={leftDReft}
        rightDReft={rightDReft}
        neckDReft={neckDReft}
        isDefaultOption={isDefaultOption}
        setIsDefaultOption={setIsDefaultOption}
        isDownload={isDownload}
        setIsDownload={setIsDownload}
      />
    </Provider>
  );
};

export default App;
