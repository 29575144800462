import React from "react";
import { FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { applySolidBackgroundAbovePNG } from "../lib/applySolidBackgroundAbovePNG";
import {
  fetchAllCustomerArt,
  fetchLatestUploadImage,
} from "../lib/slice/allCustomerArtSlice";
import { fetchRemoveArtData } from "../lib/slice/artSlice";
import { fetchConvertImageArtData } from "../lib/slice/createTextToImageSlice";
import {
  setCurrentDesignsView,
  setSelectedLayer,
} from "../lib/slice/designerSlice";
import WithLoader from "./WithLoader";

const Latestimages = ({ latestimages, setLoader, setLoaderMsg }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    view,
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
  } = useSelector((state) => state.designer);
  const shopUrl = useSelector((state) => state.dashboard.data?.shop_url);
  const dashboard = useSelector(
    (state) => state.dashboard?.data
  );
  const pid = useSelector((state) => state.dashboard.data?.productid);

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];

  const handleCommonFileLoad = (item, asset, width, height) => {
    const img = new Image();
    img.src = asset;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      const imageData = ctx.getImageData(0, 0, width, height);
      const data = imageData.data;
      let occupiedPixelCount = 0;
      for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] !== 0) {
          occupiedPixelCount++;
        }
      }
      const totalPixels = width * height;
      const occupiedPixelsPercentage = (occupiedPixelCount / totalPixels) * 100;

      const dataLayer = {
        type: "IMAGE",
        name: item?.artwork,
        asset,
        base64: asset,
        file: {},
        config: {
          x:
            view === "front"
              ? (frontClientCanvas?.canvasWidth - width) / 2
              : view === "back"
              ? (backClientCanvas?.canvasWidth - width) / 2
              : view === "left"
              ? (leftClientCanvas?.canvasWidth - width) / 2
              : view === "right"
              ? (rightClientCanvas?.canvasWidth - width) / 2
              : view === "neck"
              ? (neckClientCanvas?.canvasWidth - width) / 2
              : 0,
          y: 0,
          id: item?.id,
          width,
          height,
          occupiedPixelsPercentage,
        },
      };
      dispatch(setCurrentDesignsView([...currentDesignsView, dataLayer]));
      dispatch(setSelectedLayer(dataLayer));
    };
  };

  const calculateImageDimension = (type, originalWidth, originalHeight) => {
    const maxWidth =
      view === "front"
        ? frontClientCanvas?.canvasWidth
        : view === "back"
        ? backClientCanvas?.canvasWidth
        : view === "left"
        ? leftClientCanvas?.canvasWidth
        : view === "right"
        ? rightClientCanvas?.canvasWidth
        : view === "neck"
        ? neckClientCanvas?.canvasWidth
        : 0;
    const maxHeight =
      view === "front"
        ? frontClientCanvas?.canvasHeight
        : view === "back"
        ? backClientCanvas?.canvasHeight
        : view === "left"
        ? leftClientCanvas?.canvasHeight
        : view === "right"
        ? rightClientCanvas?.canvasHeight
        : view === "neck"
        ? neckClientCanvas?.canvasHeight
        : 0;

    if (originalWidth > maxWidth || originalHeight > maxHeight) {
      const widthRatio = maxWidth / originalWidth;
      const heightRatio = maxHeight / originalHeight;

      if (widthRatio < heightRatio) {
        if (type === "width") {
          return maxWidth;
        } else {
          return originalHeight * widthRatio;
        }
      } else {
        if (type === "width") {
          return originalWidth * heightRatio;
        } else {
          return maxHeight;
        }
      }
    }
    return originalWidth;
  };

  const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = url;
    });
  };

  return (
    <Wrapper>
      {latestimages?.map((item) => {
        return (
          <div className="col-md-3 col-lg-2 product" key={item?.id}>
            <button
              type="button"
              className="close"
              onClick={async () => {
                setLoader(true);
                const formData = new FormData();
                formData.append("image_id", item.id);
                dispatch(fetchRemoveArtData(formData)).then((response) => {
                  if (response?.payload) {
                    const customer = localStorage.getItem("customer");

                    if (customer) {
                      if (location.pathname === "/upload") {
                        const formLatestData = new FormData();
                        formLatestData.append("customer", customer);
                        formLatestData.append("shop", shopUrl);
                        dispatch(fetchLatestUploadImage(formLatestData)).then(
                          () => {
                            setLoader(false);
                            setLoaderMsg("");
                          }
                        );
                      } else if (location.pathname === "/previous-art-work") {
                        const formArtData = new FormData();
                        formArtData.append("customer_id", customer);
                        dispatch(fetchAllCustomerArt(formArtData)).then(() => {
                          setLoader(false);
                          setLoaderMsg("");
                        });
                      }
                    }
                  } else {
                    setLoader(false);
                    setLoaderMsg("");
                  }
                });
              }}
            >
              <div className="close-btn">
                <FaXmark />
              </div>
            </button>
            <div className="product-box">
              <div className="product-img" style={{ height: 120 }}>
                <img
                  style={{
                    maxHeight: "100%",
                    width: "auto",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  src={item.convert_image}
                  alt="latest-art-work"
                  loading="lazy"
                />
              </div>
              <div className="product-details">
                <button
                  type="button"
                  className="productdatas opennewoption"
                  onClick={async (e) => {
                    setLoader(true);
                    let imageUrl = "";

                    try {
                      if (dashboard?.productType === "Tumblers") {
                        const formData = new FormData();
                        formData.append("image_url", item?.artwork);
                        formData.append("upload_type", "image");
                        formData.append("cat_name", "Tumblers");
                        formData.append("pid", pid);

                        const res = await dispatch(
                          fetchConvertImageArtData(formData)
                        );

                        const resultBase64 = await applySolidBackgroundAbovePNG(
                          res?.payload?.tumlerImgdata,
                          item.convert_image
                        );

                        imageUrl = resultBase64;
                      } else {
                        imageUrl = item.convert_image;
                      }

                      const dimensions = await getImageDimensions(imageUrl);

                      const width = calculateImageDimension(
                        "width",
                        dimensions.width,
                        dimensions.height
                      );
                      const height = calculateImageDimension(
                        "height",
                        dimensions.width,
                        dimensions.height
                      );

                      handleCommonFileLoad(item, imageUrl, width, height);
                      setLoaderMsg("");
                    } catch (error) {
                      console.error("Error:", error);
                    } finally {
                      setLoader(false);
                    }
                  }}
                >
                  Add Image
                </button>
              </div>
            </div>
            <span className="artlabelblock">{item?.artwork}</span>
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-basis: 100%;

  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e33f3a;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    svg {
      color: #ffffff;
      font-size: 16px;
    }
  }

  .product {
    width: 50%;
    margin-top: 20px;
  }

  .close {
    margin: 0;
    opacity: 1;
    width: 32px;
    position: absolute;
    right: 11px;
    top: -9px;
    z-index: 1;
  }

  .removeTheArtData {
    background-color: #e33f3a;
    padding: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }

  .product-details {
    padding: 0;
  }

  .productdatas {
    padding: 7px 10px;
    background-color: #e33f3a;
    color: #fff;
    border-radius: 5px;
    position: absolute;
    bottom: 15px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    min-width: 85%;
    border: none;
  }

  .artlabelblock {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 187px;
    display: inline-block;
  }
`;

export default WithLoader(Latestimages);
