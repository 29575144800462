import React, { useEffect } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import Default from "../../components/Sidebar/Default";
import { setSelectedLayer } from "../../lib/slice/designerSlice";
import ProductColorOption from "./ProductColorOption";

const ProductColor = ({ isDefaultOption, setIsDefaultOption }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();

  const { colorArtData } = useSelector((state) => state.color);

  useEffect(() => {
    dispatch(setSelectedLayer(null));
  }, [dispatch]);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile
              ? "mbl-pdcolor-option mbl-option bottom-option"
              : "color-option-details option-box"
          }
          style={{
            position: ismobile ? "fixed" : "relative",
            display: "inline-block",
          }}
        >
          <div className="option-heading">
            <div className="back-btn" onClick={() => setIsDefaultOption(true)}>
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Product Color</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <div className="select-color-option dynamicproductrender">
            {colorArtData[0]?.colorcode && (
              <div className="color-head">
                <label style={{ marginBottom: 13 }}>Selected color :</label>
                <span
                  className="color-btn dynachangecolor"
                  style={{ backgroundColor: colorArtData[0]?.colorcode }}
                ></span>
                <label className="dynachangename">
                  {colorArtData[0]?.colorname}
                </label>
              </div>
            )}
            <ProductColorOption />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductColor;
