import React, { useEffect, useState } from "react";
import { FaArrowRight, FaRegTrashCan } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import WithLoader from "../components/WithLoader";
import {
  applyCustomerDiscount,
  clearUpdateCartMsg,
  getCartDetails,
  removeCartItem,
  removecartdetails,
  updateCartQyt,
  updatedraftorder,
} from "../lib/slice/cartSlice";
import { fetchDashboardData } from "../lib/slice/dashboardSlice";

const Cart = ({ setLoader }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dashboardLoading = useSelector((state) => state.dashboard.loading);
  const store_id = useSelector((state) => state.dashboard.data?.cur_store_id);
  const customerid = localStorage.getItem("customer");
  const storeproductid = useSelector(
    (state) => state.dashboard.data?.storeproductid
  );
  const { data, loading, successMessage, applyCustomerDiscountData } =
    useSelector((state) => state.cart);
  const noteAttributesArray = useSelector(
    (state) => state.cart.data?.noteAttributesArray
  );
  const cart_details =
    typeof data?.cart_details === "object"
      ? Object.values(data?.cart_details)
      : [];

  const [postatus, setPostatus] = useState("no");
  const [accountStatus, setAccountStatus] = useState("no");
  const [discountCode, setDiscountCode] = useState("");
  const [quantities, setQuantities] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currValues, setCurrValues] = useState({});
  const [acType, setAcType] = useState("");
  const [acNum, setAcNum] = useState("");
  const [poNum, setPoNum] = useState("");
  const [file, setFile] = useState(null);

  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    if (accountStatus === "yes" && acType === "") {
      setAcType("ups");
    }
  }, [accountStatus, acType]);

  useEffect(() => {
    if (noteAttributesArray && JSON.parse(noteAttributesArray)?.potext) {
      setPostatus("yes");
      setPoNum(JSON.parse(noteAttributesArray)?.potext);
    }
    if (noteAttributesArray && JSON.parse(noteAttributesArray)?.accounttype) {
      setAccountStatus("yes");
      setAcType(JSON.parse(noteAttributesArray)?.accounttype);
    }
    if (noteAttributesArray && JSON.parse(noteAttributesArray)?.accountnumber) {
      setAcType(JSON.parse(noteAttributesArray)?.accounttype);
      setAcNum(JSON.parse(noteAttributesArray)?.accountnumber);
    }
    if (noteAttributesArray && JSON.parse(noteAttributesArray)?.zipcode) {
      setAcType(JSON.parse(noteAttributesArray)?.accounttype);
      setZipCode(JSON.parse(noteAttributesArray)?.zipcode);
    }
  }, [noteAttributesArray]);

  useEffect(() => {
    const id = localStorage.getItem("id");
    const customer = localStorage.getItem("customer");
    if (!customerid && !store_id && id && customer) {
      const formData = new FormData();
      formData.append("product_id", id);
      formData.append("shop", "imprintmaker.myshopify.com");
      formData.append("customer", customer);
      dispatch(fetchDashboardData(formData));
    }
  }, [dispatch, customerid, store_id]);

  useEffect(() => {
    if (customerid && store_id) {
      const formData = new FormData();
      formData.append("customerid", customerid);
      formData.append("store_id", store_id);
      dispatch(getCartDetails(formData));
    }
  }, [dispatch, customerid, store_id]);

  useEffect(() => {
    setLoader(dashboardLoading);
    // eslint-disable-next-line
  }, [dashboardLoading]);

  useEffect(() => {
    setLoader(loading);
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    let timeout;
    if (successMessage) {
      timeout = setTimeout(() => {
        dispatch(clearUpdateCartMsg());
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [successMessage, dispatch]);

  const handleQuantityChange = (event, itemId) => {
    setQuantities({ ...quantities, [itemId]: parseInt(event.target.value) });
  };

  const handleRemoveAll = () => {
    const formData = new FormData();
    formData.append("customerid", data?.customerid);
    formData.append("store_id", store_id);
    dispatch(removecartdetails(formData)).then((res) => {
      if (res?.payload) {
        const formData = new FormData();
        formData.append("customerid", customerid);
        formData.append("store_id", store_id);
        dispatch(getCartDetails(formData));
      }
    });
  };

  const handlePostatusChange = (e) => {
    setPostatus(e.target.value);
  };

  const handleAccountStatusChange = (e) => {
    setAccountStatus(e.target.value);
  };

  const sum = cart_details?.reduce((acc, item) => {
    const value =
      item.price *
      (quantities.hasOwnProperty(item.id)
        ? isNaN(quantities[item.id])
          ? 0
          : quantities[item.id]
        : item.quantity);
    return acc + value;
  }, 0);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const handleArrowClick = (itemIndex, direction) => {
    const item = cart_details[itemIndex];
    if (!item) return;

    const properties = item?.properties?.filter(
      (prop) =>
        (prop?.name === "Front Mockup" && prop?.value !== "not applicable") ||
        (prop?.name === "Back Mockup" && prop?.value !== "not applicable") ||
        (prop?.name === "Left Mockup" && prop?.value !== "not applicable") ||
        (prop?.name === "Right Mockup" && prop?.value !== "not applicable") ||
        (prop?.name === "Neck Mockup" && prop?.value !== "not applicable")
    );

    if (!properties || properties.length === 0) return;

    const currentIndex = currValues[itemIndex] || 0;
    let newIndex;

    if (direction === "prev") {
      newIndex = currentIndex - 1;
      if (newIndex < 0) {
        newIndex = properties.length - 1;
      }
    } else if (direction === "next") {
      newIndex = currentIndex + 1;
      if (newIndex >= properties.length) {
        newIndex = 0;
      }
    }

    setCurrValues((prevCurrValues) => ({
      ...prevCurrValues,
      [itemIndex]: newIndex,
    }));
  };

  const handleUploadPO = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("potext", poNum);
    formData.append("zipcode", zipCode);
    formData.append("accountnumber", acNum);
    formData.append("draftorderid", data?.draftorderid);
    formData.append("cur_store_id", data?.storeid);
    formData.append("accounttype", acType);
    if (file) {
    formData.append("po_file", file);}
    dispatch(updatedraftorder(formData));
  };

  return (
    <Wrapper>
      <div className="container-fluid">
        <div className="modal-header text-center">
          <h3 className="modal-title">Your Cart</h3>
        </div>
        {cart_details?.length ? (
          <>
            <div style={{ overflowX: "auto" }}>
              {successMessage && (
                <span className="updaetcartmessage">{successMessage}</span>
              )}
              <table className="cart-table cartblock1">
                <thead className="cart__row cart__row--heading">
                  <tr>
                    <th
                      className="table-header-color"
                      style={{ fontSize: ismobile ? 13 : 15 }}
                    >
                      Product
                    </th>
                    {!ismobile && (
                      <th className="text-center table-header-color">Price</th>
                    )}
                    <th
                      className="text-center table-header-color"
                      style={{ fontSize: ismobile ? 13 : 15 }}
                    >
                      Quantity
                    </th>
                    {!ismobile && (
                      <th className="text-center small--hide table-header-color">
                        Total
                      </th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cart_details?.map((item, index) => {
                    return (
                      <tr key={item?.id} className="cart__row">
                        <td className="cart__meta small--text-left">
                          <div className="cart__product-information">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                maxWidth: ismobile ? 192 : 252,
                                marginRight: ismobile ? 17 : 0,
                                position: "relative",
                              }}
                            >
                              {item?.properties?.filter(
                                (prop) =>
                                  (prop?.name === "Front Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Back Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Left Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Right Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Neck Mockup" &&
                                    prop?.value !== "not applicable")
                              )?.length > 1 ? (
                                <div
                                  style={{
                                    marginLeft: 15,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleArrowClick(index, "prev")
                                  }
                                >
                                  <img
                                    src="https://app.imprintmaker.com/assets/custom_design/images/arrow-back.svg"
                                    alt="arrow-back"
                                  />
                                </div>
                              ) : (
                                <div style={{ width: 24 }}></div>
                              )}

                              {item?.properties
                                ?.filter(
                                  (prop) =>
                                    (prop?.name === "Front Mockup" &&
                                      prop?.value !== "not applicable") ||
                                    (prop?.name === "Back Mockup" &&
                                      prop?.value !== "not applicable") ||
                                    (prop?.name === "Left Mockup" &&
                                      prop?.value !== "not applicable") ||
                                    (prop?.name === "Right Mockup" &&
                                      prop?.value !== "not applicable") ||
                                    (prop?.name === "Neck Mockup" &&
                                      prop?.value !== "not applicable")
                                )
                                ?.map((i, imgIndex) => (
                                  <div key={imgIndex}>
                                    {imgIndex === (currValues[index] || 0) && (
                                      <div
                                        style={{
                                          marginRight: 17,
                                          maxWidth: 109,
                                          marginLeft: 17,
                                        }}
                                      >
                                        <div
                                          style={{
                                            textAlign: "center",
                                            fontWeight: 600,
                                            fontSize: 13,
                                            marginBottom: 5,
                                          }}
                                        >
                                          {i.name}
                                        </div>
                                        <div
                                          className="img-box"
                                          onClick={() => openModal(i.value)}
                                          style={{
                                            backgroundImage: `url(${i?.value})`,
                                          }}
                                        ></div>
                                        <span
                                          className="text-center"
                                          style={{
                                            display: "block",
                                            fontSize: 11,
                                            fontWeight: "bold",
                                            marginTop: 5,
                                          }}
                                        >
                                          {i?.name === "Front Mockup" &&
                                            data.artSizeInfoArr[index]
                                              ?.frontsize}
                                          {i?.name === "Back Mockup" &&
                                            data.artSizeInfoArr[index]
                                              ?.backsize}
                                          {i?.name === "Left Mockup" &&
                                            data.artSizeInfoArr[index]
                                              ?.leftsize}
                                          {i?.name === "Right Mockup" &&
                                            data.artSizeInfoArr[index]
                                              ?.rightsize}
                                          {i?.name === "Neck Mockup" &&
                                            data.artSizeInfoArr[index]
                                              ?.necksize}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              {item?.properties?.filter(
                                (prop) =>
                                  (prop?.name === "Front Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Back Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Left Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Right Mockup" &&
                                    prop?.value !== "not applicable") ||
                                  (prop?.name === "Neck Mockup" &&
                                    prop?.value !== "not applicable")
                              )?.length > 1 ? (
                                <div
                                  style={{
                                    marginRight: 15,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleArrowClick(index, "next")
                                  }
                                >
                                  <img
                                    src="https://app.imprintmaker.com/assets/custom_design/images/arrow-back.svg"
                                    alt="arrow-back"
                                    style={{ transform: "scaleX(-1)" }}
                                  />
                                </div>
                              ) : (
                                <div style={{ width: 24 }}></div>
                              )}
                            </div>
                            <div
                              style={{
                                maxWidth: ismobile
                                  ? "calc(100% - 192px)"
                                  : "calc(100% - 252px)",
                              }}
                            >
                              <div className="list-view-item__title">
                                <div
                                  className="cart__product-title"
                                  style={{
                                    color: "#000",
                                    fontSize: ismobile ? 13 : 15,
                                  }}
                                >
                                  {item?.title}
                                </div>
                              </div>
                              <ul className="product-details">
                                <li className="product-details__item product-details__item--variant-option">
                                  <span
                                    style={{
                                      fontWeight: ismobile ? 600 : 400,
                                    }}
                                  >
                                    SKU:&nbsp;
                                  </span>
                                  {item?.sku}
                                </li>
                                {!ismobile && (
                                  <li className="product-details__item product-details__item--variant-option">
                                    <span
                                      style={{
                                        fontWeight: ismobile ? 600 : 400,
                                      }}
                                    >
                                      Style:&nbsp;
                                    </span>
                                    {item?.title.split("/")[0]}
                                  </li>
                                )}
                                <li className="product-details__item product-details__item--variant-option">
                                  <span
                                    style={{
                                      fontWeight: ismobile ? 600 : 400,
                                    }}
                                  >
                                    Color:&nbsp;
                                  </span>
                                  {
                                    item?.properties?.find(
                                      (prop) => prop?.name === "Color"
                                    )?.value
                                  }
                                </li>
                                <li className="product-details__item product-details__item--variant-option">
                                  <span
                                    style={{
                                      fontWeight: ismobile ? 600 : 400,
                                    }}
                                  >
                                    Size:&nbsp;
                                  </span>
                                  {
                                    item?.properties?.find(
                                      (prop) => prop?.name === "Size"
                                    )?.value
                                  }
                                </li>
                                {ismobile && (
                                  <>
                                    <li className="product-details__item product-details__item--variant-option">
                                      <span
                                        style={{
                                          fontWeight: ismobile ? 600 : 400,
                                        }}
                                      >
                                        Price:&nbsp;
                                      </span>
                                      ${item?.price}
                                    </li>
                                    <li className="product-details__item product-details__item--variant-option">
                                      <span
                                        style={{
                                          fontWeight: ismobile ? 600 : 400,
                                        }}
                                      >
                                        Total Amount:&nbsp;
                                      </span>
                                      $
                                      {(
                                        item.price *
                                        (quantities.hasOwnProperty(item.id)
                                          ? isNaN(quantities[item.id])
                                            ? 0
                                            : quantities[item.id]
                                          : item.quantity)
                                      ).toFixed(2)}
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          </div>
                        </td>
                        {!ismobile && (
                          <td
                            className="cart__price text-center"
                            style={{ padding: 0 }}
                          >
                            ${item?.price}
                          </td>
                        )}
                        <td
                          className="cart__quantity-td text-center small--hide"
                          style={{ padding: 0 }}
                        >
                          <input
                            className="cart__qty-input updateqyt"
                            type="number"
                            min="0"
                            value={
                              quantities.hasOwnProperty(item.id)
                                ? isNaN(quantities[item.id])
                                  ? 0
                                  : quantities[item.id]
                                : item.quantity
                            }
                            pattern="[0-9]*"
                            onChange={(e) => handleQuantityChange(e, item.id)}
                          />
                        </td>
                        {!ismobile && (
                          <td className="cart__final-price text-center small--hide">
                            $
                            {(
                              item.price *
                              (quantities.hasOwnProperty(item.id)
                                ? isNaN(quantities[item.id])
                                  ? 0
                                  : quantities[item.id]
                                : item.quantity)
                            ).toFixed(2)}
                          </td>
                        )}
                        <td
                          style={{
                            width: 42,
                            textAlign: "-webkit-right",
                            padding: 0,
                          }}
                        >
                          <div
                            className="delete btn text-danger btn-sm btn-round"
                            onClick={() => {
                              const formData = new FormData();
                              formData.append("customerid", data?.customerid);
                              formData.append("cur_store_id", data?.storeid);
                              formData.append(
                                "item_variant",
                                cart_details[index]?.id
                              );
                              dispatch(removeCartItem(formData)).then((res) => {
                                if (res?.payload) {
                                  const formData = new FormData();
                                  formData.append("customerid", customerid);
                                  formData.append("store_id", store_id);
                                  dispatch(getCartDetails(formData));
                                }
                              });
                            }}
                          >
                            <FaRegTrashCan />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="cart__footer cartblock2">
              <div className="cart-subtotal">
                <span className="cart-subtotal__title">Subtotal</span>
                <span className="cart-subtotal__price append_credit">
                  ${sum?.toFixed(2)} USD
                </span>
              </div>
              <div className="cart__shipping rte">
                <form id="uploadpo_form">
                  <div className="form-group dt-common">
                    <label style={{ marginRight: 8 }}>Have PO? </label>
                    <input
                      type="radio"
                      className="postatus"
                      id="postatus1"
                      name="postatus"
                      value="yes"
                      checked={postatus === "yes"}
                      onChange={handlePostatusChange}
                    />
                    <label style={{ marginRight: 16 }}>Yes</label>
                    <input
                      type="radio"
                      className="postatus"
                      id="postatus2"
                      name="postatus"
                      value="no"
                      checked={postatus === "no"}
                      onChange={handlePostatusChange}
                    />
                    <label>No</label>
                  </div>
                  {postatus === "yes" && (
                    <>
                      <div
                        className="form-group dt-common"
                        style={{ display: "flow-root" }}
                      >
                        <input
                          type="text"
                          className="form-control potext"
                          id="potext"
                          name="potext"
                          placeholder="enter PO number here"
                          value={poNum}
                          onChange={(e) => setPoNum(e.target.value)}
                          style={{ backgroundColor: "transparent", zIndex: 0 }}
                        />
                      </div>
                      <div
                        className="form-group dt-common"
                        style={{ display: "flow-root" }}
                      >
                        <div className="po-file-upload">
                          <label
                            style={{
                              display: "inline-block",
                              width: 250,
                              height: 30,
                              lineHeight: "28px",
                              paddingRight: 48,
                              marginLeft: 10,
                              textAlign: "center",
                              border: "1px solid #ccc",
                              cursor: "pointer",
                              backgroundColor: "#f0f0f0",
                              fontWeight: 400,
                            }}
                          >
                            Upload PO (optional)
                          </label>
                          <input
                            type="file"
                            hidden
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                        </div>
                        {file && (
                          <div
                            className="uploaded-file-info"
                            style={{ margin: 8 }}
                          >
                            <div>
                              {file.name}
                              <button
                                onClick={(e) => {e.preventDefault(); setFile(null)}}
                                style={{
                                  marginRight: 10,
                                  cursor: "pointer",
                                  border: "none",
                                  padding: "5px 10px",
                                  borderRadius: "4px",
                                }}
                              >
                                X
                              </button>
                            </div>
                          </div>
                        )}

                        <button
                          onClick={handleUploadPO}
                          className="cart__submit potext"
                          style={{
                            height: 30,
                            padding: "0px 18px",
                            marginLeft: 8,
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                  <div className="form-group dt-common">
                    <label style={{ marginRight: 8 }}>
                      Use Your Own UPS/FEDEX Account?
                    </label>
                    <input
                      type="radio"
                      className="accountstatus"
                      id="accountstatus1"
                      name="accountstatus"
                      value="yes"
                      checked={accountStatus === "yes"}
                      onChange={handleAccountStatusChange}
                    />
                    <label style={{ marginRight: 16 }}>Yes</label>
                    <input
                      type="radio"
                      className="accountstatus"
                      id="accountstatus2"
                      name="accountstatus"
                      value="no"
                      checked={accountStatus === "no"}
                      onChange={handleAccountStatusChange}
                    />
                    <label>No</label>
                  </div>
                  {accountStatus === "yes" && (
                    <>
                      <div className="form-group dt-common accountchoose">
                        <input
                          type="radio"
                          className="accountselect"
                          id="accountstatus3"
                          name="accountselect"
                          value={"ups"}
                          checked={acType === "ups"}
                          onChange={() => setAcType("ups")}
                        />
                        <label style={{ marginRight: 8 }}>UPS</label>
                        <input
                          type="radio"
                          className="accountselect"
                          id="accountstatus4"
                          name="accountselect"
                          value={"fedex"}
                          checked={acType === "fedex"}
                          onChange={() => setAcType("fedex")}
                        />
                        <label style={{ marginRight: 8 }}>FEDEX</label>
                      </div>
                      <div
                        className="form-group dt-common"
                        style={{ display: "flow-root" }}
                      >
                        <input
                          type="text"
                          className="form-control accountpotext"
                          id="zipcode"
                          name="potext"
                          placeholder="enter billing zip code here"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                          style={{ backgroundColor: "transparent", zIndex: 0 }}
                        />
                        <input
                          type="text"
                          className="form-control accountpotext"
                          id="accountnumber"
                          name="potext"
                          placeholder="enter account number here"
                          onChange={(e) => setAcNum(e.target.value)}
                          value={acNum}
                          style={{ backgroundColor: "transparent", zIndex: 0 }}
                        />
                      </div>
                    </>
                  )}
                </form>
              </div>
              <div
                style={
                  ismobile
                    ? { display: "block" }
                    : { display: "flex", justifyContent: "space-between" }
                }
              >
                <div
                  className="col-md-4 custome_discount_code"
                  style={
                    ismobile
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                          padding: 0,
                        }
                      : {
                          display: "flex",
                          padding: 0,
                        }
                  }
                >
                  <div style={ismobile ? { width: "73%" } : { width: "55%" }}>
                    <input
                      type="text"
                      className="form-control apply_discount_label"
                      name="discount"
                      id="discount_code"
                      placeholder="Apply Coupon Code"
                      style={
                        ismobile
                          ? {
                              height: 48,
                              backgroundColor: "transparent",
                              zIndex: 0,
                            }
                          : {
                              height: 48,
                              backgroundColor: "transparent",
                              zIndex: 0,
                            }
                      }
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                    />
                    {!applyCustomerDiscountData?.status && (
                      <span style={{ color: "#E33F3A", fontWeight: 600 }}>
                        {applyCustomerDiscountData?.message}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    className="cart__submit applycode_btn shopify_discount custom_code"
                    disabled={!discountCode}
                    style={
                      ismobile
                        ? {
                            width: "25%",
                            height: 48,
                            opacity: discountCode ? 1 : 0.6,
                          }
                        : {
                            marginLeft: 8,
                            height: 48,
                            fontSize: 14,
                            opacity: discountCode ? 1 : 0.6,
                          }
                    }
                    onClick={() => {
                      const formData = new FormData();
                      formData.append("discount_code", discountCode);
                      formData.append("draft_id", data?.draftorderid);
                      formData.append("customer_id", data?.customerid);
                      formData.append("store_id", store_id);
                      dispatch(applyCustomerDiscount(formData));
                    }}
                  >
                    {ismobile ? "Apply" : "Apply Code"}
                  </button>
                </div>
                <div
                  className="col-md-8"
                  style={{ textAlign: "right", padding: 0 }}
                >
                  <button
                    type="button"
                    className="cart__submit btn btn--small-wide updatecart cartcustomebutton"
                    style={
                      ismobile
                        ? { width: "100%", marginBottom: 8 }
                        : { marginBottom: 8 }
                    }
                    onClick={() => {
                      const formData = new FormData();
                      formData.append("cart_id", data?.cart_id);
                      formData.append("draftorderid", data?.draftorderid);
                      formData.append("cur_store_id", data?.storeid);
                      formData.append("product_title", cart_details[0]?.title);
                      formData.append("cart_sku", cart_details[0]?.sku);
                      formData.append("customerid", data?.customerid);
                      poNum && formData.append("potext", poNum);
                      zipCode && formData.append("zipcode", zipCode);
                      acNum && formData.append("accountnumber", acNum);
                      formData.append("shopify_product_id", storeproductid);
                      acType && formData.append("accounttype", acType);
                      formData.append("discount_code", data?.discount_code);
                      formData.append("POFileName", "");
                      cart_details?.forEach((item, index) => {
                        formData.append(
                          `cartdata[${index}][qyt]`,
                          quantities.hasOwnProperty(item.id)
                            ? isNaN(quantities[item.id])
                              ? 0
                              : quantities[item.id]
                            : item.quantity
                        );
                        formData.append(
                          `cartdata[${index}][variant_id]`,
                          item.id
                        );
                        item?.properties?.forEach((props, i) => {
                          if (props.name === "Color") {
                            formData.append(
                              `cartdata[${index}][color]`,
                              props.value
                            );
                          }
                          if (props.name === "Size") {
                            formData.append(
                              `cartdata[${index}][size]`,
                              props.value
                            );
                          }
                          if (props.name === "Front Mockup") {
                            formData.append(
                              `cartdata[${index}][front]`,
                              props.value
                            );
                          }
                          if (props.name === "Back Mockup") {
                            formData.append(
                              `cartdata[${index}][back]`,
                              props.value
                            );
                          }
                          if (props.name === "Left Mockup") {
                            formData.append(
                              `cartdata[${index}][left]`,
                              props.value
                            );
                          }
                          if (props.name === "Right Mockup") {
                            formData.append(
                              `cartdata[${index}][right]`,
                              props.value
                            );
                          }
                          if (props.name === "Neck Mockup") {
                            formData.append(
                              `cartdata[${index}][neck]`,
                              props.value
                            );
                          }
                        });
                      });
                      dispatch(updateCartQyt(formData));
                    }}
                  >
                    UPDATE CART
                  </button>
                  <button
                    type="button"
                    className="cart__submit btn btn--small-wide removecartdata cartcustomebutton"
                    style={
                      ismobile
                        ? { width: "100%", marginBottom: 8 }
                        : { marginLeft: 8, marginBottom: 8 }
                    }
                    onClick={() => handleRemoveAll()}
                  >
                    REMOVE ALL
                  </button>
                  <button
                    type="button"
                    className="cart__submit btn btn--small-wide removecartdata cartcustomebutton"
                    style={
                      ismobile
                        ? { width: "100%", marginBottom: 8 }
                        : { marginLeft: 8, marginBottom: 8 }
                    }
                    onClick={() => navigate("/products")}
                  >
                    ADD NEW ITEM
                  </button>
                  <button
                    type="button"
                    className={`cart__submit btn btn--small-wide ${
                      ismobile ? "" : "preoceddcheckoutbutton"
                    }`}
                    onClick={() => (window.location.href = data?.invoice_url)}
                    style={
                      ismobile
                        ? {
                            width: "100%",
                            marginBottom: 8,
                            marginRight: 0,
                            transition: "all 0.4s",
                            fontWeight: 700,
                          }
                        : {
                            marginLeft: 8,
                            transition: "all 0.4s",
                            marginBottom: 8,
                          }
                    }
                  >
                    Proceed To Checkout
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="modal-body">
            <div className="row cartdata">
              <div className="cartemptyblock cartblock3">
                <p className="message_title">Your cart is currently empty.</p>
                <span
                  onClick={() => {
                    // navigate("/products");
                    window.location.href =
                      "https://imprintmaker.com/pages/choose-an-option";
                  }}
                  className="cart_button"
                >
                  Continue shopping
                  <span className="righticon">
                    <FaArrowRight />
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="modal fade in" id="cartPopupModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={closeModal}>
                  ×
                </button>
              </div>
              <div className="modal-body text-center">
                <img
                  src={selectedImage}
                  className="popup_imae"
                  alt="popup_image"
                  loading="lazy"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  background-color: #fff;
  padding-bottom: 100px;

  .container-fluid {
    max-width: 1566px;
    margin: 0 auto;
  }

  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fe0000 !important;
    width: 42px;
  }

  .modal-title {
    font-weight: bolder;
  }

  .updaetcartmessage {
    color: green;
    font-size: 18px;
    text-align: center;
    display: block;
  }

  .cart__meta {
    min-width: 375px;
    width: calc(58% - 42px);
  }

  .cart__product-information {
    align-items: flex-start;
    justify-content: flex-start;

    .img-box {
      cursor: pointer;
      width: 109px;
      height: 109px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 26px;
    }
  }

  .cart__price,
  .cart__quantity-td,
  .cart__final-price {
    width: 14%;
  }

  .remove_cart_item {
    width: 20px;
  }

  .cart-subtotal {
    text-align: right;
    font-weight: 500;
  }

  .cart__shipping {
    text-align: right;
  }

  .postatus,
  .accountstatus {
    margin-right: 8px;
  }

  #potext {
    display: block;
    min-width: 285px;
  }

  .accountchoose {
    display: block;
  }

  .accountselect {
    margin-right: 4px;
  }

  .message_title {
    margin: 0 0 20px;
    text-align: center;
  }

  .righticon {
    margin-left: 10px;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 16px;
    }
  }

  .modal-content {
    position: relative;
    height: 90vh;
    overflow-y: auto;
  }

  .close {
    position: relative;
  }

  .popup_imae {
    width: 500px;
    height: 500px;
  }

  #cartPopupModal {
    display: block;
    padding-left: 17px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .cart_button {
    appearance: none;
    display: flex;
    align-items: center;
    width: auto;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 8px 15px;
    background-color: #e33f3a;
    color: #fff;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    white-space: normal;
    font-size: 15px;
  }

  #accountnumber {
    margin-right: 12px;
    display: block;
    min-width: 140px;
  }

  #zipcode {
    display: block;
    min-width: 140px;
  }

  input[type="radio"] {
    accent-color: #e33f3a;
  }

  .form-control:focus {
    border-color: rgba(227, 63, 58, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(227, 63, 58, 0.6);
  }

  .po-file-upload {
    position: relative;
    display: inline-block;

    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
`;

export default WithLoader(Cart);
