import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchColorData = createAsyncThunk(
  "colorData/fetchColorData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/createColorTemplate`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchColorArtData = createAsyncThunk(
  "colorArtData/fetchColorArtData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/generateColorArt`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data?.generate_color_data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: null,
  currentProductColor: [],
  colorArtData: [],
  loading: false,
  error: null,
  focusColor: null,
};

const colorSlice = createSlice({
  name: "colorData",
  initialState,
  reducers: {
    setCurrentProductColor: (state, action) => {
      state.colorArtData = action.payload;
    },
    removeCurrentProductColor: (state, action) => {
      state.colorArtData = state.colorArtData.filter(
        (color) => color.id !== action.payload
      );
    },
    removeColorArtData: (state, action) => {
      state.colorArtData = state.colorArtData.filter(
        (color) => color.id !== action.payload
      );
    },
    resetCurrentProductColor: (state) => {
      state.colorArtData = state.colorArtData.slice(0, 1);
      state.focusColor = state.colorArtData[0];
    },
    setFocusColor: (state, action) => {
      state.focusColor = action.payload;
    },
    resetColorArtData: (state) => {
      state.colorArtData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColorData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchColorData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchColorData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchColorArtData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchColorArtData.fulfilled, (state, action) => {
        state.loading = false;
        state.colorArtData.unshift(action.payload);
      })
      .addCase(fetchColorArtData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setCurrentProductColor,
  removeCurrentProductColor,
  resetCurrentProductColor,
  setFocusColor,
  removeColorArtData,
  resetColorArtData,
} = colorSlice.actions;

export default colorSlice.reducer;
