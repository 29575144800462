import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchAtrUrlSlice = createAsyncThunk(
  "storeArtUrl/fetchAtrUrlSlice",
  async ({ formData, colorcode }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/custom_mockup_image_base64_to_png`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return { response, colorcode };
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
  correntImg: {
    frontFullImage: "",
    backFullImage: "",
    leftFullImage: "",
    rightFullImage: "",
    neckFullImage: "",
  },
  colorsData: [],
};

const storeArtUrlSlice = createSlice({
  name: "storeArtUrlSlice",
  initialState,
  reducers: {
    addCorrentImg: (state, action) => {
      state.correntImg = { ...state.correntImg, ...action.payload };
    },
    removeColorsData: (state, action) => {
      state.colorsData = state.colorsData.filter(
        (item) => item.colorcode !== action.payload
      );
    },
    resetColorsData: (state) => {
      state.colorsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAtrUrlSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAtrUrlSlice.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.response;
        state.colorsData = [
          ...state.colorsData,
          {
            ...action.payload?.response?.data,
            colorcode: action.payload?.colorcode,
          },
        ];
      })
      .addCase(fetchAtrUrlSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { addCorrentImg, removeColorsData, resetColorsData } =
  storeArtUrlSlice.actions;
export default storeArtUrlSlice.reducer;
