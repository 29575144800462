import React, { useEffect } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import Latestimages from "../../components/Latestimages";
import Default from "../../components/Sidebar/Default";
import { setSelectedLayer } from "../../lib/slice/designerSlice";
import InputDesign from "./InputDesign";

const Upload = ({
  isDefaultOption,
  setIsDefaultOption,
  frontDReft,
  backDReft,
  leftDReft,
  rightDReft,
  neckDReft,
}) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();

  const { view } = useSelector((state) => state.designer);
  const latestimages = useSelector(
    (state) => state.allCustomerArt?.latestUploadImageData?.latestimages
  );

  useEffect(() => {
    dispatch(setSelectedLayer(null));
  }, [dispatch]);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile
              ? "mbl-img-upload mbl-option"
              : "img-upload-option option-box"
          }
          style={
            ismobile
              ? { position: "fixed", display: "inline-block", top: 65 }
              : { display: "inline-block" }
          }
        >
          <div className="option-heading">
            <div className="back-btn" onClick={() => setIsDefaultOption(true)}>
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Upload</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <div className="option-srl-sec">
            <InputDesign
              dRef={
                view === "front"
                  ? frontDReft
                  : view === "back"
                  ? backDReft
                  : view === "left"
                  ? leftDReft
                  : view === "right"
                  ? rightDReft
                  : view === "neck"
                  ? neckDReft
                  : null
              }
            />
            <div className="image-formate">
              <p>Accepted File Types (Max size: 20 MB)</p>
              <ul style={{ justifyContent: "flex-start" }}>
                <li style={{ marginRight: "10px" }}>.PNG</li>
                <li style={{ marginRight: "10px" }}>.JPG</li>
                <li style={{ marginRight: "10px" }}>.JPEG</li>
                <li style={{ marginRight: "10px" }}>.SVG</li>
                <li style={{ marginRight: "10px" }}>.PDF</li>
                <li style={{ marginRight: "10px" }}>.AI</li>
              </ul>
            </div>
            {latestimages?.length > 0 && (
              <>
                <p className="latestuploadimage">Latest Uploaded Image</p>
                <div
                  className="row latestimagescroll"
                  style={{ margin: "0 auto" }}
                >
                  <Latestimages latestimages={latestimages} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Upload;
