import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import neck_lable from "../assets/images/neck_lable.png";
import { setView } from "../lib/slice/designerSlice";

const Header = () => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { view } = useSelector((state) => state.designer);
  const { colorArtData, focusColor } = useSelector((state) => state.color);
  const currentProductName = useSelector(
    (state) => state.color.data?.productDetails?.name
  );
  const order_status = useSelector((state) => state.color.data?.order_status);
  const frontRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintdetails
  );
  const backRadioOptions = useSelector(
    (state) => state.dashboard.data?.get_back_parentdata
  );
  const leftRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintLeftSleev
  );
  const rightRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintRightSleev
  );
  const neckRadioOptions = useSelector(
    (state) => state.dashboard.data?.getPrintNeck
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const options = [];
  if (frontRadioOptions?.length && colorArtData[0]?.frontimage) {
    options.push({
      view: "front",
      label: "Front",
    });
  }
  if (backRadioOptions?.length && colorArtData[0]?.backimage) {
    options.push({
      view: "back",
      label: "Back",
    });
  }
  if (leftRadioOptions?.length && colorArtData[0]?.leftimage) {
    options.push({
      view: "left",
      label: "Left",
    });
  }
  if (rightRadioOptions?.length && colorArtData[0]?.rightimage) {
    options.push({
      view: "right",
      label: "Right",
    });
  }
  if (neckRadioOptions?.length) {
    options.push({
      view: "neck",
      label: "Neck",
    });
  }

  const handleOptionClick = (selectedView) => {
    dispatch(setView(selectedView));
    if (location.pathname === "/edit-image") {
      navigate("/upload");
    } else if (location.pathname === "/edit-text") {
      navigate("/add-text");
    }
  };

  return (
    <Wrapper>
      {ismobile ? (
        <>
          {/* <div className="w3-teal_header">
            <div className="navbar-header_mobile">
              <button
                className="w3-button  w3-xlarge"
                onClick={() => setIsSidebarOpen(true)}
              >
                ☰
              </button>
              <Link className="navbar-brand_mobile" to="/upload">
                <img src={logo} alt="imprintmaker" />
              </Link>
              <div className="add-cart-btn right_add_bnt">
                <svg
                  className="cartpopupdata"
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  viewBox="0 0 37 40"
                >
                  <path
                    className="savg-color-change"
                    d="M36.5 34.8L33.3 8h-5.9C26.7 3.9 23 .8 18.5.8S10.3 3.9 9.6 8H3.7L.5 34.8c-.2 1.5.4 2.4.9 3 .5.5 1.4 1.2 3.1 1.2h28c1.3 0 2.4-.4 3.1-1.3.7-.7 1-1.8.9-2.9zm-18-30c2.2 0 4.1 1.4 4.7 3.2h-9.5c.7-1.9 2.6-3.2 4.8-3.2zM4.5 35l2.8-23h2.2v3c0 1.1.9 2 2 2s2-.9 2-2v-3h10v3c0 1.1.9 2 2 2s2-.9 2-2v-3h2.2l2.8 23h-28z"
                  />
                </svg>
                <div
                  id="CartCount"
                  className="site-header__cart-count critical-hidden cartpopupdata"
                >
                  <span className="svg-count-data">1</span>
                </div>
              </div>
            </div>
          </div> */}
          {isSidebarOpen && (
            <div
              className="w3-sidebar w3-bar-block w3-border-right w3-sider_wd left_side_slider_window"
              id="mySidebar"
            >
              <button
                type="button"
                className="w3-bar-item w3-large"
                onClick={() => setIsSidebarOpen(false)}
              >
                ×
              </button>
              <ul className="menu_toggle_item_container">
                {/* <li className="menu_item">
                  <button
                    type="button"
                    className="add-product-btn product_category_popup"
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      setIsSidebarOpen(false);
                      navigate("/products");
                    }}
                  >
                    <img
                      src="https://app.imprintmaker.com/assets/custom_design/images/add-product-icon.svg"
                      alt="add-product-icon"
                    />
                    Add This Design To Another Product
                  </button>
                </li> */}
                {/* <li className="menu_item">
                  <Link
                    className="orange-btn text-center add_media_accountname"
                    to="/upload"
                  >
                    My Account
                  </Link>
                </li> */}
              </ul>
            </div>
          )}
        </>
      ) : (
        <>
          {location.pathname !== "/cart" && (
            <header className="header" id="window_width">
              <nav className="navbar">
                {/* <div className="navbar-header">
              <Link to="/upload" className="navbar-brand">
                <img
                  src="https://imprintmaker.com/cdn/shop/t/7/assets/logo.png?v=71194539887965840201698068727"
                  alt="imprintmaker"
                />
              </Link>
            </div> */}
                <div className="header-right">
                  <div className="add-product add_media_headersection">
                    {currentProductName && (
                      <div
                        className="prd-details"
                        style={{
                          border: "none",
                          paddingTop: 27,
                          paddingBottom: 27,
                        }}
                      >
                        {/* <button
                    type="button"
                    className="add-product-btn"
                    style={{ fontSize: 11, marginLeft: 7 }}
                    onClick={() => navigate("/products")}
                  >
                    <img
                      src="https://app.imprintmaker.com/assets/custom_design/images/add-product-icon.svg"
                      alt="add-product-icon"
                    />
                    Add This Design To Another Product
                  </button> */}
                        <div className="customproductname add_media_proname">
                          <span className="prd-name" id="proname">
                            {currentProductName}
                          </span>
                          {/* <Link
                          className="change_product add_media_changeprobtn custom-btn"
                          to="/products"
                        >
                          Change Product
                        </Link> */}
                          <a
                            className="change_product add_media_changeprobtn custom-btn"
                            href="https://imprintmaker.com/pages/choose-an-option"
                          >
                            Change Product
                          </a>
                        </div>
                      </div>
                    )}
                    {colorArtData[0]?.colorname && (
                      <div
                        className="prd-details"
                        style={{
                          borderRight: "none",
                          borderLeft: "1px solid #707070",
                          paddingTop: 27,
                          paddingBottom: 27,
                        }}
                      >
                        <ul>
                          <li style={{ marginTop: 2, flexWrap: "wrap" }}>
                            <span
                              className="color-box"
                              style={{
                                backgroundColor:
                                  focusColor?.colorcode ||
                                  colorArtData[0]?.colorcode,
                              }}
                            />
                            <span className="prd-color">
                              {colorArtData[0]?.colorname}
                            </span>
                            <Link
                              to="/product-color"
                              className="opencolortab custom-btn"
                            >
                              Change Color
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                    <div
                      className="prd-details"
                      style={{
                        border: "none",
                        borderLeft: "1px solid #707070",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {options.map(({ view: optionView, label }, index) => {
                          return (
                            <div
                              key={index}
                              className="right-box"
                              onClick={() => handleOptionClick(optionView)}
                            >
                              <div
                                className="right-opt-box changeimageurl fornt_site_border"
                                style={
                                  view === optionView
                                    ? {
                                        boxShadow: "0 0 0 1px #e33f3a",
                                        width: 50,
                                        height: 50,
                                      }
                                    : { width: 50, height: 50 }
                                }
                              >
                                <img
                                  src={
                                    optionView === "front"
                                      ? focusColor?.frontimage ||
                                        colorArtData[0]?.frontimage
                                      : optionView === "back"
                                      ? focusColor?.backimage ||
                                        colorArtData[0]?.backimage
                                      : optionView === "left"
                                      ? focusColor?.leftimage ||
                                        colorArtData[0]?.leftimage
                                      : optionView === "right"
                                      ? focusColor?.rightimage ||
                                        colorArtData[0]?.rightimage
                                      : optionView === "neck"
                                      ? neck_lable
                                      : null
                                  }
                                  alt={optionView}
                                  loading="lazy"
                                  style={{ maxWidth: "100%", height: "auto" }}
                                />
                              </div>
                              <span
                                style={
                                  view === optionView
                                    ? {
                                        color: "#e33f3a",
                                        fontWeight: 600,
                                        marginBottom: 0,
                                        fontSize: 14,
                                        lineHeight: "12px",
                                      }
                                    : {
                                        marginBottom: 0,
                                        fontSize: 14,
                                        lineHeight: "12px",
                                      }
                                }
                              >
                                {label}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="site-header__icon site-header__cart add_media_carticon"
                      style={{
                        padding: "26px 10px 26PX 26px",
                        justifyContent: "center",
                        cursor: "default",
                        borderLeft: "1px solid #707070",
                      }}
                    >
                      <div
                        className="site-header__cart"
                        style={{
                          cursor: "pointer",
                          padding: 0,
                          position: "relative",
                        }}
                        onClick={() => navigate("/cart")}
                      >
                        <svg
                          className="cartpopupdata"
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          viewBox="0 0 37 40"
                        >
                          <path
                            className="savg-color-change"
                            d="M36.5 34.8L33.3 8h-5.9C26.7 3.9 23 .8 18.5.8S10.3 3.9 9.6 8H3.7L.5 34.8c-.2 1.5.4 2.4.9 3 .5.5 1.4 1.2 3.1 1.2h28c1.3 0 2.4-.4 3.1-1.3.7-.7 1-1.8.9-2.9zm-18-30c2.2 0 4.1 1.4 4.7 3.2h-9.5c.7-1.9 2.6-3.2 4.8-3.2zM4.5 35l2.8-23h2.2v3c0 1.1.9 2 2 2s2-.9 2-2v-3h10v3c0 1.1.9 2 2 2s2-.9 2-2v-3h2.2l2.8 23h-28z"
                          ></path>
                        </svg>
                        {order_status?.toString() && (
                          <div
                            id="CartCount"
                            className="site-header__cart-count critical-hidden cartpopupdata"
                            style={{
                              position: "absolute",
                              bottom: "75%",
                              left: "75%",
                            }}
                          >
                            <span className="svg-count-data cartpopupdata">
                              {order_status}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </header>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-top: 0.1rem solid rgb(189, 196, 207);
  position: sticky;
  top: 0;
  z-index: 9999;

  .custom-btn {
    background-color: #e33f3a;
    color: #fff !important;
    border: none;
  }

  .custom-btn:hover {
    background-color: #449d44 !important;
  }

  .w3-teal_header {
    top: 0;
  }

  #window_width {
    padding: 5px 20px;
  }

  .change_product {
    margin-left: 5px;
    display: inline-block;
    padding: 0 10px;
  }

  .right-box {
    max-width: 75px;
    margin-left: 10px;
    text-align: center;
  }

  .opencolortab {
    padding: 0 10px;
  }

  .w3-sidebar {
    display: block;
    z-index: 999999999;
  }

  .add_media_headersection {
    padding: 0;
    justify-content: space-between;
    width: 100%;
  }

  .site-header__icon {
    svg {
      height: 27px;
    }

    .site-header__cart-count {
      padding: 0;
      width: 22px;
    }
  }
`;

export default Header;
