import html2canvas from "html2canvas";
import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaRegTrashCan, FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import carticon from "../assets/images/carticon.png";
import CartModal from "../components/CartModal";
import DesignArea from "../components/DesignArea/DesignArea";
import WithLoader from "../components/WithLoader";
import { useFormula } from "../lib/hooks/useFormula";
import { fetchArtData } from "../lib/slice/artSlice";
import {
  removeCurrentProductColor,
  resetCurrentProductColor,
  setFocusColor,
} from "../lib/slice/colorSlice";
import {
  fetchProductSizeData,
  removeSize,
  resetQty,
  resetResponses,
  setTotalSelectedSizes,
  updateSelectedSizes,
} from "../lib/slice/productSizeSlice";
import { fetchProductTierPriceData } from "../lib/slice/productTierPriceSlice";
import {
  addCorrentImg,
  fetchAtrUrlSlice,
  removeColorsData,
  resetColorsData,
} from "../lib/slice/storeArtUrlSlice";
import { fetchStorePrintArtdata } from "../lib/slice/storePrintArtSlice";
import ProductColorOption from "./ProductColor/ProductColorOption";

const ApproveMockup = ({
  frontDReft,
  backDReft,
  leftDReft,
  rightDReft,
  neckDReft,
  setIsDefaultOption,
  isDownload,
  setLoader,
  setIsDownload,
  setLoaderMsg,
}) => {
  const ismobile = window.innerWidth <= 991;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    view,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
    mockupWrapperFront,
  } = useSelector((state) => state.designer);
  const { colorArtData, focusColor } = useSelector((state) => state.color);
  const productType = useSelector(
    (state) => state.dashboard?.data?.productType
  );
  const colorData = useSelector((state) => state.color.data);
  const { selectedSizesArray, totalSelectedSizes } = useSelector(
    (state) => state.productSize
  );
  const dashboardData = useSelector((state) => state.dashboard.data);
  const cur_store_id = useSelector(
    (state) => state.dashboard.data?.cur_store_id
  );
  const productId = useSelector((state) => state.dashboard.data?.productid);
  const productSize = useSelector((state) => state.productSize.data);
  const productTierPrice = useSelector((state) => state.productTierPrice.data);
  const customer_id = localStorage.getItem("customer");
  const colorsData = useSelector((state) => state.storeArtUrl.colorsData);
  const totalStiches = useSelector(
    (state) => state.productTierPrice?.totalStiches
  );
  const dashboard = useSelector(
    (state) => state.dashboard?.data
  );

  const {
    NewImageFormulaFront,
    NewImageFormulaBack,
    NewImageFormulaLeft,
    NewImageFormulaRight,
    NewImageFormulaNeck,
  } = useFormula();

  const [isColorOptionVisible, setIsColorOptionVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qtyAlert, setQtyAlert] = useState(false);
  const [isAddToCart, setIsAddToCart] = useState(false);

  useEffect(() => {
    dispatch(resetResponses());
    if (productId && colorArtData) {
      colorArtData?.forEach((color) => {
        const formData = new FormData();
        formData.append("productid", productId);
        formData.append("colorname", color?.colorname);
        dispatch(fetchProductSizeData(formData));
      });
    }
  }, [dispatch, productId, colorArtData]);

  const totalQty = Object.keys(selectedSizesArray).reduce((total, key) => {
    return (
      total +
      Object.values(selectedSizesArray[key]).reduce(
        (subTotal, quantity) => subTotal + quantity.quantity,
        0
      )
    );
  }, 0);

  useEffect(() => {
    if (
      productId &&
      totalQty &&
      (frontDesigns.length ||
        backDesigns.length ||
        leftDesigns.length ||
        rightDesigns.length ||
        neckDesigns.length) &&
      (currentFrontPrint?.front_name ||
        currentBackPrint?.back_name ||
        currentLeftPrint?.left_name ||
        currentRightPrint?.right_name ||
        currentNeckPrint?.neck_name)
    ) {
      const formData = new FormData();
      formData.append("productid", productId);
      formData.append("front_print_name", currentFrontPrint?.front_name || "");
      formData.append("back_print_name", currentBackPrint?.back_name || "");
      formData.append("left_print_name", currentLeftPrint?.left_name || "");
      formData.append("right_print_name", currentRightPrint?.right_name || "");
      formData.append("neck_print_name", currentNeckPrint?.neck_name || "");
      formData.append("check_front_art_add", frontDesigns?.length ? 1 : 0);
      formData.append("check_back_art_add", backDesigns?.length ? 1 : 0);
      formData.append("check_left_art_add", leftDesigns?.length ? 1 : 0);
      formData.append("check_right_art_add", rightDesigns?.length ? 1 : 0);
      formData.append("check_neck_art_add", neckDesigns?.length ? 1 : 0);
      formData.append("totalqyt", totalQty);
      formData.append("isStiches", productType === "Embroidery" ? 1 : 0);

      if (productType === "Embroidery") {
        formData.append("stichesCount", totalStiches);
      }

      dispatch(fetchProductTierPriceData(formData));
    }

    // eslint-disable-next-line
  }, [
    dispatch,
    productId,
    currentFrontPrint?.front_name,
    currentBackPrint?.back_name,
    currentLeftPrint?.left_name,
    currentRightPrint?.right_name,
    currentNeckPrint?.neck_name,
    frontDesigns.length,
    backDesigns.length,
    leftDesigns.length,
    rightDesigns.length,
    neckDesigns.length,
    totalQty,
  ]);

  const frontTierPrice =
    productTierPrice?.templatedata?.find((i) => i?.front === "FRONT")
      ?.tier_price || 0;

  const backTierPrice =
    productTierPrice?.templatedata?.find((i) => i?.front === "BACK")
      ?.tier_price || 0;

  const leftTierPrice =
    productTierPrice?.templatedata?.find((i) => i?.front === "LEFT")
      ?.tier_price || 0;

  const rightTierPrice =
    productTierPrice?.templatedata?.find((i) => i?.front === "RIGHT")
      ?.tier_price || 0;

  const neckTierPrice =
    productTierPrice?.templatedata?.find((i) => i?.front === "NECK")
      ?.tier_price || 0;

  const handleInputChange = (e, size, id, colorId) => {
    setQtyAlert(false);
    dispatch(
      updateSelectedSizes({
        colorId,
        size,
        id,
        quantity: parseInt(e.target.value) || 0,
      })
    );
    dispatch(setTotalSelectedSizes());
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const totalSelectedSizesArray = Object.keys(totalSelectedSizes).flatMap(
    (size) => {
      return Array(totalSelectedSizes[size]).fill(size);
    }
  );
  let totalTShirtCost = 0;
  for (const size of totalSelectedSizesArray) {
    for (const product of productSize) {
      if (product.variantoption) {
        const variantData = product.variantoption.find((v) => v.size === size);

        if (variantData) {
          const price = parseFloat(variantData.price);
          totalTShirtCost += price;
          break;
        }
      }
    }
  }

  const totalDesignCost =
    frontTierPrice * totalQty +
    backTierPrice * totalQty +
    leftTierPrice * totalQty +
    rightTierPrice * totalQty +
    neckTierPrice * totalQty;
  const totalCost = totalTShirtCost + totalDesignCost;

  const handleSave = async () => {
    setIsAddToCart(true);
    setIsDownload(false);
  };

  const handleAddToCart = async () => {
    if (totalQty === 0) {
      setQtyAlert(true);
    } else {
      setLoader(true);
      setLoaderMsg("This step may take up to 45 seconds.");
      handleSave();
    }
  };

  useEffect(() => {
    if (!dashboardData) {
      navigate("/product-color");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = (currentPrintId) => {
      if (currentPrintId) {
        const formData = new FormData();
        formData.append("type", currentPrintId);
        formData.append("tshirttype", view);
        dispatch(fetchArtData(formData));
      }
    };

    if (view === "front") {
      fetchData(currentFrontPrint?.id);
    } else if (view === "back") {
      fetchData(currentBackPrint?.id);
    } else if (view === "left") {
      fetchData(currentLeftPrint?.id);
    } else if (view === "right") {
      fetchData(currentRightPrint?.id);
    } else if (view === "neck") {
      fetchData(currentNeckPrint?.id);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    view,
    currentFrontPrint?.id,
    currentBackPrint?.id,
    currentLeftPrint?.id,
    currentRightPrint?.id,
    currentNeckPrint?.id,
  ]);

  useEffect(() => {
    if (isAddToCart) {
      const formData = new FormData();
      frontDesigns &&
        frontDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaFront
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaFront
          ).toFixed(2);
          const canvasHeight = +currentFrontPrint?.front_area_end_point
            ? Number(
                (
                  Number(currentFrontPrint?.front_area_end_point) *
                  NewImageFormulaFront
                ).toFixed(2)
              )
            : Number(
                (
                  Number(currentFrontPrint?.front_area_height_full) *
                  NewImageFormulaFront
                ).toFixed(2)
              );

          const canvasData = {
            canvasname: `canvasimgcanvas${i}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
            custombottom:
              canvasHeight - design?.config?.height - design?.config?.y + "px",
          };

          const textartdata = {
            canvasname: `canvasmyCanvas${i}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            fontSize: (design?.config?.fontSize / NewImageFormulaFront).toFixed(
              2
            ),
            uploadtype: "text",
            dataUrl: design?.base64,
          };

          const canvasfrontimagesart = {
            name: "canvasimag",
            value: design?.asset,
          };

          const canvastextimageart = {
            name: "canvasimag",
            value: design?.base64,
          };

          if (design?.type === "IMAGE") {
            for (const key in canvasData) {
              formData.append(
                `canvasdata[${i}][${key}]`,
                design?.type === "IMAGE" ? canvasData[key] : ""
              );
            }
          }

          if (design?.type === "TEXT") {
            for (const key in textartdata) {
              formData.append(`textartdata[${i}][${key}]`, textartdata[key]);
            }
          }
          if (design?.type === "IMAGE") {
            for (const key in canvasfrontimagesart) {
              formData.append(
                `canvasfrontimagesart[${i}][${key}]`,
                canvasfrontimagesart[key]
              );
            }
          }
          if (design?.type === "TEXT") {
            for (const key in canvastextimageart) {
              formData.append(
                `canvastextimageart[${i}][${key}]`,
                canvastextimageart[key]
              );
            }
          }
        });
      let currentFrontImgIndex = 0;
      [...Object.values(selectedSizesArray)].reverse().forEach((item, i) => {
        const colorcode = colorArtData[i]?.colorcode;
        Object.values(item).forEach((is) => {
          if (is.quantity > 0) {
            const canvasimage = {
              name: "canvasimag",
              sizeid: is?.id,
              value:
                colorsData?.find((item) => item.colorcode === colorcode)
                  ?.imageUrl?.front || "",
            };
            for (const key in canvasimage) {
              formData.append(
                `canvasimage[${currentFrontImgIndex}][${key}]`,
                canvasimage[key]
              );
            }
            currentFrontImgIndex++;
          }
        });
      });

      backDesigns &&
        backDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaBack
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaBack
          ).toFixed(2);
          const canvasHeight = +currentBackPrint?.back_area_end_point
            ? Number(
                (
                  Number(currentBackPrint?.back_area_end_point) *
                  NewImageFormulaBack
                ).toFixed(2)
              )
            : Number(
                (
                  Number(currentBackPrint?.back_area_height_full) *
                  NewImageFormulaBack
                ).toFixed(2)
              );

          const canvasData = {
            canvasname: `canvasimgcanvasback${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
            custombottom:
              canvasHeight - design?.config?.height - design?.config?.y + "px",
          };

          const textartdata = {
            canvasname: `canvasmyCanvasback${i}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            fontSize: (design?.config?.fontSize / NewImageFormulaBack).toFixed(
              2
            ),
            uploadtype: "text",
            dataUrl: design?.base64,
          };

          const canvasbackimagesart = {
            name: "canvasimag",
            value: design?.asset,
          };

          const canvasbacktextart = {
            name: "canvasimag",
            value: design?.base64,
          };

          if (design?.type === "IMAGE") {
            for (const key in canvasData) {
              formData.append(
                `canvasdata[${i + frontDesigns?.length}][${key}]`,
                design?.type === "IMAGE" ? canvasData[key] : ""
              );
            }
          }

          if (design?.type === "TEXT") {
            for (const key in textartdata) {
              formData.append(
                `textartdata[${i + frontDesigns?.length}][${key}]`,
                textartdata[key]
              );
            }
          }
          if (design.type === "IMAGE") {
            for (const key in canvasbackimagesart) {
              formData.append(
                `canvasbackimagesart[${i}][${key}]`,
                canvasbackimagesart[key]
              );
            }
          }
          if (design.type === "TEXT") {
            for (const key in canvasbacktextart) {
              formData.append(
                `canvasbacktextart[${i}][${key}]`,
                canvasbacktextart[key]
              );
            }
          }
        });
      let currentBackImgIndex = 0;
      [...Object.values(selectedSizesArray)].reverse().forEach((item, i) => {
        const colorcode = colorArtData[i]?.colorcode;
        Object.values(item).forEach((is) => {
          if (is.quantity > 0) {
            const canvasbackimage = {
              name: "canvasimag",
              sizeid: is?.id,
              value:
                colorsData?.find((item) => item.colorcode === colorcode)
                  ?.imageUrl?.back || "",
            };
            for (const key in canvasbackimage) {
              formData.append(
                `canvasbackimage[${currentBackImgIndex}][${key}]`,
                canvasbackimage[key]
              );
            }
            currentBackImgIndex++;
          }
        });
      });

      leftDesigns &&
        leftDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaLeft
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaLeft
          ).toFixed(2);
          const canvasHeight = +currentLeftPrint?.left_area_end_point
            ? Number(
                (
                  Number(currentLeftPrint?.left_area_end_point) *
                  NewImageFormulaLeft
                ).toFixed(2)
              )
            : Number(
                (
                  Number(currentLeftPrint?.left_area_height_full) *
                  NewImageFormulaLeft
                ).toFixed(2)
              );

          const canvasData = {
            canvasname: `canvasimgcanvasleft${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
            custombottom:
              canvasHeight - design?.config?.height - design?.config?.y + "px",
          };

          const textartdata = {
            canvasname: `canvasmyCanvasleft${i}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            fontSize: (design?.config?.fontSize / NewImageFormulaLeft).toFixed(
              2
            ),
            uploadtype: "text",
            dataUrl: design?.base64,
          };

          const canvasleftimagesart = {
            name: "canvasimag",
            value: design?.asset,
          };

          const canvaslefttextart = {
            name: "canvasimag",
            value: design?.base64,
          };

          if (design?.type === "IMAGE") {
            for (const key in canvasData) {
              formData.append(
                `canvasdata[${
                  i + frontDesigns?.length + backDesigns?.length
                }][${key}]`,
                design?.type === "IMAGE" ? canvasData[key] : ""
              );
            }
          }

          if (design?.type === "TEXT") {
            for (const key in textartdata) {
              formData.append(
                `textartdata[${
                  i + frontDesigns?.length + backDesigns?.length
                }][${key}]`,
                textartdata[key]
              );
            }
          }
          if (design.type === "IMAGE") {
            for (const key in canvasleftimagesart) {
              formData.append(
                `canvasleftimagesart[${i}][${key}]`,
                canvasleftimagesart[key]
              );
            }
          }
          if (design.type === "TEXT") {
            for (const key in canvaslefttextart) {
              formData.append(
                `canvaslefttextart[${i}][${key}]`,
                canvaslefttextart[key]
              );
            }
          }
        });
      let currentLeftImgIndex = 0;
      [...Object.values(selectedSizesArray)].reverse().forEach((item, i) => {
        const colorcode = colorArtData[i]?.colorcode;
        Object.values(item).forEach((is) => {
          if (is.quantity > 0) {
            const canvasleftimage = {
              name: "canvasimag",
              sizeid: is?.id,
              value:
                colorsData?.find((item) => item.colorcode === colorcode)
                  ?.imageUrl?.left || "",
            };
            for (const key in canvasleftimage) {
              formData.append(
                `canvasleftimage[${currentLeftImgIndex}][${key}]`,
                canvasleftimage[key]
              );
            }
            currentLeftImgIndex++;
          }
        });
      });

      rightDesigns &&
        rightDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaRight
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaRight
          ).toFixed(2);
          const canvasHeight = +currentRightPrint?.right_area_end_point
            ? Number(
                (
                  Number(currentRightPrint?.right_area_end_point) *
                  NewImageFormulaRight
                ).toFixed(2)
              )
            : Number(
                (
                  Number(currentRightPrint?.right_area_height_full) *
                  NewImageFormulaRight
                ).toFixed(2)
              );

          const canvasData = {
            canvasname: `canvasimgcanvasright${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
            custombottom:
              canvasHeight - design?.config?.height - design?.config?.y + "px",
          };

          const textartdata = {
            canvasname: `canvasmyCanvasright${i}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            fontSize: (design?.config?.fontSize / NewImageFormulaRight).toFixed(
              2
            ),
            uploadtype: "text",
            dataUrl: design?.base64,
          };

          const canvasrightimagesart = {
            name: "canvasimag",
            value: design?.asset,
          };

          const canvasrighttextart = {
            name: "canvasimag",
            value: design?.base64,
          };

          if (design?.type === "IMAGE") {
            for (const key in canvasData) {
              formData.append(
                `canvasdata[${
                  i +
                  frontDesigns?.length +
                  backDesigns?.length +
                  leftDesigns?.length
                }][${key}]`,
                design?.type === "IMAGE" ? canvasData[key] : ""
              );
            }
          }

          if (design?.type === "TEXT") {
            for (const key in textartdata) {
              formData.append(
                `textartdata[${
                  i +
                  frontDesigns?.length +
                  backDesigns?.length +
                  +leftDesigns?.length
                }][${key}]`,
                textartdata[key]
              );
            }
          }
          if (design.type === "IMAGE") {
            for (const key in canvasrightimagesart) {
              formData.append(
                `canvasrightimagesart[${i}][${key}]`,
                canvasrightimagesart[key]
              );
            }
          }
          if (design.type === "TEXT") {
            for (const key in canvasrighttextart) {
              formData.append(
                `canvasrighttextart[${i}][${key}]`,
                canvasrighttextart[key]
              );
            }
          }
        });
      let currentRightImgIndex = 0;
      [...Object.values(selectedSizesArray)].reverse().forEach((item, i) => {
        const colorcode = colorArtData[i]?.colorcode;
        Object.values(item).forEach((is) => {
          if (is.quantity > 0) {
            const canvasrightimage = {
              name: "canvasimag",
              sizeid: is?.id,
              value:
                colorsData?.find((item) => item.colorcode === colorcode)
                  ?.imageUrl?.right || "",
            };
            for (const key in canvasrightimage) {
              formData.append(
                `canvasrightimage[${currentRightImgIndex}][${key}]`,
                canvasrightimage[key]
              );
            }
            currentRightImgIndex++;
          }
        });
      });

      neckDesigns &&
        neckDesigns.forEach((design, i) => {
          const customwidth = (
            design?.config?.width / NewImageFormulaNeck
          ).toFixed(2);
          const customheight = (
            design?.config?.height / NewImageFormulaNeck
          ).toFixed(2);
          const canvasHeight = +currentNeckPrint?.neck_area_end_point
            ? Number(
                (
                  Number(currentNeckPrint?.neck_area_end_point) *
                  NewImageFormulaNeck
                ).toFixed(2)
              )
            : Number(
                (
                  Number(currentNeckPrint?.neck_area_height_full) *
                  NewImageFormulaNeck
                ).toFixed(2)
              );

          const canvasData = {
            canvasname: `canvasimgcanvasneck${i + 1}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            art_id: design?.config?.id,
            uploadtype: `${design?.type}`.toLowerCase(),
            customwidth,
            customheight,
            custombottom:
              canvasHeight - design?.config?.height - design?.config?.y + "px",
          };

          const textartdata = {
            canvasname: `canvasmyCanvasneck${i}`,
            artwidth: design?.config?.width + "px",
            artheight: design?.config?.height + "px",
            arttop: design?.config?.y + "px",
            artleft: design?.config?.x + "px",
            fontSize: (design?.config?.fontSize / NewImageFormulaNeck).toFixed(
              2
            ),
            uploadtype: "text",
            dataUrl: design?.base64,
          };

          const canvasneckimagesart = {
            name: "canvasimag",
            value: design?.asset,
          };

          const canvasnecktextart = {
            name: "canvasimag",
            value: design?.base64,
          };

          if (design?.type === "IMAGE") {
            for (const key in canvasData) {
              formData.append(
                `canvasdata[${
                  i +
                  frontDesigns?.length +
                  backDesigns?.length +
                  leftDesigns?.length +
                  rightDesigns?.length
                }][${key}]`,
                design?.type === "IMAGE" ? canvasData[key] : ""
              );
            }
          }

          if (design?.type === "TEXT") {
            for (const key in textartdata) {
              formData.append(
                `textartdata[${
                  i +
                  frontDesigns?.length +
                  backDesigns?.length +
                  leftDesigns?.length +
                  rightDesigns?.length
                }][${key}]`,
                textartdata[key]
              );
            }
          }
          if (design.type === "IMAGE") {
            for (const key in canvasneckimagesart) {
              formData.append(
                `canvasneckimagesart[${i}][${key}]`,
                canvasneckimagesart[key]
              );
            }
          }
          if (design.type === "TEXT") {
            for (const key in canvasnecktextart) {
              formData.append(
                `canvasnecktextart[${i}][${key}]`,
                canvasnecktextart[key]
              );
            }
          }
        });
      let currentNeckImgIndex = 0;
      [...Object.values(selectedSizesArray)].reverse().forEach((item, i) => {
        const colorcode = colorArtData[i]?.colorcode;
        Object.values(item).forEach((is) => {
          if (is.quantity > 0) {
            const canvasneckimage = {
              name: "canvasimag",
              sizeid: is?.id,
              value:
                colorsData?.find((item) => item.colorcode === colorcode)
                  ?.imageUrl?.neck || "",
            };
            for (const key in canvasneckimage) {
              formData.append(
                `canvasneckimage[${currentNeckImgIndex}][${key}]`,
                canvasneckimage[key]
              );
            }
            currentNeckImgIndex++;
          }
        });
      });

      let currentIndex = 0;
      colorArtData.forEach((color, i) => {
        const colorId = color?.id;
        const sizesForColor = selectedSizesArray[colorId];

        if (sizesForColor) {
          Object.keys(sizesForColor).forEach((sizeKey, index) => {
            const item = productSize
              .flatMap((size) => size.variantoption)
              .find((variant) => variant.size === sizeKey);

            if (item) {
              const option = {
                colorname: color?.colorname || "",
                colorcode: color?.colorcode || "",
                datasize: item?.size || "",
                sizeval: sizesForColor[sizeKey]?.quantity || 0,
                weight: item?.weight || "",
                variantprice: item?.price,
                prosku: colorData?.productDetails?.sku || "",
              };
              for (const key in option) {
                formData.append(`option[${currentIndex}][${key}]`, option[key]);
              }
              currentIndex++;
            }
          });
        }
      });

      let tooltipData = [];
      if (frontDesigns?.length) {
        tooltipData.push({
          data_price: frontTierPrice,
          data_name: currentFrontPrint?.front_name,
        });
      }
      if (backDesigns?.length) {
        tooltipData.push({
          data_price: backTierPrice,
          data_name: currentBackPrint?.back_name,
        });
      }
      if (leftDesigns?.length) {
        tooltipData.push({
          data_price: leftTierPrice,
          data_name: currentLeftPrint?.left_name,
        });
      }
      if (rightDesigns?.length) {
        tooltipData.push({
          data_price: rightTierPrice,
          data_name: currentRightPrint?.right_name,
        });
      }
      if (neckDesigns?.length) {
        tooltipData.push({
          data_price: neckTierPrice,
          data_name: currentNeckPrint?.neck_name,
        });
      }

      tooltipData.forEach((item, index) => {
        formData.append(
          `tooltop_popup_text[${index}][data_price]`,
          item.data_price || 0
        );
        formData.append(
          `tooltop_popup_text[${index}][data_name]`,
          item.data_name || ""
        );
      });

      formData.append("product_id", productId);
      formData.append("shopify_product_id", dashboardData.storeproductid);
      formData.append("cur_store_id", cur_store_id);
      formData.append("customerid", customer_id);
      formData.append("image_width", mockupWrapperFront?.width);
      formData.append("image_height", mockupWrapperFront?.height);
      formData.append("customerTag", 0);
      formData.append("total_cart_item", 0);
      formData.append("cat_name", dashboard?.productType);
      formData.append("image_url", dashboardData?.front_image);
      formData.append("main_pro_frontimg", dashboardData?.front_image);
      formData.append("main_pro_backimg", dashboardData?.back_image);
      frontDesigns?.length &&
        formData.append("inch_type", currentFrontPrint?.id);
      backDesigns?.length && formData.append("backtype", currentBackPrint?.id);
      leftDesigns?.length && formData.append("lefttype", currentLeftPrint?.id);
      rightDesigns?.length &&
        formData.append("righttype", currentRightPrint?.id);
      neckDesigns?.length && formData.append("necktype", currentNeckPrint?.id);
      dispatch(fetchStorePrintArtdata(formData)).then((action) => {
        setLoader(false);
        setLoaderMsg("");
        if (action?.payload) {
          openModal();
          dispatch(resetQty());
        }
        setIsDownload(false);
      });
    }
    setIsAddToCart(false);

    // eslint-disable-next-line
  }, [isAddToCart]);

  const handleSaveImage = async (color) => {
    try {
      setLoaderMsg("This step may take up to 8 seconds.");
      setIsDownload(true);
      const designInfo = [
        {
          querySelector: ".pro-width-front-color",
        },
        {
          querySelector: ".pro-width-back-color",
        },
        {
          querySelector: ".pro-width-left-color",
        },
        {
          querySelector: ".pro-width-right-color",
        },
        {
          querySelector: ".pro-width-neck-color",
        },
      ];
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      const promises = designInfo.map(async ({ querySelector }) => {
        await delay(1000);
        return captureCanvasImageForColor(querySelector);
      });

      const results = await Promise.all(promises);
      const payload = {
        front: results.find((res) => Object.keys(res)[0] === "frontFullImage")
          ?.frontFullImage[0],
        back: results.find((res) => Object.keys(res)[0] === "backFullImage")
          ?.backFullImage[0],
        left: results.find((res) => Object.keys(res)[0] === "leftFullImage")
          ?.leftFullImage[0],
        right: results.find((res) => Object.keys(res)[0] === "rightFullImage")
          ?.rightFullImage[0],
        neck: results.find((res) => Object.keys(res)[0] === "neckFullImage")
          ?.neckFullImage[0],
      };

      const formData = new FormData();
      formData.append("front", payload.front);
      formData.append("back", payload.back);
      formData.append("left", payload.left);
      formData.append("right", payload.right);
      formData.append("neck", payload.neck);
      await dispatch(fetchAtrUrlSlice({ formData, colorcode: color }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownload(false);
      setLoader(false);
      setLoaderMsg("");
    }
  };

  const captureCanvasImageForColor = (selector) => {
    return new Promise((resolve) => {
      const containers = document.querySelectorAll(selector);
      const dataUrls = [];
      const promises = [];

      containers.forEach((container) => {
        if (container) {
          const promise = html2canvas(container, {
            useCORS: true,
            allowTaint: false,
            logging: true,
          }).then((canvas) => {
            const dataUrl = canvas.toDataURL("image/png");
            dataUrls.push(dataUrl);
          });

          promises.push(promise);
        }
      });
      Promise.all(promises).then(() => {
        let dataToUpdate = {};

        if (selector === ".pro-width-front-color") {
          dataToUpdate.frontFullImage = dataUrls;
        } else if (selector === ".pro-width-back-color") {
          dataToUpdate.backFullImage = dataUrls;
        } else if (selector === ".pro-width-left-color") {
          dataToUpdate.leftFullImage = dataUrls;
        } else if (selector === ".pro-width-right-color") {
          dataToUpdate.rightFullImage = dataUrls;
        } else if (selector === ".pro-width-neck-color") {
          dataToUpdate.neckFullImage = dataUrls;
        }
        dispatch(addCorrentImg(dataToUpdate));
        resolve(dataToUpdate);
      });
    });
  };
  useEffect(() => {
    setLoader(true);
    handleSaveImage(colorArtData[0]?.colorcode);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (colorArtData?.some((item) => item.id !== focusColor?.id)) {
      dispatch(setFocusColor(colorArtData[0]));
    }

    // eslint-disable-next-line
  }, [dispatch, colorArtData]);

  return (
    <Wrapper>
      <div
        className={ismobile ? "aaprove_btn_inner_page" : "custom_height_set"}
      >
        <div
          className={
            ismobile
              ? "header_sizeing_fixed"
              : "container-fluid custome-tshirt-option"
          }
        >
          <div className="row">
            {!ismobile && (
              <DesignArea
                frontDReft={frontDReft}
                backDReft={backDReft}
                leftDReft={leftDReft}
                rightDReft={rightDReft}
                neckDReft={neckDReft}
                setIsDefaultOption={setIsDefaultOption}
                isDownload={isDownload}
              />
            )}
            <div
              className="cartoptionsetting inactivecartoption"
              style={
                ismobile
                  ? { display: "block", width: "100%", margin: 0, top: 55 }
                  : { display: "block", width: "50%" }
              }
            >
              <div className="cart-headerpart">
                <div className="cart-popup-box cart-popup-backs">
                  <span
                    className="includebackbutton custom-btn"
                    style={{ left: ismobile ? 10 : 0 }}
                    onClick={() => {
                      navigate("/product-color");
                      dispatch(resetColorsData());
                      dispatch(resetCurrentProductColor());
                    }}
                  >
                    <span className="cart-popup-back">
                      <FaChevronLeft />
                    </span>
                    <span style={{ marginLeft: 8 }}>Back</span>
                  </span>
                  <h3 className="popup-header-text">Select Sizes</h3>
                  {!ismobile && (
                    <div
                      className="close-circle cart-popup-close"
                      style={{}}
                      onClick={() => navigate("/upload")}
                    >
                      <FaXmark />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="cart-bodypart"
                style={
                  ismobile
                    ? {
                        backgroundColor: "#f2f2f2",
                        height: "75vh",
                        overflow: "auto",
                      }
                    : {
                        maxHeight: "75vh",
                        position: "relative",
                        overflow: "auto",
                        height: "unset",
                      }
                }
              >
                <div
                  className={ismobile ? "" : "selectsizeblockdata"}
                  style={ismobile ? {} : { height: "unset" }}
                >
                  {totalQty === 0 && qtyAlert && (
                    <h4 className="selectsizeqyt">Please Select Quantity</h4>
                  )}

                  <div className="" style={ismobile ? { height: "unset" } : {}}>
                    <div className="appendmultipleproduct">
                      <div
                        className="select sizeblock"
                        style={
                          ismobile
                            ? {
                                zIndex: 1,
                                backgroundColor: "#f6f6f6",
                                position: "relative",
                              }
                            : {
                                zIndex: 1,
                                backgroundColor: "#f6f6f6",
                                position: "relative",
                              }
                        }
                      >
                        <div style={{ maxWidth: "50%" }}>
                          <span className="pName-title">
                            {colorData?.productDetails?.name}
                          </span>
                          <br />
                          <span className="pName-sku">
                            SKU: {colorData?.productDetails?.sku}
                          </span>
                        </div>
                        <div
                          className="btn btn-sm addBtn-cart"
                          style={
                            ismobile
                              ? {
                                  marginTop: 10,
                                  position: "absolute",
                                  top: 10,
                                  right: 0,
                                  transition: "all 0.4s",
                                }
                              : {
                                  height: 39,
                                  lineHeight: "27px",
                                  position: "absolute",
                                  top: 10,
                                  right: 0,
                                  transition: "all 0.4s",
                                }
                          }
                          onClick={() => {
                            setIsColorOptionVisible(!isColorOptionVisible);
                          }}
                        >
                          Add Another Color
                        </div>
                        {isColorOptionVisible && (
                          <div className="color-block">
                            <ProductColorOption
                              setIsColorOptionVisible={setIsColorOptionVisible}
                              handleSaveImage={handleSaveImage}
                            />
                          </div>
                        )}
                      </div>
                      <div className="scroll-bar-wrap">
                        <div className="listView scrollbar">
                          <div className="listView-wrap" id="append_multi94">
                            {colorArtData?.map((colorItem, colorIndex) => {
                              const sizeInfo =
                                productSize[colorIndex]?.variantoption || [];
                              return (
                                <div
                                  className="listView-elements"
                                  id={`add_new_row_${colorItem.id}`}
                                  key={colorItem?.id}
                                >
                                  <div
                                    className="cart-variant"
                                    style={
                                      ismobile
                                        ? {
                                            flexWrap: "wrap",
                                            position: "relative",
                                          }
                                        : {}
                                    }
                                  >
                                    <div className="cart-variant-color">
                                      <div
                                        className="color-btn"
                                        style={{
                                          backgroundColor: colorItem?.colorcode,
                                        }}
                                      >
                                        {!ismobile && (
                                          <div
                                            className="mt-5"
                                            style={{
                                              marginTop: 60,
                                              width: 102,
                                              fontSize: 10,
                                            }}
                                          >
                                            (Inventory) <br />
                                            as of
                                            {productSize[colorIndex]?.inv_date}
                                          </div>
                                        )}
                                      </div>
                                      <div className="color-info">
                                        <div className="lable">
                                          {colorItem?.colorname}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="cart-variant-size"
                                      style={
                                        ismobile ? { minWidth: "320px" } : {}
                                      }
                                    >
                                      {sizeInfo.map((size, sizeIndex) => (
                                        <div
                                          style={
                                            ismobile
                                              ? {
                                                  minWidth: 43,
                                                  marginBottom: 6,
                                                }
                                              : { marginBottom: 6 }
                                          }
                                          key={sizeIndex}
                                        >
                                          <div
                                            className="size"
                                            style={
                                              ismobile ? { fontSize: 13 } : {}
                                            }
                                          >
                                            {size.size}
                                          </div>
                                          <div className="input">
                                            <input
                                              type="number"
                                              min={1}
                                              className="form-control form-control-sm chooselectimage"
                                              style={{
                                                backgroundColor: "transparent",
                                                zIndex: 0,
                                              }}
                                              pattern="[0-9]*"
                                              onChange={(e) => {
                                                handleInputChange(
                                                  e,
                                                  size.size,
                                                  size.id,
                                                  colorItem?.id
                                                );
                                              }}
                                              value={
                                                selectedSizesArray?.[
                                                  colorItem?.id
                                                ]?.[size.size]?.quantity || ""
                                              }
                                              onFocus={() =>
                                                dispatch(
                                                  setFocusColor(colorItem)
                                                )
                                              }
                                            />
                                            <div
                                              className="qw-SizingTable-surcharge"
                                              style={
                                                ismobile
                                                  ? {
                                                      fontSize: 11,
                                                      textAlign: "center",
                                                      paddingTop: 5,
                                                      height: 21,
                                                    }
                                                  : {
                                                      fontSize: 12,
                                                      textAlign: "center",
                                                      paddingTop: 5,
                                                      height: 21,
                                                    }
                                              }
                                            >
                                              {Number(size.qty_Seattle) +
                                                Number(size.qty_Cincinnati) +
                                                Number(size.qty_Dallas) +
                                                Number(size.qty_Reno) +
                                                Number(size.qty_Robbinsville) +
                                                Number(size.qty_Jacksonville) +
                                                Number(size.qty_Minneapolis) +
                                                Number(size.qty_Phoenix)}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div
                                      className="cart-variant-action"
                                      style={
                                        ismobile
                                          ? { position: "absolute", right: 0 }
                                          : {}
                                      }
                                    >
                                      <div
                                        className="delete btn text-danger btn-sm btn-round"
                                        onClick={() => {
                                          if (colorArtData?.length > 1) {
                                            dispatch(
                                              removeCurrentProductColor(
                                                colorItem?.id
                                              )
                                            );
                                          }
                                          dispatch(removeSize(colorItem?.id));
                                          dispatch(
                                            removeColorsData(
                                              colorItem?.colorcode
                                            )
                                          );
                                          dispatch(setTotalSelectedSizes());
                                        }}
                                      >
                                        <FaRegTrashCan />
                                      </div>
                                    </div>
                                  </div>
                                  {ismobile && (
                                    <div
                                      className="mt-5"
                                      style={{
                                        marginTop: 8,
                                        fontSize: 10,
                                      }}
                                    >
                                      (Inventory) <br />
                                      as of
                                      {productSize[colorIndex]?.inv_date}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={ismobile ? "" : "cart-setting"}
                  style={
                    ismobile
                      ? { border: "2px solid #f6f6f6" }
                      : {
                          border: "2px solid #f6f6f6",
                          width: "calc(100% - 10px)",
                          position: "sticky",
                          bottom: 0,
                        }
                  }
                >
                  <div
                    className={
                      ismobile
                        ? "w3-container w3-border-top w3-padding-16 size_footer variant_popup_footer add_to_cart_footer"
                        : "cart-setting-section"
                    }
                  >
                    <div
                      className="cartBtns-col addtocart_block"
                      style={{ width: "70%" }}
                    >
                      <div className="cart-setting-price">
                        <div className="cart-setting-icon">
                          <img
                            src={carticon}
                            className="cart-setting-imag"
                            alt="carticon"
                          />
                        </div>

                        {(frontDesigns?.length ||
                          backDesigns?.length ||
                          leftDesigns?.length ||
                          rightDesigns?.length ||
                          neckDesigns?.length) &&
                        totalQty ? (
                          <div className="cart-setting-titlesection selectedsizedata">
                            <div
                              style={{
                                lineHeight: "27px",
                                display: "block",
                                fontSize: 13,
                                fontWeight: "bold",
                              }}
                            >
                              <span className="eachwiseprice">
                                ${(totalCost / totalQty).toFixed(2)}
                              </span>
                              <span style={{ float: "left", marginLeft: 5 }}>
                                USD each
                              </span>
                              {/* {(frontDesigns?.length ||
                                backDesigns?.length ||
                                leftDesigns?.length ||
                                rightDesigns?.length ||
                                neckDesigns?.length) && (
                                <div style={{ position: "relative" }}>
                                  <span className="infoblock">
                                    <div className="tooltiptext">
                                      {frontDesigns?.length ? (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            FRONT:
                                          </span>
                                          <span>
                                            {currentFrontPrint?.front_name} - -
                                            ${frontTierPrice}
                                            /print
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            FRONT:
                                          </span>
                                          <span>NA</span>
                                        </div>
                                      )}
                                      {backDesigns?.length ? (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            BACK: {currentBackPrint?.back_name}-
                                            -
                                          </span>
                                          <span>
                                            ${backTierPrice}
                                            /print
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            BACK:
                                          </span>
                                          <span>NA</span>
                                        </div>
                                      )}
                                      {leftDesigns?.length ? (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            LEFT: {currentLeftPrint?.left_name}-
                                            -
                                          </span>
                                          <span>
                                            ${leftTierPrice}
                                            /print
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            LEFT:
                                          </span>
                                          <span>NA</span>
                                        </div>
                                      )}
                                      {rightDesigns?.length ? (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            RIGHT:
                                            {currentRightPrint?.right_name}- -
                                          </span>
                                          <span>
                                            ${rightTierPrice}
                                            /print
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            RIGHT:
                                          </span>
                                          <span>NA</span>
                                        </div>
                                      )}
                                      {neckDesigns?.length ? (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            NECK: {currentNeckPrint?.neck_name}-
                                            -
                                          </span>
                                          <span>
                                            ${neckTierPrice}
                                            /print
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            color: "#495057",
                                            fontSize: 16,
                                            fontWeight: 400,
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span style={{ marginRight: 11 }}>
                                            NECK:
                                          </span>
                                          <span>NA</span>
                                        </div>
                                      )}
                                    </div>
                                    Decoration Price Info
                                  </span>
                                </div>
                              )} */}
                            </div>
                            <div className="cart-setting-title">
                              <span className="cart_totalprice">
                                ${totalCost.toFixed(2)}
                              </span>
                              /<span className="productpieces">{totalQty}</span>
                              &nbsp; pieces
                            </div>
                          </div>
                        ) : (
                          <div className="noselectsize">
                            <span
                              style={
                                ismobile
                                  ? {
                                      fontSize: 12,
                                      fontWeight: 500,
                                      marginTop: 12,
                                      display: "block",
                                    }
                                  : {
                                      fontSize: 20,
                                      fontWeight: 500,
                                      marginTop: 12,
                                      display: "block",
                                    }
                              }
                              className="onlyplantshirtprice"
                            >
                              ${totalTShirtCost.toFixed(2)} USD
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="cart-button">
                      <button
                        type="button"
                        className="btn btn-success addcartswalpopup"
                        onClick={() => {
                          setIsDownload(true);
                          handleAddToCart();
                        }}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <CartModal closeModal={closeModal} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .close-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #272727;
    border-radius: 50%;

    svg {
      color: #ffffff;
      font-size: 24px;
    }
  }

  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fe0000;
  }

  .infoblock {
    color: #e33f3a;
    margin-left: 20px;
    cursor: pointer;
  }

  .tooltiptext {
    display: none;
    position: absolute;
    bottom: 100%;
    top: unset;
    background-color: #fff;
    border: 1px solid #aeaeae;
    padding: 5px;
    border-radius: 4px;
    height: unset !important;
  }

  .infoblock:hover .tooltiptext {
    display: inline-block;
  }

  .custom-btn {
    background-color: #e33f3a;
    color: #fff !important;
    border: none;
  }

  .custom-btn:hover {
    background-color: #449d44 !important;
  }

  .includebackbutton {
    transition: all 0.4s;
  }

  .cart-popup-back {
    width: 24px;
    display: flex;
    align-items: center;

    svg {
      color: #fff;
      font-size: 14px;
    }
  }

  .selectsizeqyt {
    margin: 0 auto;
    padding: 5px;
    font-size: 16px;
    color: red;
  }

  .color-block {
    width: 100%;
    margin-top: 20px;
  }

  .listView-elements {
    min-height: 136px;
  }

  .chooselectimage {
    text-align: center;
    width: 60px;
    @media (min-width: 1400px) {
      width: 83px;
    }
  }

  .delete_variant {
    width: 15px;
    height: 15px;
  }

  .eachwiseprice {
    float: left;
  }

  .cart_totalprice,
  .productpieces {
    font-size: 13px;
    font-weight: bold;
  }

  .cart-button {
    margin: 8px;
  }

  .form-control:focus {
    border-color: rgba(227, 63, 58, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(227, 63, 58, 0.6);
  }
`;

export default WithLoader(ApproveMockup);
