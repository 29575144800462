import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProductSizeData = createAsyncThunk(
  "productSizeData/fetchProductSizeData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/getProductSize`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  data: [],
  selectedSizesArray: [],
  totalSelectedSizes: {},
  loading: false,
  error: null,
};

const productVariantSlice = createSlice({
  name: "productSizeData",
  initialState,
  reducers: {
    resetResponses: (state) => {
      state.data = [];
    },

    resetQty: (state) => {
      state.selectedSizesArray = [];
      state.totalSelectedSizes = {};
    },

    updateSelectedSizes: (state, action) => {
      const { colorId, size, id, quantity } = action.payload;
      state.selectedSizesArray = {
        ...state.selectedSizesArray,
        [colorId]: {
          ...state.selectedSizesArray[colorId],
          [size]: { quantity, id },
        },
      };
    },

    setTotalSelectedSizes: (state) => {
      const sizeTotals = {};

      for (const colorId in state.selectedSizesArray) {
        const sizes = state.selectedSizesArray[colorId];
        for (const size in sizes) {
          if (!sizeTotals[size]) {
            sizeTotals[size] = 0;
          }
          sizeTotals[size] += sizes[size].quantity;
        }
      }

      state.totalSelectedSizes = sizeTotals;
    },

    removeSize: (state, action) => {
      if (state.selectedSizesArray[action.payload]) {
        delete state.selectedSizesArray[action.payload];
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProductSizeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductSizeData.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(fetchProductSizeData.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });
  },
});

export const {
  resetResponses,
  resetQty,
  updateSelectedSizes,
  setTotalSelectedSizes,
  removeSize,
} = productVariantSlice.actions;

export default productVariantSlice.reducer;
