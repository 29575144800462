import React, { useState } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import Latestimages from "../components/Latestimages";
import Default from "../components/Sidebar/Default";

const PreviousArtWork = ({ isDefaultOption, setIsDefaultOption }) => {
  const ismobile = window.innerWidth <= 991;

  const latestimages = useSelector(
    (state) => state.allCustomerArt?.allCustomerArtData?.customer_art
  );

  const [search, setSearch] = useState("");

  const filterLatestImg = latestimages?.filter((item) =>
    item?.artwork.toLowerCase().includes(search.trim().toLowerCase())
  );

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className={
            ismobile
              ? "add-product-details mbl-option"
              : "latest-image-details option-box"
          }
          style={
            ismobile
              ? { position: "fixed", display: "inline-block", top: 65 }
              : { display: "inline-block", padding: 0 }
          }
        >
          <div className="option-heading">
            <div className="back-btn" onClick={() => setIsDefaultOption(true)}>
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Previous Art Work</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <div
            className="row latestimagescroll"
            style={{ margin: "0 auto", maxHeight: 550 }}
          >
            <div className="col-md-12 col-sm-12 col-xs-12 search-box-div">
              <input
                type="text"
                className="form-control input-lg search-box file-search"
                placeholder="Search file name"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  backgroundColor: "transparent",
                  zIndex: 0,
                  borderColor: "rgba(227, 63, 58, 0.6)",
                  boxShadow:
                    "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(227, 63, 58, 0.6)",
                }}
              />
            </div>
            <Latestimages latestimages={filterLatestImg} />
          </div>
        </div>
      )}
    </>
  );
};

export default PreviousArtWork;
