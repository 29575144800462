import React from "react";
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from "react-icons/fa6";
import { GoMirror } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setCurrentDesignsView } from "../../lib/slice/designerSlice";

const TextAlignmentContent = () => {
  const dispatch = useDispatch();

  const {
    selectedLayer,
    view,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
    frontClientCanvas,
    backClientCanvas,
    leftClientCanvas,
    rightClientCanvas,
    neckClientCanvas,
  } = useSelector((state) => state.designer);

  const currentDesignsView =
    view === "front"
      ? frontDesigns
      : view === "back"
      ? backDesigns
      : view === "left"
      ? leftDesigns
      : view === "right"
      ? rightDesigns
      : view === "neck"
      ? neckDesigns
      : [];

  const onChangeAlignment = (config) => {
    const targetLayerIndex = currentDesignsView.findIndex(
      (d) => d?.config?.id === selectedLayer?.config?.id
    );
    if (targetLayerIndex === -1) return;
    const tempLayers = [...currentDesignsView];
    tempLayers[targetLayerIndex] = {
      ...selectedLayer,
      config: { ...selectedLayer?.config, ...config },
    };

    dispatch(setCurrentDesignsView(tempLayers));
  };

  const designAlign = (ALIGN) => {
    if (!selectedLayer) return;

    switch (ALIGN) {
      case "HorizontalCenter":
        return onChangeAlignment({
          x:
            ((view === "front"
              ? frontClientCanvas?.canvasWidth
              : view === "back"
              ? backClientCanvas?.canvasWidth
              : view === "left"
              ? leftClientCanvas?.canvasWidth
              : view === "right"
              ? rightClientCanvas?.canvasWidth
              : view === "neck"
              ? neckClientCanvas?.canvasWidth
              : 0) -
              selectedLayer?.config?.width) /
            2,
        });
      case "HorizontalLeft":
        return onChangeAlignment({ x: 0 });
      case "HorizontalRight":
        return onChangeAlignment({
          x:
            (view === "front"
              ? frontClientCanvas?.canvasWidth
              : view === "back"
              ? backClientCanvas?.canvasWidth
              : view === "left"
              ? leftClientCanvas?.canvasWidth
              : view === "right"
              ? rightClientCanvas?.canvasWidth
              : view === "neck"
              ? neckClientCanvas?.canvasWidth
              : 0) - selectedLayer?.config?.width,
        });
      default:
        break;
    }
  };

  return (
    <Wrapper className="font-rotation-row text-opt-row">
      <div
        className="center-opt"
        onClick={() => designAlign("HorizontalCenter")}
      >
        <span className="center rotate_block">
          <GoMirror />
        </span>
        Center
      </div>
      <div className="text-align-opt">
        <ul>
          <li onClick={() => designAlign("HorizontalLeft")} className="center">
            <FaAlignLeft />
          </li>
          <li
            onClick={() => designAlign("HorizontalCenter")}
            className="center"
          >
            <FaAlignCenter />
          </li>
          <li onClick={() => designAlign("HorizontalRight")} className="center">
            <FaAlignRight />
          </li>
        </ul>
        Text Alignment
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 17px;

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    font-size: 18px;
  }
`;

export default TextAlignmentContent;
