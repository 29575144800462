import React, { useEffect } from "react";
import { FaChevronLeft, FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../../components/Sidebar/Default";
import TextSizeContent from "./TextSizeContent";

const TextSize = ({
  isDefaultOption,
  setIsDefaultOption,
  setTextLayer,
  textLayer,
  enableInputRef,
}) => {
  const ismobile = window.innerWidth <= 991;

  const navigate = useNavigate();

  const { selectedLayer } = useSelector((state) => state.designer);

  useEffect(() => {
    if (selectedLayer?.type !== "TEXT") {
      navigate("/add-text");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isDefaultOption ? (
        <Default setIsDefaultOption={setIsDefaultOption} />
      ) : (
        <div
          className="mbl-text-size-option mbl-option bottom-option"
          style={{
            position: ismobile ? "fixed" : "relative",
            display: "inline-block",
          }}
        >
          <div className="option-heading">
            <div
              className="back-btn"
              onClick={() => {
                navigate("/edit-text");
                ismobile && setIsDefaultOption(true);
              }}
            >
              <FaChevronLeft style={{ color: "#646464", fontSize: 18 }} />
            </div>
            <h3 className="h3">Text size</h3>
            <div
              className="option-close-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                backgroundColor: "#272727",
                borderRadius: "50%",
              }}
              onClick={() => setIsDefaultOption(true)}
            >
              <FaXmark style={{ color: "#FFFFFF", fontSize: 24 }} />
            </div>
          </div>
          <TextSizeContent
            setTextLayer={setTextLayer}
            textLayer={textLayer}
            enableInputRef={enableInputRef}
            setIsDefaultOption={setIsDefaultOption}
          />
        </div>
      )}
    </>
  );
};

export default TextSize;
