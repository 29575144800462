import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import storing_animation from "../assets/images/storing_animation.gif";
import Default from "../components/Sidebar/Default";
import { fetchBrandData } from "../lib/slice/brandSlice";
import { setCurrentProduct } from "../lib/slice/categoryByProductSlice";
import { fetchCategoryData } from "../lib/slice/categorySlice";
import { fetchColorData } from "../lib/slice/colorSlice";

const Products = ({ setIsDefaultOption }) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerId = localStorage.getItem("customer");
  const shopUrl = useSelector((state) => state.dashboard.data?.shop_url);
  const storeId = useSelector((state) => state.dashboard.data?.cur_store_id);
  const brand = useSelector((state) => state.brand.data);
  const category = useSelector((state) => state.category.data);
  const products = useSelector(
    (state) => state.categoryByProduct.data?.products
  );

  const [search, setSearch] = useState("");

  useEffect(() => {
    const formData = new FormData();
    dispatch(fetchCategoryData(formData));
    dispatch(fetchBrandData(formData));
  }, [dispatch]);

  const filteredProducts = products?.filter((p) =>
    p?.name.toLowerCase().includes(search.trim().toLowerCase())
  );

  return (
    <>
      <Default setIsDefaultOption={setIsDefaultOption} />
      <div
        className="modal add-product-modal fade in"
        style={{
          display: "block",
          paddingRight: 17,
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <div className="modal-dialog" style={{ margin: "110px auto" }}>
          <div
            className="modal-content"
            style={{ height: "85vh", overflowY: "scroll" }}
          >
            <div className="modal-header text-center">
              <button
                type="button"
                className="close"
                style={{ position: "fixed" }}
                onClick={() => navigate("/product-color")}
              >
                <span aria-hidden="true">
                  <img
                    src="https://app.imprintmaker.com/assets/custom_design/images/close-btn.svg"
                    alt="close-btn.svg"
                  />
                </span>
              </button>
              <h3 className="modal-title" id="myModalLabel">
                Add Products
              </h3>
              <h2 className="h2">Add your design to more great products.</h2>
            </div>
            {products ? (
              <div className="modal-body">
                <div className="row product_listing">
                  <div className="col-md-12">
                    <div className="col-md-3 padd-left">
                      <button type="button" className="back_product_selection">
                        <span className="product-popup-back">
                          <img
                            src="https://app.imprintmaker.com/assets/custom_design/images/arrow-white.svg"
                            style={{ width: 13, height: 13 }}
                            alt="arrow-white"
                          />
                        </span>
                        Start Over
                      </button>
                    </div>
                    <div className="col-md-9 col-sm-3 col-xs-3 product-search-box-div">
                      <input
                        type="text"
                        className="form-control input-lg search-box product-search"
                        placeholder="Search Product"
                        style={{
                          height: 53,
                          backgroundColor: "transparent",
                          zIndex: 0,
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  {!ismobile && (
                    <div className="col-md-3">
                      <div
                        className="product-box categoylist"
                        style={{ float: "left" }}
                      >
                        <div className="product-details">
                          <nav className="pc-FiltersGroup">
                            <header className="pc-FiltersGroup-header">
                              Type
                            </header>
                            {category?.map((c) => {
                              return (
                                <div
                                  className="pc-FiltersGroup-values"
                                  key={c?.id}
                                >
                                  <input
                                    type="checkbox"
                                    className="checkboxselect"
                                    id="fs-fg-style-Unisex"
                                  />
                                  <span className="categoyname">{c.name}</span>
                                  {c?.subcategorydata &&
                                    c?.subcategorydata?.map((s) => {
                                      return (
                                        <div
                                          className="pc-FiltersGroup-values"
                                          key={s?.id}
                                          style={{ marginLeft: 30 }}
                                        >
                                          <input
                                            type="checkbox"
                                            className="checkboxselect"
                                            id="fs-fg-style-Unisex"
                                          />
                                          <span className="categoyname">
                                            {s?.name}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </nav>
                        </div>
                        <div className="product-details">
                          <nav className="pc-FiltersGroup">
                            <header className="pc-FiltersGroup-header">
                              Brand
                            </header>
                            {brand?.map((b) => {
                              return (
                                <div
                                  className="pc-FiltersGroup-values"
                                  key={b.id}
                                >
                                  <input
                                    type="checkbox"
                                    className="selectbranddata"
                                    id="fs-fg-style-Unisex"
                                  />
                                  <span className="categoyname">{b.name}</span>
                                </div>
                              );
                            })}
                          </nav>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-9 appendpoductpopup">
                    {filteredProducts?.map((p) => {
                      return (
                        <div
                          className="col-md-6 col-lg-3 product serach-product-details"
                          key={p?.id}
                        >
                          <div className="product-box">
                            <div className="product-img">
                              <img
                                src={`https://app.imprintmaker.com/uploads/colorfrontimage/${p?.front_image}`}
                                alt="colorfrontimage"
                                loading="lazy"
                              />
                            </div>
                            <div className="product-details">
                              <div className="arttitle">
                                <p className="poductname">{p?.name}</p>
                              </div>
                              <input
                                type="hidden"
                                id="productname"
                                defaultValue={p?.name}
                              />
                              <button
                                type="button"
                                onClick={async () => {
                                  const shop = shopUrl;
                                  const formColorData = new FormData();
                                  formColorData.append("shop", shop);
                                  formColorData.append("customer", customerId);
                                  formColorData.append("poduct_id", p?.id);
                                  formColorData.append("cur_store_id", storeId);
                                  const formData = new FormData();
                                  formData.append("productid", p?.id);
                                  formData.append("customer", customerId);
                                  formData.append("default", "yes");
                                  await Promise.all([
                                    dispatch(fetchColorData(formColorData)),
                                    dispatch(setCurrentProduct(p)),
                                  ]);
                                  navigate("/upload");
                                }}
                                className="productdata"
                                style={{
                                  padding: "7px 10px",
                                  fontSize: "13px",
                                  backgroundColor: "#E33F3A",
                                  color: "#fff",
                                  borderRadius: "5px",
                                  position: "absolute",
                                  bottom: "15px",
                                  margin: "0 auto",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  minWidth: "85%",
                                  border: "none",
                                }}
                              >
                                Add this product
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <img
                id="saving_animation"
                src={storing_animation}
                alt="saving"
                style={{
                  zIndex: 100001,
                  position: "fixed",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: 10,
                  borderRadius: 10,
                  width: 100,
                  height: 100,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
