export function applySolidBackgroundAbovePNG(bgBase64, pngBase64) {
  return new Promise((resolve) => {
    let bgImg = new Image();
    let pngImg = new Image();

    bgImg.onload = function () {
      pngImg.onload = function () {
        let bgCanvas = document.createElement("canvas");
        bgCanvas.width = pngImg.width;
        bgCanvas.height = pngImg.height;
        let bgCtx = bgCanvas.getContext("2d");

        bgCtx.drawImage(bgImg, 0, 0, bgCanvas.width, bgCanvas.height);

        let finalCanvas = document.createElement("canvas");
        finalCanvas.width = pngImg.width;
        finalCanvas.height = pngImg.height;
        let finalCtx = finalCanvas.getContext("2d");

        finalCtx.drawImage(pngImg, 0, 0, finalCanvas.width, finalCanvas.height);

        finalCtx.globalCompositeOperation = "source-atop";

        finalCtx.drawImage(
          bgCanvas,
          0,
          0,
          finalCanvas.width,
          finalCanvas.height
        );

        let resultBase64 = finalCanvas.toDataURL("image/png");

        resolve(resultBase64);
      };

      pngImg.src = pngBase64;
    };

    bgImg.src = bgBase64;
  });
}
