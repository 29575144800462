import { configureStore } from "@reduxjs/toolkit";
import allCustomerArtReducer from "./lib/slice/allCustomerArtSlice";
import artReducer from "./lib/slice/artSlice";
import brandReducer from "./lib/slice/brandSlice";
import cartReducer from "./lib/slice/cartSlice";
import categoryByProductReducer from "./lib/slice/categoryByProductSlice";
import categoryReducer from "./lib/slice/categorySlice";
import colorReducer from "./lib/slice/colorSlice";
import createTextToImageReducer from "./lib/slice/createTextToImageSlice";
import dashboardReducer from "./lib/slice/dashboardSlice";
import designerReducer from "./lib/slice/designerSlice";
import googleFontReducer from "./lib/slice/googleFontSlice";
import productSizeReducer from "./lib/slice/productSizeSlice";
import productTierPriceReducer from "./lib/slice/productTierPriceSlice";
import saveNoteReducer from "./lib/slice/saveNoteSlice";
import storeArtUrlReducer from "./lib/slice/storeArtUrlSlice";
import storePrintArtReducer from "./lib/slice/storePrintArtSlice";
import textColorReducer from "./lib/slice/textColorSlice";
import textOutlineColorReducer from "./lib/slice/textOutlineColorSlice";
import uploadReducer from "./lib/slice/uploadSlice";

const store = configureStore({
  reducer: {
    allCustomerArt: allCustomerArtReducer,
    art: artReducer,
    brand: brandReducer,
    cart: cartReducer,
    categoryByProduct: categoryByProductReducer,
    category: categoryReducer,
    color: colorReducer,
    createTextToImage: createTextToImageReducer,
    dashboard: dashboardReducer,
    designer: designerReducer,
    googleFont: googleFontReducer,
    productSize: productSizeReducer,
    productTierPrice: productTierPriceReducer,
    saveNote: saveNoteReducer,
    storePrintArt: storePrintArtReducer,
    textColor: textColorReducer,
    textOutlineColor: textOutlineColorReducer,
    upload: uploadReducer,
    storeArtUrl: storeArtUrlReducer,
  },
});

export default store;
