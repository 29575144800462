import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCreateTexttoImageData = createAsyncThunk(
  "createTexttoImageData/fetchCreateTexttoImageData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/createTexttoImage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchConvertImageArtData = createAsyncThunk(
  "convertImageArtData/fetchConvertImageArtData",
  async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/convertImageArtData`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  createTexttoImageData: null,
  convertImageArtData: null,
  loading: false,
  error: null,
};

const createTextToImageSlice = createSlice({
  name: "createTexttoImageData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateTexttoImageData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCreateTexttoImageData.fulfilled, (state, action) => {
        state.loading = false;
        state.createTexttoImageData = action.payload;
      })
      .addCase(fetchCreateTexttoImageData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchConvertImageArtData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchConvertImageArtData.fulfilled, (state, action) => {
        state.loading = false;
        state.convertImageArtData = action.payload;
      })
      .addCase(fetchConvertImageArtData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default createTextToImageSlice.reducer;
