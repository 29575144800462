import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setBackDesigns,
  setFrontDesigns,
  setLeftDesigns,
  setNeckDesigns,
  setRightDesigns,
} from "../../lib/slice/designerSlice";
import { setTotalStiches } from "../../lib/slice/productTierPriceSlice";
import WithLoader from "../WithLoader";
import Mockup from "./Mockup";

const DesignArea = ({
  frontDReft,
  backDReft,
  leftDReft,
  rightDReft,
  neckDReft,
  setIsDefaultOption,
  isDownload,
  setLoader,
}) => {
  const ismobile = window.innerWidth <= 991;

  const dispatch = useDispatch();
  const location = useLocation();

  const {
    view,
    mockupWrapperFront,
    mockupWrapperBack,
    mockupWrapperLeft,
    mockupWrapperRight,
    mockupWrapperNeck,
    outsideDesign,
    maxPrintDesign,
    currentFrontPrint,
    currentBackPrint,
    currentLeftPrint,
    currentRightPrint,
    currentNeckPrint,
    frontDesigns,
    backDesigns,
    leftDesigns,
    rightDesigns,
    neckDesigns,
  } = useSelector((state) => state.designer);
  const { colorArtData, focusColor } = useSelector((state) => state.color);
  const productType = useSelector(
    (state) => state.dashboard?.data?.productType
  );

  const frontTotalStiches = frontDesigns.reduce(
    (sum, item) => sum + item?.config?.totalStitches,
    0
  );
  const backTotalStiches = backDesigns.reduce(
    (sum, item) => sum + item?.config?.totalStitches,
    0
  );
  const leftTotalStiches = leftDesigns.reduce(
    (sum, item) => sum + item?.config?.totalStitches,
    0
  );
  const rightTotalStiches = rightDesigns.reduce(
    (sum, item) => sum + item?.config?.totalStitches,
    0
  );
  const neckTotalStiches = neckDesigns.reduce(
    (sum, item) => sum + item?.config?.totalStitches,
    0
  );

  const totalStiches =
    frontTotalStiches +
    backTotalStiches +
    leftTotalStiches +
    rightTotalStiches +
    neckTotalStiches;

  useEffect(() => {
    dispatch(setTotalStiches(totalStiches));
  }, [dispatch, totalStiches]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (view === "front") {
        setLoader(!Boolean(Number(mockupWrapperFront?.width)));
      }
      if (view === "back") {
        setLoader(!Boolean(Number(mockupWrapperBack?.width)));
      }
      if (view === "left") {
        setLoader(!Boolean(Number(mockupWrapperLeft?.width)));
      }
      if (view === "right") {
        setLoader(!Boolean(Number(mockupWrapperRight?.width)));
      }
      if (view === "neck") {
        setLoader(!Boolean(Number(mockupWrapperNeck?.width)));
      }
    }, 0);

    return () => clearTimeout(timer);

    // eslint-disable-next-line
  }, [
    view,
    mockupWrapperFront?.width,
    mockupWrapperBack?.width,
    mockupWrapperLeft?.width,
    mockupWrapperRight?.width,
    mockupWrapperNeck?.width,
  ]);

  const renderMockups = (viewFrom, reft) => {
    return (
      <div
        style={
          view === viewFrom
            ? { display: "block" }
            : { display: isDownload ? "block" : "none" }
        }
      >
        {colorArtData?.map((item, index) => {
          return (
            <div
              style={{
                display: (
                  focusColor ? focusColor?.id === item?.id : index === 0
                )
                  ? "block"
                  : isDownload
                  ? "block"
                  : "none",
              }}
              key={index}
            >
              <Mockup
                updateDesign={(designs) =>
                  viewFrom === "front"
                    ? dispatch(setFrontDesigns(designs))
                    : viewFrom === "back"
                    ? dispatch(setBackDesigns(designs))
                    : viewFrom === "left"
                    ? dispatch(setLeftDesigns(designs))
                    : viewFrom === "right"
                    ? dispatch(setRightDesigns(designs))
                    : viewFrom === "neck"
                    ? dispatch(setNeckDesigns(designs))
                    : null
                }
                dRef={reft}
                setIsDefaultOption={setIsDefaultOption}
                v={viewFrom}
                isDownload={isDownload}
                item={item}
                displayColor={Boolean(index === 0)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className="col-md-6 col-xxl-8 canvas-img-col"
      id="mockup-wrapper"
      style={ismobile ? { padding: 15 } : { height: "auto", padding: 0 }}
    >
      <>
        {location.pathname !== "/approve-mockup" && (
          <>
            {!ismobile &&
              Object.values(outsideDesign).some((value) => value) && (
                <span
                  className="art_upload_error_message_box"
                  style={{
                    display: "block",
                    margin: 0,
                    marginTop: 8,
                    // position: "absolute",
                    left: "50%",
                    top: 0,
                    // transform: "translateX(-50%)",
                    zIndex: 1,
                    fontSize: 14,
                  }}
                >
                  {Object.keys(outsideDesign)
                    .filter((key) => outsideDesign[key])
                    .map((key, index, array) => (
                      <span key={index}>
                        {index > 0 && index === array.length - 1
                          ? " and "
                          : index > 0
                          ? ", "
                          : ""}
                        {key}
                      </span>
                    ))}
                  : Please position your artwork inside print area.
                </span>
              )}
            {!ismobile &&
              Object.keys(maxPrintDesign)
                .filter((value) => maxPrintDesign[value])
                .map((value, index) => {
                  let printArea;
                  let printName;

                  switch (value) {
                    case "front":
                      printArea = `${currentFrontPrint?.front_area_width_float} X ${currentFrontPrint?.front_area_height_float}`;
                      printName = currentFrontPrint?.front_name;
                      break;
                    case "back":
                      printArea = `${currentBackPrint?.back_area_width_float} X ${currentBackPrint?.back_area_height_float}`;
                      printName = currentBackPrint?.back_name;
                      break;
                    case "left":
                      printArea = `${currentLeftPrint?.left_area_width_float} X ${currentLeftPrint?.left_area_height_float}`;
                      printName = currentLeftPrint?.left_name;
                      break;
                    case "right":
                      printArea = `${currentRightPrint?.right_area_width_float} X ${currentRightPrint?.right_area_height_float}`;
                      printName = currentRightPrint?.right_name;
                      break;
                    case "neck":
                      printArea = `${currentNeckPrint?.neck_area_width_float} X ${currentNeckPrint?.neck_area_height_float}`;
                      printName = currentNeckPrint?.neck_name;
                      break;
                    default:
                      printArea = null;
                      printName = null;
                  }

                  if (printArea && printName && value === view) {
                    return (
                      <div
                        key={value}
                        className="art_upload_error_message_box"
                        style={{
                          display: "block",
                          margin: 0,
                          marginTop: 8,
                          // position: "absolute",
                          left: "50%",
                          top: 24,
                          // transform: "translateX(-50%)",
                          zIndex: 1,
                          fontSize: 14,
                        }}
                      >
                        {`${value} : Max size allowed for ${printName} : ${printArea}`}
                      </div>
                    );
                  }

                  return null;
                })}
          </>
        )}
      </>
      {!ismobile && totalStiches > 0 && productType === "Embroidery" && (
        <span
          style={{
            display: "block",
            margin: 0,
            // position: "absolute",
            left: "50%",
            top: 24,
            marginTop: 8,
            // transform: "translateX(-50%)",
            zIndex: 1,
            fontSize: 16,
            textAlign: "center",
          }}
        >
          <span style={{ fontWeight: 600, marginRight: 8 }}>
            Total Stitches :
          </span>
          {totalStiches}
        </span>
      )}
      {renderMockups("front", frontDReft)}
      {renderMockups("back", backDReft)}
      {renderMockups("left", leftDReft)}
      {renderMockups("right", rightDReft)}
      {renderMockups("neck", neckDReft)}
      {ismobile && totalStiches > 0 && productType === "Embroidery" && (
        <span
          style={{
            display: "block",
            fontSize: 12,
            textAlign: "center",
            marginTop: 8,
          }}
        >
          <span style={{ fontWeight: 600, marginRight: 8 }}>
            Total Stitches :
          </span>
          {totalStiches}
        </span>
      )}
    </div>
  );
};

export default WithLoader(DesignArea);
