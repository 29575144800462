import React from "react";
import styled from "styled-components";

const SaveModal = ({ closeModal }) => {
  return (
    <Wrapper className="swal2-overlay">
      <div className="swal2-modal">
        <div className="swal2-icon swal2-success animate">
          <span className="line tip animate-success-tip" />
          <span className="line long animate-success-long" />
          <div className="placeholder" /> <div className="fix" />
        </div>
        <h2>Success</h2>
        <div className="swal2-content">Your art is saved successfully!</div>
        <hr className="swal2-spacer" />
        <button className="swal2-confirm styled" onClick={() => closeModal()}>
          OK
        </button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  z-index: 9999;

  .swal2-modal {
    display: block;
    width: 500px;
    padding: 20px;
    background: rgb(255, 255, 255);
    margin: 0;
    transform: translate(-50%, -50%);
  }

  .swal2-icon,
  .swal2-content,
  .swal2-spacer {
    display: block;
  }

  .swal2-confirm {
    background-color: #e33f3a;
  }
`;

export default SaveModal;
