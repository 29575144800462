import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLayer: null,
  frontDesigns: [],
  backDesigns: [],
  leftDesigns: [],
  rightDesigns: [],
  neckDesigns: [],
  view: "front",
  frontClientCanvas: {
    canvasWidth: 0,
    canvasHeight: 0,
    canvasHeightFull: 0,
    canvasTop: 0,
    canvasLeft: 0,
  },
  backClientCanvas: {
    canvasWidth: 0,
    canvasHeight: 0,
    canvasHeightFull: 0,
    canvasTop: 0,
    canvasLeft: 0,
  },
  leftClientCanvas: {
    canvasWidth: 0,
    canvasHeight: 0,
    canvasHeightFull: 0,
    canvasTop: 0,
    canvasLeft: 0,
  },
  rightClientCanvas: {
    canvasWidth: 0,
    canvasHeight: 0,
    canvasHeightFull: 0,
    canvasTop: 0,
    canvasLeft: 0,
  },
  neckClientCanvas: {
    canvasWidth: 0,
    canvasHeight: 0,
    canvasHeightFull: 0,
    canvasTop: 0,
    canvasLeft: 0,
  },
  mockupWrapperFront: {
    width: 0,
    height: 0,
  },
  mockupWrapperBack: {
    width: 0,
    height: 0,
  },
  mockupWrapperLeft: {
    width: 0,
    height: 0,
  },
  mockupWrapperRight: {
    width: 0,
    height: 0,
  },
  mockupWrapperNeck: {
    width: 0,
    height: 0,
  },
  zoom: 1.0,
  currentFrontPrint: {},
  currentBackPrint: {},
  currentLeftPrint: {},
  currentRightPrint: {},
  currentNeckPrint: {},
  imgData: {
    fData: [],
    bData: [],
    lData: [],
    rData: [],
    nData: [],
    frontFullImage: "",
    backFullImage: "",
    leftFullImage: "",
    rightFullImage: "",
    neckFullImage: "",
  },
  outsideDesign: {
    front: false,
    back: false,
    left: false,
    right: false,
    neck: false,
  },
  maxPrintDesign: {
    front: false,
    back: false,
    left: false,
    right: false,
    neck: false,
  },
};

const designerSlice = createSlice({
  name: "designer",
  initialState,
  reducers: {
    setSelectedLayer: (state, action) => {
      state.selectedLayer = action.payload;
    },
    setFullImage: (state, action) => {
      const { position, payload } = action.payload;
      state.imgData[`${position}FullImage`] = payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
      state.selectedLayer = null;
    },
    setClientCanvas: (state, action) => {
      const { canvasType, payload } = action.payload;
      state[canvasType] = { ...state[canvasType], ...payload };
    },
    setMockupWrapperFront: (state, action) => {
      Object.assign(state.mockupWrapperFront, action.payload);
    },
    setMockupWrapperBack: (state, action) => {
      Object.assign(state.mockupWrapperBack, action.payload);
    },
    setMockupWrapperLeft: (state, action) => {
      Object.assign(state.mockupWrapperLeft, action.payload);
    },
    setMockupWrapperRight: (state, action) => {
      Object.assign(state.mockupWrapperRight, action.payload);
    },
    setMockupWrapperNeck: (state, action) => {
      Object.assign(state.mockupWrapperNeck, action.payload);
    },
    setFrontDesigns: (state, action) => {
      state.frontDesigns = action.payload;
      const uptodateSelectedLayer = state.selectedLayer
        ? action.payload.find(
            (d) => d.config.id === state.selectedLayer.config.id
          )
        : null;
      state.selectedLayer = uptodateSelectedLayer;
    },
    setBackDesigns: (state, action) => {
      state.backDesigns = action.payload;
      const uptodateSelectedLayer = state.selectedLayer
        ? action.payload.find(
            (d) => d.config.id === state.selectedLayer.config.id
          )
        : null;
      state.selectedLayer = uptodateSelectedLayer;
    },
    setLeftDesigns: (state, action) => {
      state.leftDesigns = action.payload;
      const uptodateSelectedLayer = state.selectedLayer
        ? action.payload.find(
            (d) => d.config.id === state.selectedLayer.config.id
          )
        : null;
      state.selectedLayer = uptodateSelectedLayer;
    },
    setRightDesigns: (state, action) => {
      state.rightDesigns = action.payload;
      const uptodateSelectedLayer = state.selectedLayer
        ? action.payload.find(
            (d) => d.config.id === state.selectedLayer.config.id
          )
        : null;
      state.selectedLayer = uptodateSelectedLayer;
    },
    setNeckDesigns: (state, action) => {
      state.neckDesigns = action.payload;
      const uptodateSelectedLayer = state.selectedLayer
        ? action.payload.find(
            (d) => d.config.id === state.selectedLayer.config.id
          )
        : null;
      state.selectedLayer = uptodateSelectedLayer;
    },
    deleteLayerFromCurrentView: (state, action) => {
      const currentPropertyDesignName =
        state.view === "front"
          ? "frontDesigns"
          : state.view === "back"
          ? "backDesigns"
          : state.view === "left"
          ? "leftDesigns"
          : state.view === "right"
          ? "rightDesigns"
          : state.view === "neck"
          ? "neckDesigns"
          : "";

      state.selectedLayer = null;
      state[currentPropertyDesignName] = state[
        currentPropertyDesignName
      ].filter((d) => d.config.id !== action.payload);
    },
    setCurrentDesignsView: (state, action) => {
      const uptodateSelectedLayer = state.selectedLayer
        ? action.payload.find(
            (d) => d.config.id === state.selectedLayer.config.id
          )
        : null;
      state[
        state.view === "front"
          ? "frontDesigns"
          : state.view === "back"
          ? "backDesigns"
          : state.view === "left"
          ? "leftDesigns"
          : state.view === "right"
          ? "rightDesigns"
          : state.view === "neck"
          ? "neckDesigns"
          : ""
      ] = action.payload;
      state.selectedLayer = uptodateSelectedLayer;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
    setFrontCurrentPrint: (state, action) => {
      state.currentFrontPrint = action.payload;
    },
    setBackCurrentPrint: (state, action) => {
      state.currentBackPrint = action.payload;
    },
    setLeftCurrentPrint: (state, action) => {
      state.currentLeftPrint = action.payload;
    },
    setRightCurrentPrint: (state, action) => {
      state.currentRightPrint = action.payload;
    },
    setNeckCurrentPrint: (state, action) => {
      state.currentNeckPrint = action.payload;
    },
    removeAllDesign: (state) => {
      state.frontDesigns = [];
      state.backDesigns = [];
      state.leftDesigns = [];
      state.rightDesigns = [];
      state.neckDesigns = [];
    },
    updateImgData: (state, action) => {
      state.imgData = { ...state.imgData, ...action.payload };
    },
    setOutsideDesign: (state, action) => {
      state.outsideDesign = action.payload;
    },
    setMaxPrintDesign: (state, action) => {
      state.maxPrintDesign = action.payload;
    },
    updateBase64Data: (state, action) => {
      const { designId, base64, base64High } = action.payload;

      const designArray =
        state.view === "front"
          ? "frontDesigns"
          : state.view === "back"
          ? "backDesigns"
          : state.view === "left"
          ? "leftDesigns"
          : state.view === "right"
          ? "rightDesigns"
          : state.view === "neck"
          ? "neckDesigns"
          : null;

      const targetDesignIndex = state[designArray].findIndex(
        (design) => design.config.id === designId
      );
      if (targetDesignIndex !== -1) {
        state[designArray][targetDesignIndex].base64 = base64;
        state[designArray][targetDesignIndex].base64High = base64High;
        if (state.selectedLayer?.config.id === designId) {
          state.selectedLayer.base64 = base64;
          state.selectedLayer.base64High = base64High;
        }
      }
    },
  },
});

export const {
  setSelectedLayer,
  setView,
  setClientCanvas,
  setMockupWrapperFront,
  setMockupWrapperBack,
  setMockupWrapperLeft,
  setMockupWrapperRight,
  setMockupWrapperNeck,
  setFrontDesigns,
  setBackDesigns,
  setLeftDesigns,
  setRightDesigns,
  setNeckDesigns,
  deleteLayerFromCurrentView,
  setCurrentDesignsView,
  setZoom,
  setFrontCurrentPrint,
  setBackCurrentPrint,
  setLeftCurrentPrint,
  setRightCurrentPrint,
  setNeckCurrentPrint,
  removeAllDesign,
  updateImgData,
  setOutsideDesign,
  setMaxPrintDesign,
  updateBase64Data,
  setFullImage,
} = designerSlice.actions;

export default designerSlice.reducer;
